import React from 'react'
import { PropTypes } from 'prop-types'
import { Avatar, Container, Tooltip } from '@nike/epic-react-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'raviger'

const GravatarTip = ({ email }) => {
  return (
    <Tooltip
      className='tooltip-info-gravatar'
      position='right'
      size='large'
      message={
        <span>
          The MFT Portal uses Gravatar for profile images. To update your image visit the{' '}
          <Link href='https://en.gravatar.com' target='_blank' rel='noopener noreferrer'>
            Gravatar
          </Link>{' '}
          site and update/create your profile using {email}.
        </span>
      }
      inverse
    >
      <FontAwesomeIcon icon='info-circle' />
    </Tooltip>
  )
}

export const UserHero = ({ email }) => {
  return (
    <Container className='epic-bg-default UserHeroContainer'>
      <div className='avatar'>
        <Avatar email={email} size='120' />
      </div>
      <div className='userHeroInfo'>
        <h3>{email}</h3>
        <GravatarTip email={email} />
      </div>
    </Container>
  )
}

UserHero.propTypes = {
  email: PropTypes.string.isRequired,
}
