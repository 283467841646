import { usePath, useQueryParams } from 'raviger'
import { setAuthCode } from './GitHubAuth.js'

// Intercept the `code` query param returned by GitHub to
// prevent Okta from interpreting it as a new login attempt.
export default function InterceptGitHubAuthCode({ fallback }) {
  const path = usePath()
  const [{ code }, setQueryParams] = useQueryParams()

  if (path === '/github-authorized' && code) {
    // Remove `code` query param so okta-auth-js doesn't see it
    setQueryParams({}, { replace: true })
    // Save in sessionStorage so it can be accessed later in GitHubAuth.js,
    // when user's Okta token is available to make authenticated API calls
    setAuthCode(code)
  }

  return fallback()
}
