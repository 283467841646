import React from 'react'
import config from '__config'
import dates from '__util/dates'
import { SearchForm } from '__components/search'

const envChoices = [
  { value: 'dev', label: 'Dev' },
  { value: 'qa', label: 'QA / RLS' },
]

const isProd = config.env === 'prod'
if (!isProd) {
  // In the prod UI, disable searches against the Prod Axway instance for now
  // to avoid performance impact
  envChoices.push({
    value: 'prd',
    label: 'Prod',
  })
}

const statusChoices = [
  { value: '', label: 'Any' },
  { value: 'Processed', label: 'Processed' },
  { value: 'In Progress', label: 'In Progress' },
  { value: 'Failed', label: 'Failed' },
  { value: 'Failed Subtransmission', label: 'Failed Subtransmission' },
]
const protocolChoices = [
  { value: '', label: 'Any' },
  { value: 'as2', label: 'AS2' },
  { value: 'ftp', label: 'FTP' },
  { value: 's3', label: 'S3' },
  { value: 'ssh', label: 'SSH / SFTP' },
  { value: 'azure-blob', label: 'Azure Blob' },
  { value: 'smb', label: 'SMB' },
]
const directionChoices = [
  { value: '', label: 'Any' },
  { value: 'Incoming', label: 'Incoming' },
  { value: 'Outgoing', label: 'Outgoing' },
]
const limitChoices = [
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 25, label: 25 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
]
const windowChoices = [
  { value: 1, label: '1 second' },
  { value: 10, label: '10 seconds' },
  { value: 60, label: '1 minute' },
  { value: 300, label: '5 minute' },
  { value: 300 * 2, label: '10 minutes' },
  { value: 300 * 3, label: '15 minutes' },
  { value: 300 * 6, label: '30 minutes' },
  { value: 3600, label: '1 hour' },
  { value: 3600 * 2, label: '2 hours' },
  { value: 3600 * 3, label: '3 hours' },
  { value: 3600 * 4, label: '4 hours' },
  { value: 3600 * 5, label: '5 hours' },
  { value: 3600 * 8, label: '8 hours' },
  { value: 3600 * 12, label: '12 hours' },
  { value: 3600 * 24, label: '24 hours' },
  { value: 3600 * 24 * 7, label: '7 days' },
  { value: 3600 * 24 * 14, label: '14 days' },
]

export const FileTrackerSearchForm = (props) => {
  const now = new Date()
  const timezone = dates.format(now, 'z')
  const isNotFuture = (date) => date <= now
  return (
    <SearchForm
      // These field names should match allowedFilters values ___SearchPage
      fields={[
        { name: 'env', label: 'Environment', options: envChoices },
        { name: 'status', label: 'Status', options: statusChoices },
        { name: 'protocol', label: 'Protocol', options: protocolChoices },
        { name: 'direction', label: 'Direction', options: directionChoices },
        {
          name: 'resubmitted',
          label: 'Resubmitted',
          options: [
            { value: null, label: 'Any' },
            { value: 'true', label: 'Yes - resubmitted already' },
            { value: 'false', label: 'No - never resubmitted' },
          ],
        },
        { name: 'accountOrLogin', label: 'Account Name', throttle: true },
        { name: 'file', label: 'File name', throttle: true },
        { name: 'limit', label: 'Table size', options: limitChoices },
        {
          type: 'Date',
          name: 'endTimeBefore',
          label: `End Time Before (${timezone})`,
          required: false,
          widgetProps: {
            dateFormat: dates.formats.rfc2822NoZone, // Display format; value is a date object
            filterDate: isNotFuture,
            allowSameDay: true,
            isClearable: true,
            clearButtonTitle: 'Reset to now',
            showTimeInput: true,
            required: false,
          },
        },
        {
          name: 'timeWindowSeconds',
          label: 'Time window (backwards from End Time)',
          options: windowChoices,
        },
      ]}
    />
  )
}
