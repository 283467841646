import React from 'react'
import { Label, RadioGroup } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import { SubWizardContext } from '../SubWizard.js'

const defaultValue = 'MATCH_FIRST'
const NAME = 'condition_type'

export default function FilterCondition({ stepName }) {
  const { registerField, setField, state } = React.useContext(SubWizardContext)
  const { fields } = state

  React.useEffect(() => registerField(stepName, NAME, [], defaultValue), [registerField, stepName])

  return (
    <Field>
      <Label label='Filter Condition?'>
        <HelpText>For the routes below, how should the filter rule be matched?</HelpText>
        <RadioGroup
          list={[
            { value: 'MATCH_ALL', label: 'Execute all routes matching the filter rule' },
            {
              value: 'MATCH_FIRST',
              label: 'Execute only the first matching route, then exit routing',
            },
          ]}
          onChange={(value) => setField(NAME, value)}
          value={fields[NAME]}
        />
      </Label>
    </Field>
  )
}
