import React from 'react'

import { Container } from '@nike/epic-react-ui'
import { PageTitle, PageHeader } from '__components'
import { useNark, useNarkOnPageLoad } from '__util/nark'

const pageName = 'home'

export const HomePage = ({ columns, ...props }) => {
  const nark = useNark()
  useNarkOnPageLoad({ nark, pageName }) // This will post to nark
  return (
    <div>
      <PageTitle title='Home' />
      <PageHeader title='Managed File Transfer' />
      <Container>
        <div>Welcome to the MFT Portal</div>
      </Container>
    </div>
  )
}

export default HomePage
