import React from 'react'
import { Field } from '__components'
import { Label, TextInput } from '@nike/epic-react-ui'

export default function AccountsSearchForm({ onFilter }) {
  // timer is saved in a ref so it isn't wiped out at re-render
  const textChangeTimer = React.useRef()
  const idleMs = 400
  const [name, setName] = React.useState(undefined)

  React.useEffect(() => {
    if (name !== undefined) {
      // Throttle requests: delay after user stops typing
      clearTimeout(textChangeTimer.current)
      textChangeTimer.current = setTimeout(() => onFilter(name), idleMs)
    }
  }, [name, onFilter])

  return (
    <>
      <Field style={{ width: '30em' }}>
        <Label label='Account name'>
          <TextInput
            onChange={({ target }) => {
              setName(target.value)
            }}
            value={name || ''}
            placeholder='type to filter...'
          />
        </Label>
      </Field>
    </>
  )
}
