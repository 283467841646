import React, { useCallback, useEffect } from 'react'
import classnames from 'classnames'
import { Accordion } from '__components'
import { Button, Tooltip } from '@nike/epic-react-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * Renders a set of one or more forms. Each form may render one or more fields having
 * a name and a component that renders it.
 *
 * The FormSet's `values` prop is an array of objects:
 *   [
 *     { fieldA: 'A', fieldB: 'B' },
 *     { fieldA: 'C', fieldB: 'D' },
 *   ]
 *
 */
export default function FormSet({
  fields, // Definition for one form. Object: keys are field names and values are components.
  errors = [], // Array of error objects for each form, like [{ age: 'Must be at least 18', name: 'Required.' }, ...]
  values, // Array of objects representing each form in the set. Usually from component state.
  onChange, // A function that receives one form's data object and should set state as needed.
  addLabel = 'Add another',
  fieldLayout = 'block', // block | inline
  formTitle, // Optional string heading for each form, displayed with the form's index + 1
  initialValues, // Optional object for initial/default values: { field name: value, ... }
  formCountMin = 1,
  formCountMax = 50,
  isCollapsible = false, // Each form in the set can be collapsed/expanded by clicking on heading
}) {
  const fieldNames = Object.keys(fields)
  const addEmptyForm = useCallback(
    (e) => {
      const blankForm = {}
      fieldNames.forEach((name) => (blankForm[name] = initialValues[name]))
      const newValues = [...values, blankForm]
      if (e && e.target) {
        e.target.style.outline = 'none' // prevent blue outline remaining on button
      }
      onChange(newValues, 'addForm')
    },
    [fieldNames, initialValues, onChange, values]
  )

  useEffect(() => {
    // Ensure minimum number of forms
    for (let i = 0, toAdd = formCountMin - values.length; i < toAdd; i++) {
      addEmptyForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formCountMin])

  const onDelete = React.useCallback(
    (i) => {
      const before = [...values].splice(0, i)
      const after = [...values].splice(i + 1)
      onChange(before.concat(after), 'removeForm', i)
    },
    [onChange, values]
  )

  const setRow = React.useCallback(
    (formNum, data, fieldName) => {
      const newValues = [...values]
      newValues[formNum] = data
      onChange(newValues, 'changeField', formNum, fieldName)
    },
    [onChange, values]
  )

  const renderForm = React.useCallback(
    (formValues, index) => {
      const formName = formTitle ? `${formTitle} ${index + 1}` : ''
      const formErrors = errors[index]

      return (
        <Form
          errors={formErrors}
          onDelete={values.length <= formCountMin ? null : onDelete}
          fields={fields}
          layout={fieldLayout}
          key={`FormSet-Form-${index}`}
          formIndex={index}
          formName={formName}
          isCollapsible={isCollapsible}
          setRow={setRow}
          trigger={
            // This prop is used by Accordion
            <FormHeading
              hasErrors={formErrors && Object.keys(formErrors).length > 0}
              name={formName}
              isCollapsible={isCollapsible}
            />
          }
          values={formValues}
        />
      )
    },
    [errors, fields, fieldLayout, formCountMin, formTitle, isCollapsible, onDelete, setRow, values]
  )

  return (
    <>
      <div className={classnames('FormSet', { isCollapsible })}>
        <Accordion
          easing='ease-in'
          enabled={isCollapsible}
          initialOpenIndexes={values}
          onlyOpenSelected={false}
        >
          {values.map(renderForm)}
        </Accordion>
      </div>
      {values.length < formCountMax && (
        <div className='FormSet-add-button'>
          <Button onClick={addEmptyForm} inverse small>
            {addLabel}
          </Button>
        </div>
      )}
    </>
  )
}

const Form = ({
  errors = {},
  fields,
  formIndex,
  formName,
  isCollapsible,
  layout,
  onDelete,
  setRow,
  values,
}) => {
  const keyPrefix = `FormSet-Form-${formIndex}`
  const hasErrors = Object.keys(errors).length > 0

  return (
    <div className={classnames('FormSet-Form', { hasErrors }, layout)}>
      {isCollapsible || <FormHeading name={formName} hasErrors={hasErrors} />}
      {Object.entries(fields).map(([name, field], fieldNumber) => {
        return React.createElement(field, {
          fieldNumber,
          formIndex,
          formValues: values,
          key: `${keyPrefix}-field-${fieldNumber}`,
          name,
          value: values[name] || '',
          error: errors[name] || '',
          onChange: (e) => {
            // This could be improved; for now, supports form controls that call onChange with
            //   - e.target.value (e.g. TextInputs)
            //   - e.value (e.g. Selects)
            //   - value
            const value =
              e.value === undefined ? (e.target === undefined ? e : e.target.value) : e.value
            const newValues = { ...values }
            newValues[name] = value
            setRow(formIndex, newValues, name)
          },
        })
      })}
      {onDelete && (
        <FormDeleteButton
          formIndex={formIndex}
          formName={formName}
          keyPrefix={keyPrefix}
          onDelete={onDelete}
        />
      )}
    </div>
  )
}

function FormHeading({ hasErrors, name }) {
  if (!name) return null
  return (
    <div className={classnames('FormSet-FormHeading', { hasErrors })}>
      {name}
      {hasErrors && (
        <FontAwesomeIcon
          style={{ float: 'left' }}
          icon='exclamation-circle'
          className='epic-color-error'
        />
      )}
    </div>
  )
}

function FormDeleteButton({ formIndex, formName, keyPrefix, onDelete }) {
  return (
    <span
      key={`${keyPrefix}-delete`}
      className='FormSet-FormDeleteButton clickable'
      onClick={() => onDelete(formIndex)}
    >
      {formName ? (
        <Tooltip message={`Remove ${formName}`} size='large'>
          <FontAwesomeIcon icon='times' />
        </Tooltip>
      ) : (
        <FontAwesomeIcon icon='times' />
      )}
    </span>
  )
}
