import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import config from '__config'

const PageTitle = ({ title, children }) => {
  useDocumentTitle(titleWithAppName(title))
  return <React.Fragment>{children}</React.Fragment>
}

const useDocumentTitle = (title) => {
  useEffect(() => {
    document.title = title
  }, [title])
}

const titleWithAppName = (title) => {
  return title ? `${title} - ${config.appName}` : config.appName
}

export default PageTitle

PageTitle.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}
