import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Menu({ currentStep, goToStep, state }) {
  const { steps } = state

  return (
    <div className='WizardMenu'>
      {steps.map((step, index) => {
        const stepIndex = index + 1
        const classes = []
        let icon
        if (currentStep === stepIndex) {
          classes.push('active')
        }
        if (step.sectionHeading) {
          classes.push('sectionHeading')
        }

        // Step status is only set by the `validateWizard` action
        if (step.status === 'complete') {
          classes.push('complete')
          icon = <FontAwesomeIcon icon='check-circle' className='epic-color-success' />
        } else if (step.status === 'hasErrors') {
          classes.push('error')
          icon = <FontAwesomeIcon icon='exclamation-circle' className='epic-color-error' />
        }

        // if (stepHasUnsavedChanges(state, step.name)) {
        //   classes.push('unsaved')
        //   icon = <FontAwesomeIcon icon='circle' className='epic-color-info' />
        // }

        return (
          <div
            key={`WizardMenuLink-${stepIndex}`}
            className={classes.join(' ')}
            onClick={() => classes.includes('disabled') || goToStep(stepIndex)}
          >
            {step.name}
            {icon}
          </div>
        )
      })}
    </div>
  )
}

Menu.propTypes = {
  state: PropTypes.object.isRequired,
}
