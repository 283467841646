import React from 'react'
import { Link } from 'raviger'
import { Table } from '@nike/epic-react-ui'
import config from '__config'

const columns = [
  {
    accessor: 'name',
    Header: 'Account',
    Cell: ({ value }) => {
      const encodedValue = encodeURIComponent(value)
      return <Link href={`${config.basePath}/accounts/${encodedValue}`}>{value}</Link>
    },
  },
  {
    accessor: 'pushed_at',
    Header: 'Last commit',
    Cell: ({ value }) => new Date(value).toLocaleString(),
  },
]

export default function AccountsTable({ data }) {
  return <Table columns={columns} data={data} />
}
