import React from 'react'
import { SiteWizardContext } from '../SiteWizard.js'
import { Label, RadioGroup, Select } from '@nike/epic-react-ui'
import { Field, FilePicker, HelpText, ValidationError } from '__components'
import { required } from '__util/forms/validators.js'
import { findValue, listToSelectOptions } from '__util/select.js'

export default function PartnerAS2CertName({ stepName }) {
  const { registerField, setField, state } = React.useContext(SiteWizardContext)
  const { envConfig, errors, fields } = state
  const certOptions = React.useMemo(() => {
    const certNames = (envConfig?.certs || [])
      .filter((cert) => cert.cert_usage === 'partner')
      .map((cert) => cert.cert_name)
    return listToSelectOptions(certNames)
  }, [envConfig])

  React.useEffect(() => {
    // These 3 fields determine the value of partner_as2_cert_name when the SiteWizard is serialized
    registerField(stepName, 'partnerAS2CertSource', [], 'select')
    registerField(stepName, 'partnerAS2CertUpload', [
      required((v, state) => state.fields.partnerAS2CertSource === 'upload'),
    ])
    registerField(stepName, 'partnerAS2CertSelection', [
      required((v, state) => state.fields.partnerAS2CertSource === 'select'),
    ])
  }, [registerField, stepName])

  return (
    <>
      <Field>
        <Label label='Partner AS2 Cert' required>
          <HelpText>
            Would you like to upload a new AS2 Partner certificate for this account/environment or
            is the certificate for this transfer already on the account?
          </HelpText>
          <RadioGroup
            list={[
              { value: 'upload', label: 'Upload one now' },
              { value: 'select', label: 'Choose one from current certificates' },
            ]}
            onChange={(value) => setField('partnerAS2CertSource', value)}
            value={fields.partnerAS2CertSource}
          />
        </Label>
      </Field>
      {fields.partnerAS2CertSource === 'upload' && (
        <Field>
          <Label label='Upload Partner AS2 Cert' required>
            <FilePicker
              onSelect={(file) => setField('partnerAS2CertUpload', file.name)}
              value={fields.partnerAS2CertUpload}
            />
            <ValidationError message={errors.partnerAS2CertUpload} />
          </Label>
        </Field>
      )}
      {fields.partnerAS2CertSource === 'select' && (
        <Field>
          <Label label='Choose a Partner AS2 Cert' required>
            <HelpText>
              These certs are also listed under the Certificates tab having Usage "partner".
            </HelpText>
            <Select
              onChange={(option) => setField('partnerAS2CertSelection', option.value)}
              options={certOptions}
              value={findValue(certOptions, fields.partnerAS2CertSelection)}
            />
            <ValidationError message={errors.partnerAS2CertSelection} />
          </Label>
        </Field>
      )}
    </>
  )
}
