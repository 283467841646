import React from 'react'
import { SiteWizardContext } from '../SiteWizard.js'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'

export default function NikeAS2CertName({ name, stepName }) {
  const { registerField, state } = React.useContext(SiteWizardContext)
  const { env, fields } = state

  React.useEffect(() => registerField(stepName, name, [], env === 'prd' ? 'as2' : 'nonprod_as2'), [
    env,
    registerField,
    name,
    stepName,
  ])

  return (
    <Field>
      <Label label='Nike AS2 Cert Name' required>
        <HelpText>The environment of this site determines this value.</HelpText>
        <TextInput disabled value={fields[name] || ''} />
      </Label>
    </Field>
  )
}
