import React from 'react'
import { default as RcTooltip } from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'

export default function Tooltip({
  hover,
  click,
  focus,
  trigger,
  placement = 'right',
  content,
  children,
  show,
}) {
  const optionalProps = {}
  if (show !== undefined) {
    optionalProps.visible = show
  }

  if ((hover || click || focus) && trigger) {
    throw new Error(
      'trigger cannot be used with click, hover, and focus. trigger is the long-form of those options'
    )
  }

  if (!trigger) {
    trigger = []
    if (click) trigger.push('click')
    if (hover) trigger.push('hover')
    if (focus) trigger.push('focus')
    if (!trigger.length) trigger.push('hover')
  }

  return content ? (
    <RcTooltip
      placement={placement}
      trigger={trigger}
      mouseEnterDelay={0.5}
      overlay={<div className='tooltip-panel'>{content}</div>}
      {...optionalProps}
    >
      {children}
    </RcTooltip>
  ) : (
    children
  )
}
