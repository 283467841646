import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { HelpText, ValidationError } from '__components'

export default function FailureEmail({ error, onChange, value }) {
  return (
    <Label key='failure_email' label='Failure Email' required>
      <HelpText>
        One or more email addresses to be notified if a related file transfer fails. One email is
        sent per failed file.
      </HelpText>
      <TextInput hasErrors={error} onChange={onChange} value={value} autoComplete='off' />
      <ValidationError message={error} />
    </Label>
  )
}
