import React from 'react'
import { StatusDisplay } from './StatusDisplay'
import { TruncatedText } from '__util/strings'
import { CopyableText, PickTable } from '__components'
import { Tooltip } from '@nike/epic-react-ui'

export const columns = [
  { accessor: 'status', Header: 'Status', Cell: ({ value }) => <StatusDisplay status={value} /> },
  {
    accessor: 'resubmitted',
    Header: 'Resubmitted',
    Cell: ({ value }) => {
      return value === true ? (
        <Tooltip message='This file has been resubmitted at least once' inverse>
          <div className='epic-color-default' style={{ fontWeight: 800 }}>
            Yes
          </div>
        </Tooltip>
      ) : (
        <Tooltip message='This file has never been resubmitted' inverse>
          No
        </Tooltip>
      )
    },
  },
  { accessor: 'protocol', Header: 'Protocol' },
  { accessor: 'direction', Header: 'Direction' },
  { accessor: 'account', Header: 'Account' },
  {
    accessor: 'filename',
    Header: 'File Name',
    Cell: ({ value }) => <CopyableText text={value} truncateAt={50} noTooltip />,
  },
  {
    accessor: 'filesize',
    Header: 'File Size',
    Cell: ({ value }) =>
      value === 0 ? (
        <b>
          <span className='epic-color-error'>0</span>
        </b>
      ) : (
        value
      ),
  },
  {
    accessor: 'startTime',
    Header: 'Start Time',
    Cell: ({ value }) => <CopyableText text={value} noTooltip />,
  },
  { accessor: 'duration', Header: 'Duration' },
  {
    accessor: 'message',
    Header: 'Details',
    Cell: ({ value }) => <TruncatedText maxChars={20} text={value} />,
  },
]

// This is just a PickTable with some file tracking features
export const TrackerTable = ({ rows, toolbar }) => {
  return (
    <PickTable
      toolbar={toolbar}
      columns={columns}
      data={rows}
      isRowPickable={(row) => {
        if (!row) {
          return [false, '']
        }
        if (row.protocol === 'as2' && row.direction === 'Incoming') {
          return [false, 'Incoming AS2 files can not be resubmitted.']
        }
        if (row.status === 'Failed' && row.direction === 'Incoming' && row.filesize === 0) {
          return [false, 'Failed incoming files of size 0 cannot be resubmitted']
        }
        return [true, '']
      }}
    />
  )
}
