import { validateForm } from '__util/forms/index.js'

export function validateSteps(state) {
  state.errors = {}
  const results = state.steps.map(({ name }) => validateStep(name, state))

  results.forEach(({ isStepValid, stepErrors }, index) => {
    // Are we silencing validation results right now, or not?
    if (state.showValidationStatusInMenu) {
      state.steps[index].status = isStepValid ? 'complete' : 'hasErrors'
    }

    Object.assign(state.errors, stepErrors)
  })
  return state
}

// Determine if a step is complete and the user can move on. Validates all
// fields but does not show errors to user.
export function validateStep(stepName, state) {
  const step = state.steps.find((step) => step.name === stepName)
  const fieldsInStep = step.fields || {}
  const stepErrors = validateForm({ ...state, validators: fieldsInStep })

  return {
    isStepValid: Object.keys(stepErrors).length === 0,
    stepErrors,
  }
}

export function stepHasErrors(wizardState, stepName) {
  const { errors, steps } = wizardState
  const step = steps[stepName] || {}
  const fields = step.fields || {}
  return fields ? Object.entries(fields).some(([name, validators]) => errors[name]) : false
}

export function stepHasUnsavedChanges(wizardState, stepName) {
  const { changed, steps } = wizardState
  const step = steps[stepName] || {}
  const fields = step.fields || {}
  return fields ? Object.entries(fields).some(([name, validators]) => changed[name]) : false
}

export function isFieldRequired(fieldName, state) {
  // Traverse all field validators to determine if field is required
  let validators = []
  state.steps.forEach((step) => {
    if (step.fields?.[fieldName]) {
      validators = step.fields[fieldName]
    }
  })

  return validators.find((v) => v.__name === 'required') !== undefined
}
