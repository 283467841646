import React from 'react'
import { Label, RadioGroup, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'

export default function RequiresAccessField({ disabled, onChange, value }) {
  return (
    <Field>
      <Label label='Do you need to upload or download files in a folder on the Nike MFT system?' />
      <HelpText>
        This is only applicable for file transfers initiated by the partner/client. You might need
        this if your workflows are manual, for example.
      </HelpText>
      {disabled ? (
        <TextInput disabled value={value ? 'Yes' : 'No'} />
      ) : (
        <RadioGroup
          list={[
            { label: 'Yes, help me set up user login', value: true },
            { label: 'No', value: false },
          ]}
          onChange={onChange}
          value={value}
        />
      )}
    </Field>
  )
}
