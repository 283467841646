import React, { useRef } from 'react'
import { IconButton } from '@nike/epic-react-ui'

const FilePicker = ({ name, value, placeholder, onSelect }) => {
  const fileInput = useRef()

  const openPicker = (e) => {
    fileInput.current.click()
  }
  const clear = (e) => {
    fileInput.current.value = ''
    onSelect('')
  }

  return (
    <div className='FilePicker' key={`file-${name}`}>
      <IconButton onClick={openPicker} type='share' className='FilePicker-pick' />
      {value ? (
        <React.Fragment>
          <span className='FilePicker-file'>{value}</span>
          <IconButton onClick={clear} type='delete' className='FilePicker-clear' inverse />
        </React.Fragment>
      ) : null}
      <input
        type='file'
        ref={fileInput}
        onChange={(e) => onSelect(fileInput.current.files[0])}
        name={name}
      />
    </div>
  )
}

export default FilePicker
