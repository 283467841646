import React, { useCallback, useEffect, useReducer } from 'react'
import { http } from '__util'

export const SearchContext = React.createContext({
  // These are here solely for good auto-completion
  filters: [],
  items: [],
  total: 0,
  offset: 0,
  isLoading: false,
  error: '',
})

const filterReducer = (state, action) => {
  state = {
    ...state,
    // Always reset offset so results start on first page
    offset: 0,
    searchNow: false,
  }

  switch (action.type) {
    case 'addFilters':
    case 'addFiltersAndSearch':
      Object.entries(action.filters).forEach(([name, value], index) => {
        if (value) {
          state[name] = value
        } else {
          delete state[name]
        }
      })
      if (action.type === 'addFiltersAndSearch') {
        state['searchNow'] = true // trigger a request via useEffect below
      }
      break
    case 'registerChipDeleteHandler':
      return { ...state, _onTextFieldChipDelete: action.handler }
    case 'deleteFilter':
      delete state[action.filterName]
      // Special case: Text fields take their values from local state within SearchForm rather than from SearchContext. So
      // clear them manually via this function provided from within SearchForm.
      state._onTextFieldChipDelete(action.filterName)
      return { ...state }
    default:
      console.error('unexpected action type: ', action.types)
  }
  return state
}

const initialFetchState = {
  items: [],
  total: 0,
  offset: 0,
  error: '',
}
const fetchStateReducer = (state, action) => {
  switch (action.type) {
    case 'fetchStart':
      return { ...state, isLoading: true }
    case 'fetchSucceeded':
      const { items, total, offset } = action
      return { ...state, items, total, offset, isLoading: false, error: '' }
    case 'fetchError':
      return { ...state, error: action.error }
    default:
  }
  return state
}

/**
 * Props:
 *   onSearch: optional function takes current filters object and should return it after making final modifications
 *
 *   The 3 '*PropName' props allow APIs that return search results differently,
 *   e.g. file tracker returns {results: [...]} instead of {items: [...]}
 */
export const Search = ({
  allowedFilters,
  baseUrl,
  children,
  initialFilters = {},
  onSearch,
  resultPropName = 'items',
  totalCountPropName = 'total',
  offsetPropName = 'offset',
}) => {
  const [filters, dispatch] = useReducer(filterReducer, { ...initialFilters, searchNow: true })
  const [fetchState, dispatchFetchState] = useReducer(fetchStateReducer, initialFetchState)

  const getItems = useCallback(() => {
    let { ...data } = filters
    if (onSearch) {
      data = onSearch(data)
    }

    // Exclude filters that are only used for local state (e.g. for combining into other filter values)
    const privateFilters = Object.keys(data).filter((k) => k.startsWith('_'))
    privateFilters.forEach((f) => {
      delete data[f]
    })

    dispatchFetchState({ type: 'fetchStart' })
    return http
      .get(baseUrl, data)
      .then((response) => {
        const items = response[resultPropName]
        const total = response[totalCountPropName] || items.length
        const offset = response[offsetPropName]
        dispatchFetchState({ type: 'fetchSucceeded', items, total, offset })
      })
      .catch(({ error }) => {
        dispatchFetchState({ type: 'fetchError', error })
      })
  }, [
    dispatchFetchState,
    filters,
    baseUrl,
    onSearch,
    offsetPropName,
    resultPropName,
    totalCountPropName,
  ])

  const parseFilter = useCallback(
    (string) => {
      const parts = string.split(':')
      const filter = {}
      if (parts.length === 2) {
        filter[parts[0].toLowerCase()] = parts[1].toLowerCase()
      } else {
        dispatchFetchState({
          type: 'fetchError',
          error: 'Expecting filter text formatted like "key:value"',
        })
      }
      return filter
    },
    [dispatchFetchState]
  )

  useEffect(() => {
    if (filters.searchNow) {
      delete filters['searchNow']
      getItems()
    }
  }, [filters, getItems])

  return (
    <SearchContext.Provider
      value={{
        allowedFilters,
        items: fetchState.items,
        total: fetchState.total,
        offset: fetchState.offset,
        isLoading: fetchState.isLoading,
        error: fetchState.error,
        dispatch,
        filters,
        parseFilter,
        getItems,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}
