import React from 'react'
import { StatusDisplay } from './StatusDisplay'
import { CopyableText, DefList } from '__components'

const showDetailsDialog = (env, transferId, response, dispatch) => {
  const results = response.results
  let content

  if (results && results.length) {
    const details = results[0]
    const commands = details.protocolCommands
      .replace(/local: /g, '\nlocal:  ')
      .replace(/remote: /g, '\nremote: ')
    content = (
      <DefList
        termStyle={{ minWidth: '12em', maxWidth: '20em' }}
        defStyle={{ fontWeight: 'normal' }}
        items={{
          Env: env,
          'Transfer ID': <CopyableText text={transferId} noTooltip />,
          Name: details.filename,
          Size: details.filesize,
          Status: <StatusDisplay status={details.status} />,
          Site: details.site,
          Protocol: details.protocol,
          'Start Time': details.startTime,
          Duration: details.duration,
          Message: details.message,
          Account: details.account,
          'Remote Host': details.remoteHost,
          'Remote Folder': details.remoteFolder,
          'Transfer Type': details.transferType,
          Mode: details.mode,
          'Protocol Commands': <pre style={{ font: '10px Monaco' }}>{`\n ${commands}`}</pre>,
          'Core ID': details.coreId,
          'Security Parameters': details.securityParameters,
          Resubmitted: details.resubmitted,
        }}
      />
    )
  } else {
    content = 'No details were returned for this transfer.'
  }

  dispatch({
    type: 'openDialog',
    content: (
      <React.Fragment>
        <h4>File Details</h4>
        {content}
      </React.Fragment>
    ),
    resetOnClose: false,
  })
}

export default showDetailsDialog
