export function findFilterValue(choices, category, filters) {
  if (!choices) {
    return null
  }
  return choices.find((x) => x.value === filters[category] || '') || null
}

export function findValue(choices, value) {
  if (!choices) {
    return null
  }

  if (Array.isArray(choices)) {
    // choices is a list of option objects
    return choices.find((x) => x.value === value) || null
  } else {
    // choices is an object like { val1: label1, val2: label2 }
    if (value && value in choices) {
      return { value, label: choices[value] }
    } else {
      return null
    }
  }
}

export function findMultipleValues(choices, values) {
  if (!choices || !values) {
    return []
  }
  return choices.filter((x) => values.includes(x.value)) || []
}

export function listToSelectOptions(items) {
  return items.map((item) => ({ value: item, label: item }))
}

export function objToSelectOptions(obj) {
  return Object.entries(obj).map(([value, label]) => ({ value, label }))
}
