import merge from 'deepmerge'

const isProd =
  window.location.href.includes('//portal.mftaws.nike.com') ||
  window.location.href.includes('//console.platforms.nike.com/mft')

const isInConsole = window.location.href.includes('platforms.nike.com')
const basePath = isInConsole ? '/mft' : ''

const narkPreprod = {
  platformId: '==nUmGjjzibg4unsHDw5cal',
  url: 'https://api-preprod.nark.nikecloud.com/v1',
}
const narkProd = {
  platformId: '==RZ7WjZcXvK5s3x6RckPMO',
  url: 'https://api.nark.nikecloud.com/v1',
}

var base = {
  // Node.js REST API - https://github.com/nike-axway-mft/mft-portal-api
  apiUrl: 'https://um8bujnlbd.execute-api.us-west-2.amazonaws.com/sandbox/v1',
  appName: 'MFT Portal',
  appFullName: 'MFT Portal',
  basePath,
  gitHubAuth: {
    authUrl: 'https://github.com/login/oauth/authorize',
  },
  isProd,
  oAuth: {
    // Configured in https://developer.niketech.com
    clientId: 'nike.niketech.mft',
    redirectUri: window.location.origin + basePath,
    responseType: 'authorization_code',
    scope: 'profile openid email',
  },
  nark: narkPreprod,
}

var localhost = merge(base, {
  // Locally-running lambda (see ngp/rocket-api repo for an example)
  // apiUrl: 'http://localhost:3000',
  env: 'development',
  gitHubAuth: {
    client_id: '13355791466c69711a31',
  },
})

var sandbox = merge(base, {
  env: 'sandbox',
  gitHubAuth: {
    client_id: '7c1437c1be7308258db9',
  },
})

var nonprod = merge(base, {
  env: 'nonprod',
  apiUrl: 'https://5e8pmx4jz8.execute-api.us-west-2.amazonaws.com/nonprod/v1',
  gitHubAuth: {
    client_id: 'bccfa34618d4ec0d6a3b',
  },
})

var prod = merge(base, {
  env: 'prod',
  apiUrl: 'https://uyunhpis7b.execute-api.us-west-2.amazonaws.com/prod/v1',
  oAuth: {
    redirectUri: 'https://portal.mftaws.nike.com',
  },
  gitHubAuth: {
    client_id: '5114592511f204334ace',
  },
  nark: narkProd,
})

let config
if (isProd) {
  config = prod
} else if (
  window.location.href.includes('nonprod-portal') ||
  window.location.href.includes('//console-nonprod.platforms.nike.com/mft')
) {
  config = nonprod
} else if (window.location.href.includes('sandbox-portal')) {
  config = sandbox
} else {
  config = localhost
}

export default config
config.appVersion = '22a2552e'
