import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faBan,
  faBolt,
  faCalendarAlt,
  faCheck,
  faCheckCircle,
  faCircle,
  faClipboard,
  faCloudUploadAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faHandPointRight,
  faHourglass,
  faHourglassHalf,
  faInfoCircle,
  faPencilAlt,
  faPlus,
  faSyncAlt,
  faTimes,
  faTrash,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

export const registerFontAwesomeIcons = () => {
  library.add(
    faAngleLeft,
    faAngleRight,
    faArrowLeft,
    faArrowRight,
    faBan,
    faBolt,
    faCalendarAlt,
    faCheck,
    faCheckCircle,
    faCircle,
    faClipboard,
    faCloudUploadAlt,
    faExclamationCircle,
    faExclamationTriangle,
    faHandPointRight,
    faHourglass,
    faHourglassHalf,
    faInfoCircle,
    faPencilAlt,
    faPlus,
    faSyncAlt,
    faTimes,
    faTrash,
    faUser
  )
}
