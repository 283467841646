import React from 'react'

const Alert = ({ message, level = 'error', closeButton }) => {
  return (
    <div className={`Alert Alert--${level} show`}>
      { message }
      { closeButton }
    </div>
  )
}

export default Alert
