import React from 'react'

export default function Field({ children, ...attrs }) {
  return (
    <React.Fragment>
      <div className='Field' {...attrs}>
        {children}
      </div>
    </React.Fragment>
  )
}
