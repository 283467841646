import React from 'react'
import { Label, RadioGroup } from '@nike/epic-react-ui'
import { Field } from '__components'
import { SubWizardContext } from '../SubWizard.js'
import { options } from '__util/forms/validators.js'

const NAME = 'direction'

export default function Direction({ stepName }) {
  const { registerField, setField, state } = React.useContext(SubWizardContext)
  const { fields, isNew } = state

  React.useEffect(
    () => registerField(stepName, NAME, [options(['FROM_', 'TO_'])]),
    [registerField, stepName]
  )

  return (
    <Field>
      <Label label='Direction?'>
        <RadioGroup
          disabled={!isNew}
          list={[
            { value: 'FROM_', label: 'Partner will pull files from Nike' },
            { value: 'TO_', label: 'Partner will push files to Nike' },
          ]}
          onChange={isNew ? (value) => setField(NAME, value) : null}
          value={fields[NAME]}
        />
      </Label>
    </Field>
  )
}
