import { createSlice } from '@reduxjs/toolkit'

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState: {
    isLoading: false,
    all: [],
    filtered: [],
    isCreating: false,
    createResults: {},
  },
  reducers: {
    accountsFetchStart: (state) => {
      state.isLoading = true
    },
    accountsFetchDone: (state, action) => {
      state.all = action.payload.accounts
      state.filtered = action.payload.accounts
      state.isLoading = false
    },
    accountsFilter: (state, action) => {
      const name = action.payload.name.toLowerCase()
      state.filtered =
        state.all.length && name
          ? state.all.filter((a) => a.name.toLowerCase().includes(name))
          : state.all
    },
    accountsCreateStart: (state) => {
      state.isCreating = true
    },
    accountsCreateDone: (state, action) => {
      state.isCreating = false
      state.createResults = action.payload.response
    },
  },
})

//export action creators
export const {
  accountsFetchStart,
  accountsFetchDone,
  accountsFilter,
  accountsCreateStart,
  accountsCreateDone,
} = accountsSlice.actions

// export reducer slice
export default accountsSlice.reducer

//export selectors
export const selectAccountsLoading = (state) => state.accounts.isLoading
export const selectAllAccounts = (state) => state.accounts.all || []
export const selectFilteredAccounts = (state) => state.accounts.filtered || []
export const selectCreating = (state) => state.accounts.isCreating
export const selectCreateResults = (state) => state.accounts.createResults || {}
