import React from 'react'

export function JSONReplacer(key, value) {
  if (!value) return undefined
  if (key === 'access_key' || key === 'secret_key') {
    return undefined
  }
  if (key === 'port' || typeof value === 'boolean') {
    return (value).toString()
  }
  // Convert number sequences that don't start with "0" to int
  if (/^[1-9]+[0-9]*$/.test(value)) {
    return parseInt(value)
  }
  return value;
}

export default function PrettyJSON({ children }) {
  return (
    <pre>
      <code className='block'>{JSON.stringify(children, JSONReplacer, 2)}</code>
    </pre>
  )
}
