import React from 'react'
import { Button, Spinner, Table } from '@nike/epic-react-ui'
import { Collapsible, ExtraTableControls } from '__components'
import { getAccount } from '__accounts/api.js'
import { cannotEditDeployed } from '__accounts/VersionSwitcher'
import { TruncatedText } from '__util/strings'
import { GitHubContext } from '__src/github/GitHubAuth'
import DetailsLink from './common/DetailsLink.js'
import EditLink from './common/EditLink.js'
import SiteWizard from './sites/SiteWizard.js'
import { deserializeSite, protocolToWord } from './sites/serialize.js'
import DetailsContext from './DetailsContext.js'

const triggerStyle = { margin: '1em auto', width: 'fit-content' }

const AddButton = ({ disabled, loading, onClick }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      disabledTip={{ message: cannotEditDeployed }}
      small
    >
      {loading ? <Spinner color='#fff' /> : 'Add a Site'}
    </Button>
  )
}

const initialState = {
  // Store the site being edited in state to pass it to the wizard below
  editSite: {},
  mainConfigs: {},
  loading: false,
}

function sitesReducer(state, action) {
  switch (action.type) {
    case 'loading':
      return { ...state, loading: action.isLoading }
    case 'loaded':
      const { editSite, mainConfigs } = action
      if (mainConfigs) {
        return { ...state, loading: false, editSite, mainConfigs }
      } else {
        return { ...state, loading: false, editSite }
      }
    default:
      return state
  }
}

export default function Sites({ isEditing, setEditing, cancelEditing }) {
  const details = React.useContext(DetailsContext)
  const github = React.useContext(GitHubContext)
  const token = github.getToken()
  const { name, canEdit, commitChanges, env, envConfig } = details
  const sites = React.useMemo(() => envConfig?.sites || [], [envConfig])
  const wizardRef = React.useRef()

  const [state, dispatch] = React.useReducer(sitesReducer, initialState)
  const { editSite, loading, mainConfigs } = state

  const openWizard = React.useCallback(
    (editSite) => {
      dispatch({ type: 'loading', isLoading: true })
      getAccount(token, name, 'main')
        .then((response) => {
          dispatch({ type: 'loaded', editSite, mainConfigs: response.configs })
          setTimeout(
            () => {
              // This may be undefined when downstream exceptions occur (e.g. during validation)
              // so check first to avoid a red herring error message.
              wizardRef.current &&
                wizardRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
              setEditing() // Disable other edit links immediately; loading can take a while
            },
            // Wait a moment in order for height of first step of wizard to be known
            100
          )
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    [token, name, setEditing]
  )

  const resetWizard = React.useCallback(() => {
    dispatch({ type: 'loaded', editSite: {} })
    cancelEditing()
  }, [cancelEditing])

  const getDeployedSites = React.useCallback(() => {
    return mainConfigs[env]?.sites || []
  }, [env, mainConfigs])

  const columns = React.useMemo(() => {
    return [
      {
        accessor: 'site_name',
        Header: 'Name',
        Cell: ({ value }) => {
          const site = sites.find((site) => site.site_name === value) || {}
          return (
            <>
              <DetailsLink title={site && site.site_name} getFields={() => deserializeSite(site)} />
              {!isEditing && <EditLink item={site} canEdit={canEdit} openWizard={openWizard} />}
            </>
          )
        },
      },
      {
        accessor: 'contact',
        Header: 'Contact',
        Cell: ({ value }) => <TruncatedText maxChars={50} text={value} />,
      },
      {
        accessor: 'transfer_type',
        Header: 'Type',
      },
      {
        accessor: 'protocol',
        Header: 'Protocol',
        Cell: ({ value }) => protocolToWord(value),
      },
    ]
  }, [canEdit, isEditing, openWizard, sites])

  return (
    <>
      {!isEditing && (
        <ExtraTableControls>
          <AddButton disabled={!canEdit} loading={loading} onClick={() => openWizard({})} />
        </ExtraTableControls>
      )}
      <Table columns={columns} data={sites} withSearchBar searchBarPlaceholder='Search sites...' />

      {canEdit ? (
        <>
          <Collapsible
            open={isEditing}
            trigger={
              <AddButton disabled={isEditing} loading={loading} onClick={() => openWizard({})} />
            }
            triggerWhenOpen={
              <Button onClick={() => resetWizard()} inverse small>
                {loading ? <Spinner /> : 'Cancel '}
              </Button>
            }
            triggerStyle={triggerStyle}
            triggerTagName='div'
          >
            {isEditing && (
              <SiteWizard
                // debug
                commitChanges={commitChanges}
                env={env}
                envConfig={envConfig}
                site={editSite}
                getDeployedSites={getDeployedSites}
                // debug
              />
            )}
            <div ref={wizardRef}></div>
          </Collapsible>
        </>
      ) : (
        <div style={triggerStyle}>
          <Button disabled disabledTip={{ message: cannotEditDeployed }} small>
            Add
          </Button>
        </div>
      )}
    </>
  )
}
