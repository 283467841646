import React from 'react'
import { Label, RadioGroup, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import CyberArkHelp from '../common/CyberArkHelp.js'

export const authTypeOptions = [
  { label: 'Password - stored in CyberArk', value: 'cyberark' },
  { label: 'Password - stored in Cerberus', value: 'cerberus' },
  { label: 'Certificate - I will upload the public key', value: 'cert' },
]

export default function AuthTypeField({ env, disabled = false, onChange, value = '' }) {
  return (
    <Field>
      <Label label='Authentication' />
      <HelpText>How will you authenticate this connection?</HelpText>
      {disabled ? (
        <TextInput disabled value={value} />
      ) : (
        <RadioGroup list={authTypeOptions} onChange={onChange} value={value} />
      )}
      {value === 'cyberark' && <CyberArkHelp env={env} />}
    </Field>
  )
}
