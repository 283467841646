import React from 'react'

export default function ButtonBar({
  children,
  centered = false,
  topRight = false,
  margin = 5,
  ...attrs
}) {
  let classes = ['ButtonBar']
  if (topRight) {
    classes.push('top-right')
  } else if (centered) {
    classes.push('centered')
  }
  return (
    <div className={classes.join(' ')} {...attrs}>
      {React.Children.map(children, (button, index) => (
        <div key={index} style={{ margin: margin }}>
          {button}
        </div>
      ))}
    </div>
  )
}
