import React from 'react'
import { Table } from '@nike/epic-react-ui'
import DetailsContext from './DetailsContext.js'

const columns = [
  {
    accessor: 'cert_name',
    Header: 'Name',
  },
  {
    accessor: 'cert_usage',
    Header: 'Usage',
  },
  {
    accessor: 'cert_passphrase',
    Header: 'Passphrase',
  },
]

export default function Certs() {
  const details = React.useContext(DetailsContext)
  const { envConfig } = details
  const certs = envConfig?.certs || []

  return (
    <Table
      columns={columns}
      data={certs}
      withSearchBar
      searchBarPlaceholder='Search certficates...'
    />
  )
}
