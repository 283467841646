import React from 'react'
import { Label, Select } from '@nike/epic-react-ui'
import { Field, HelpText, ValidationError } from '__components'
import { findValue, listToSelectOptions } from '__util/select.js'
import { SiteWizardContext } from '../SiteWizard.js'
import { required, string } from '__util/forms/validators.js'

const regionOptions = listToSelectOptions(['us-east-1', 'us-west-1', 'us-west-2'])

export default function S3Region({ name, stepName }) {
  const { registerField, setField, state } = React.useContext(SiteWizardContext)
  const { errors, fields } = state

  React.useEffect(
    () =>
      registerField(
        stepName,
        name,
        [
          required(),
          string({
            regex: /^[a-z]{2}[-][a-z]{1,10}[-][0-9]{1}$/i,
            regexExplanation: 'Must use the format "<Two letter country>-<region>-<number>"',
          }),
        ],
        'us-west-2'
      ),
    [registerField, name, stepName]
  )

  const onChange = (str) => {
    setField(name, str)
  }

  return (
    <Field>
      <Label label='Region' required>
        <HelpText>Select the region, or type to add a custom region</HelpText>
        <Select
          hasErrors={errors[name]}
          onChange={(option) => onChange(option.value)}
          options={regionOptions}
          value={findValue(regionOptions, fields[name])}
          isCreatable
          onCreateOption={(str) => {
            regionOptions.push({
              value: str,
              label: str,
            })
            onChange(str)
          }}
        />
        <ValidationError message={errors[name]} />
      </Label>
    </Field>
  )
}
