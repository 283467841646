import React from 'react'
import { SiteWizardContext } from '../SiteWizard.js'
import { Label } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'

const suites = [
  'TLS_ECDHE_RSA_WITH_AES_256_GCM_SHA384',
  'TLS_ECDHE_RSA_WITH_AES_256_CBC_SHA384',
  'TLS_ECDHE_RSA_WITH_AES_128_GCM_SHA256',
  'TLS_ECDHE_RSA_WITH_AES_128_CBC_SHA256',
  'TLS_RSA_WITH_AES_256_GCM_SHA384',
  'TLS_RSA_WITH_AES_256_CBC_SHA256',
  'TLS_RSA_WITH_AES_128_GCM_SHA256',
  'TLS_RSA_WITH_AES_128_CBC_SHA256',
]

export default function CipherSuites({ name, stepName }) {
  const { registerField } = React.useContext(SiteWizardContext)

  React.useEffect(() => registerField(stepName, name, [], suites.join(', ')), [
    registerField,
    name,
    stepName,
  ])

  return (
    <Field>
      <Label label='Cipher Suites'>
        <HelpText>
          <p>
            We support the following cipher suites. Please contact LST-MFT@nike.com if the cipher
            suite you need is not in this list.
          </p>
          <ul>
            {suites.map((suite) => (
              <li key={suite}>{suite}</li>
            ))}
          </ul>
        </HelpText>
      </Label>
    </Field>
  )
}
