import React from 'react'
import { Container } from '@nike/epic-react-ui'

const PageHeader = ({ title, intro, children }) => {
  let headerStyle = ['epic-font-brand flex-fill']

  return (
    <Container className='flex-container start page-header'>
      <h1 className={headerStyle.join(' ')}>{title}</h1>
      {intro ? <p>{intro}</p> : null}
      {React.Children.count(children) ? <div className='flex-static'>{children}</div> : null}
    </Container>
  )
}

export default PageHeader
