import React from 'react'
import { SiteWizardContext } from '../SiteWizard.js'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText, ValidationError } from '__components'
import { email, required } from '__util/forms/validators.js'

export default function NikeEmail({ name, stepName }) {
  const { registerField, setField, state } = React.useContext(SiteWizardContext)
  const { errors, fields } = state

  React.useEffect(
    () => registerField(stepName, name, [required(), email()]),
    [name, registerField, stepName]
  )

  return (
    <Field>
      <Label label='Partner Email' required>
        <HelpText>
          Email address provided by this partner for this specific transfer, will be notified of
          every failed transfer.
        </HelpText>
        <TextInput
          hasErrors={errors[name]}
          onChange={(e) => setField(name, e.target.value)}
          value={fields[name] || ''}
        />
        <ValidationError message={errors[name]} />
      </Label>
    </Field>
  )
}
