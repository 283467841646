import React from 'react'
import { Label, RadioGroup } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import { listToSelectOptions } from '__util/select.js'
import { SiteWizardContext } from '../SiteWizard.js'

const options = ['async', 'sync']
const defaultValue = 'sync'

export default function AckType({ name, stepName }) {
  const { registerField, setField, state } = React.useContext(SiteWizardContext)

  React.useEffect(() => registerField(stepName, name, [], defaultValue), [
    name,
    registerField,
    stepName,
  ])

  return (
    <Field>
      <Label label='How Does the Partner Acknowledge AS2 Transfers?'>
        <HelpText>
          This information is provided by the trading partner. If you are unsure, please ask them
          their preferred acknowledgement method.
        </HelpText>
        <RadioGroup
          list={listToSelectOptions(options)}
          onChange={(value) => setField(name, value)}
          value={state.fields[name] || ''}
        />
      </Label>
    </Field>
  )
}
