import React, { createContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { api } from '__util'
import {
  isSupport,
  isSupportOrHigher,
  isDevOrHigher,
  isLeadOrHigher,
  isAdmin,
  isPlatformAdmin,
  isGitHubUser,
} from './permissions'

const defaultUser = {
  id: null,
  email: null,
  given_name: null,
  last_name: null,
  groups: [],
}

// State where UI is waiting for a response (reset via `doneWaiting` action)
const noWaiting = { waiting: '' }
const noErrors = { alerts: {}, errors: {} }
const initialState = {
  user: { ...defaultUser },
  ...noErrors,
  ...noWaiting,
}

const viewerReducer = (state, action) => {
  switch (action.type) {
    case 'waiting':
      return { ...state, waiting: action.kind }
    case 'doneWaiting':
      return { ...state, ...noWaiting }
    case 'updateUser':
      return { ...state, ...noWaiting, ...noErrors, user: action.user }
    case 'alert':
      console.error('ViewerContext alert: ', action.alerts)
      return { ...state, ...noWaiting, ...noErrors, alerts: action.alerts }
    default:
      console.error('unexpected action type: ', action.types)
  }
  // console.log('---> ViewerState:', state)
  return state
}

export const ViewerState = ({ email, children }) => {
  const [state, dispatch] = useReducer(viewerReducer, initialState)

  useEffect(() => {
    if (email) {
      load(email)
    }
  }, [email])

  const load = (email) => {
    dispatch({ type: 'waiting', kind: 'load' })
    api
      .get('users', email)
      .then(({ item }) => dispatch({ type: 'updateUser', user: item }))
      .catch((response) => {
        dispatch({ type: 'alert', alerts: response.errors || response })
      })
  }

  return (
    <ViewerContext.Provider
      value={{
        alerts: state.alerts,
        errors: state.errors,
        waiting: state.waiting,
        ...state.user,
        isGitHubUser: () => isGitHubUser(state.user),
        isSupport: () => isSupport(state.user),
        isSupportOrHigher: () => isSupportOrHigher(state.user),
        isDevOrHigher: () => isDevOrHigher(state.user),
        isLeadOrHigher: () => isLeadOrHigher(state.user),
        isAdmin: () => isAdmin(state.user),
        isPlatformAdmin: () => isPlatformAdmin(state.user),
        load,
      }}
    >
      {children}
    </ViewerContext.Provider>
  )
}

export const ViewerContext = createContext({})

ViewerState.propTypes = {
  email: PropTypes.string,
  children: PropTypes.node.isRequired,
}
