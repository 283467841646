import React from 'react'
import { HelpText } from '__components'

export default function SiteWizardHeader({ wizardState }) {
  const { isNew, fields, getDeployedSites } = wizardState
  const label = isNew ? 'Creating' : 'Editing'
  let name = fields.site_name
  // Blank name when wizard first starts up. After NameStep, name should have a less confusing value.
  name = name === 'SFTP' ? '' : name
  const isDeployed = getDeployedSites().includes(name)
  return (
    <div className='text-center'>
      <h3>
        {label} Site {name}
      </h3>
      {isDeployed && (
        <HelpText error>
          This site is already implemented in MFT. You will only be able to edit certain fields.
        </HelpText>
      )}
    </div>
  )
}
