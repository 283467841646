import React from 'react'
import { PrettyJSON } from '__components'

export default function ItemDetails({ fields }) {
  return (
    <>
      <table className='UnstyledTable' style={{ margin: '0 auto' }}>
        <tbody>
          {Object.entries(fields).map(([name, value], index) => {
            if (typeof value === 'object') {
              return (
                <tr key={`item-field-${index}`}>
                  <td style={{ textAlign: 'right', padding: '0.5em 1em' }}>{name}</td>
                  <td>
                    <PrettyJSON>{value}</PrettyJSON>
                  </td>
                </tr>
              )
            } else {
              return (
                <tr key={`item-field-${index}`}>
                  <td style={{ textAlign: 'right', padding: '0.5em 1em' }}>{name}</td>
                  <td>{value}</td>
                </tr>
              )
            }
          })}
        </tbody>
      </table>
    </>
  )
}
