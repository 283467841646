import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Label, Select } from '@nike/epic-react-ui'
import { HelpText } from '__components'
import { findValue, listToSelectOptions } from '__util/select.js'
import { AlertsContext } from '__components'
import { GitHubContext } from '__src/github/GitHubAuth'
import { listAccounts } from '__accounts/api'
import { selectAllAccounts } from '__state/slices/accounts.js'

export default function PullTargetAccount({ error, formValues, onChange, value }) {
  const alerts = React.useContext(AlertsContext)
  const github = React.useContext(GitHubContext)
  const token = github.getToken()
  const dispatch = useDispatch()
  const allAccounts = useSelector(selectAllAccounts)

  React.useEffect(() => {
    // Handle the case where the list of all accounts is not yet in redux, because the user
    // did not first visit /accounts.
    async function fetchAccountsNow() {
      listAccounts(token)
        .then((response) => dispatch({ type: 'accountsFetchDone', accounts: response }))
        .catch((error) => {
          alerts.add('Failed to fetch list of accounts. Please try again.')
        })
    }

    if (formValues.initiated === 'pull' && !allAccounts.length) {
      fetchAccountsNow()
    }
  }, [alerts, allAccounts.length, dispatch, formValues, token])

  const options = listToSelectOptions(allAccounts.map((a) => a.name))

  return (
    formValues.initiated === 'pull' && (
      <Label key='TargetAccount' label='Target Account' required>
        <Select
          hasErrors={error}
          onChange={onChange}
          options={options}
          value={findValue(options, value)}
        />
        <HelpText>
          What account is this target associated with? If the account does not already exist please
          create it in this environment before deploying.
        </HelpText>
      </Label>
    )
  )
}
