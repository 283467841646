import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Alert from './Alert'
import { CloseButton } from '__components'

export const AlertsContext = React.createContext({})

export const Alerts = ({ children }) => {
  // Track visible alerts in a ref so avoid renders which interfere with our dismiss timers.
  const alerts = useRef([])
  const autoDismissTimers = useRef({})
  const [count, setCount] = useState(0)

  // Re-render whenever count changes. Required since ref changes do not trigger renders.
  useEffect(() => {
    const timers = autoDismissTimers.current

    // Clean up when unmounting: cancel timers
    return () => {
      Object.values(timers).map((id) => window.clearTimeout(id))
    }
  }, [count])

  /**
   * Show an alert
   * @param {object} alert with these props:
   *        message: string
   *        dismiss: bool or a number of seconds after which it should be auto-dismissed
   *        level: string - one of info, warn, error, success
   */
  const add = (alert) => {
    const msg = alert.message
    if (alerts.current.find((a) => a.message === msg)) {
      return console.warn('Cannot add duplicate alert messages')
    }

    alerts.current.push(alert)

    if (alert.dismiss !== false) {
      // Set a timer, default 10s
      autoDismissTimers.current[msg] = setTimeout(() => {
        remove(msg)
      }, (alert.dismiss || 10) * 1000)
    }

    setCount(alerts.current.length) // trigger render
  }

  const remove = (msg) => {
    alerts.current = [...alerts.current.filter((a) => a.message !== msg)]
    window.clearTimeout(autoDismissTimers.current[msg])
    setCount(alerts.current.length) // trigger render
  }

  return (
    <AlertsContext.Provider
      value={{
        add,
        remove,
      }}
    >
      <div className='Alerts'>
        {alerts.current.map((alert, index) => (
          <Alert
            key={`alert-${index}`}
            message={alert.message}
            level={alert.level}
            closeButton={
              alert.dismiss ? (
                <CloseButton
                  onClick={() => {
                    console.log('clearing timer')
                    remove(alert.message)
                  }}
                />
              ) : (
                ''
              )
            }
          />
        ))}
      </div>
      {children}
    </AlertsContext.Provider>
  )
}

Alerts.propTypes = {
  children: PropTypes.node.isRequired,
}
