import React from 'react'
import config from '__config'
import { ViewerContext } from '../users/ViewerContext'
import { AlertsContext } from '__components'
import { api, http } from '__util'
import qs from 'qs'

// GitHub auth flow involves redirects, so state is lost unless it's stored
// in a persistent way. We elected to use sessionStorage to save the following
// in order to persist them across redirects.
const REFERRER_KEY = 'MFT_GitHub_Auth:referrer'
const STATUS_KEY = 'MFT_GitHub_Auth:status'
const TOKEN_KEY = 'MFT_GitHub_Auth:token'
const CODE_KEY = 'MFT_GitHub_Auth:code'
const ALERT_KEY = 'MFT_GitHub_Auth:alert'
const GITHUB_AUTH_URL = `${config.gitHubAuth.authUrl}?client_id=${config.gitHubAuth.client_id}&scope=repo`

const redirectToGitHub = () => {
  window.location.assign(GITHUB_AUTH_URL)
}

const saveRequestedUrl = () => {
  window.sessionStorage.setItem(REFERRER_KEY, window.location.href)
}

export const restoreRequestedUrl = () => {
  const referrer = window.sessionStorage.getItem(REFERRER_KEY)
  if (referrer !== window.location.href) {
    // Use replace so the GitHub auth flow is not stored in history
    window.location.replace(referrer)
  }
}

export const setAuthCode = (code) => window.sessionStorage.setItem(CODE_KEY, code)
export const getAuthCode = () => window.sessionStorage.getItem(CODE_KEY)
const setToken = (token) => window.sessionStorage.setItem(TOKEN_KEY, token)
const getToken = () => window.sessionStorage.getItem(TOKEN_KEY)
const setStatus = (status) => window.sessionStorage.setItem(STATUS_KEY, status)
const getStatus = () => window.sessionStorage.getItem(STATUS_KEY)
const setAlert = (alert) => window.sessionStorage.setItem(ALERT_KEY, JSON.stringify(alert))
const getAlert = () => {
  const alert = window.sessionStorage.getItem(ALERT_KEY)
  return alert ? JSON.parse(alert) : alert
}

async function convertAuthCodeToToken(authCode, onError, onSuccess) {
  const response = await http.get(api.url(`users/github-token?code=${authCode}`))
  if (response.status === 'failure') {
    onError(response.response)
    return
  }

  try {
    const data = qs.parse(response.response)
    const token = data.access_token
    onSuccess(token)
  } catch (error) {
    onError(error)
  }
}

export const GitHubState = ({ children }) => {
  const alerts = React.useContext(AlertsContext)
  const viewer = React.useContext(ViewerContext)
  const isGitHubUser = viewer.isGitHubUser()

  const authFlowStart = React.useCallback((opts) => {
    const silent = opts?.silent || false
    setStatus(silent ? 'renewing' : 'authorizing')
    setToken('')
    setAuthCode('')
    saveRequestedUrl()
    setTimeout(redirectToGitHub, 500)
  }, [])

  const authFlowSuccess = React.useCallback((token) => {
    const status = getStatus()
    setToken(token)
    setTimeout(restoreRequestedUrl, 500)
    if (status !== 'renewing') {
      setAlert({
        level: 'success',
        message: 'This app can now view and manage accounts and transfers on your behalf.',
        dismiss: 5,
      })
    }
    setStatus('authorized')
  }, [])

  const authFlowError = React.useCallback((error) => {
    console.error(error)
    setStatus('failed')
    setAuthCode('')
    setToken('')
    setAlert({
      level: 'error',
      message:
        'Failed to convert GitHub auth code to a token. Please contact the MFT team for assistance.',
    })
  }, [])

  // Given an HTTP error, determine if user's token looks expired. If so, kick off the
  // GitHub auth flow and then redirect back to the referrer.
  const renewTokenIfExpired = React.useCallback(
    (error) => {
      // Note: we should avoid infinite loop in the case where this refresh still leads to a
      // Bad credentials error from the API.
      if (error.message?.includes('Bad credentials')) {
        authFlowStart({ silent: true })
      }
    },
    [authFlowStart]
  )

  const finishAuthFlow = React.useCallback(async () => {
    const code = getAuthCode()
    code && (await convertAuthCodeToToken(code, authFlowError, authFlowSuccess))
  }, [authFlowError, authFlowSuccess])

  React.useEffect(() => {
    if (!isGitHubUser) return

    // Fetch status from sessionStorage because it persists across the various redirects, unlike React state.
    const status = getStatus()
    const token = getToken()

    switch (status) {
      case 'authorizing':
      case 'renewing':
        finishAuthFlow()
        break
      case 'failed':
        break
      case 'authorized':
        if (token) {
          // Now that we've been redirected, show success/error alert if defined in sessionStorage
          const alert = getAlert()
          if (alert) {
            setAlert(null)
            alerts.add(alert)
          }
        } else {
          console.log('status authorized but github token is undefined.')
          authFlowStart({ silent: true })
        }
        break
      default:
        if (!token) {
          console.log('Undefined flow status and token; start github auth flow')
          authFlowStart()
        }
    }
  }, [alerts, authFlowStart, finishAuthFlow, isGitHubUser])

  return (
    <GitHubContext.Provider
      value={{
        authFlowStart,
        getToken,
        renewTokenIfExpired,
      }}
    >
      {children}
    </GitHubContext.Provider>
  )
}

export const GitHubContext = React.createContext({})
