import React from 'react'
import { Label, Select } from '@nike/epic-react-ui'
import { Field, ValidationError } from '__components'
import { findValue, listToSelectOptions } from '__util/select.js'
import { SubWizardContext } from '../SubWizard.js'
import { required } from '__util/forms/validators.js'

const NAME = 'source_site_name'

export default function SourceSite({ existingSiteNames, stepName }) {
  const { registerField, setField, state } = React.useContext(SubWizardContext)
  const { errors, fields } = state
  const siteOptions = listToSelectOptions(existingSiteNames.filter((n) => !n.includes('OUTBOUND_')))

  React.useEffect(
    () =>
      registerField(stepName, NAME, [required((v, state) => state.fields.nikeInitiated === true)]),
    [registerField, stepName]
  )

  return (
    <Field>
      <Label label='Source Site' required>
        <Select
          hasErrors={errors[NAME]}
          onChange={(option) => setField(NAME, option.value)}
          options={siteOptions}
          value={findValue(siteOptions, fields[NAME])}
        />
      </Label>
      <ValidationError message={errors[NAME]} />
    </Field>
  )
}
