export const generateName = (state, existingSiteNames, isNew) => {
  const role = state.role === 'target' ? 'OUTBOUND' : ''
  const job = state.role === 'source' ? state.jobName || '' : ''
  const sys = state.systemName === 'other' ? state.otherSystemName || '' : state.systemName || ''
  const protocol = normalizeProtocol(state.protocol)

  const parts = [role, job, sys.toUpperCase(), protocol]
  // string of non-empty parts separated by one '_'
  const name = parts.filter((p) => p).join('_')
  // When creating a new site, name must not collide with existing name
  return isNew ? uniqueSiteName(name, existingSiteNames) : name
}

/**
 * Return protocol part of a site name, or default 'SFTP'.
 *
 * @param {*} protocol - value from dropdown. These have a certain value required by the sites JSON schema.
 */
const normalizeProtocol = (protocol) => {
  switch (protocol) {
    case 'ssh':
    case undefined:
      return 'SFTP'
    case 'azure-blob':
      return 'AZUREBLOB'
    default:
      return protocol.toUpperCase()
  }
}

/**
 * Returns provided name. Appends a sequence number to it if there is already such a name in siteNames.
 * @param {string} name
 * @param {array of strings} siteNames
 */
const uniqueSiteName = (name, siteNames = []) => {
  const matchingName = siteNames.find((n) => n === name)
  if (matchingName) {
    const endingSeq = /.*_(\d+)$/
    const seq = matchingName.match(endingSeq) || 0
    if (seq !== null) {
      name += `_${seq + 1}`
    }
  }
  return name
}

export const parseSiteName = (site) => {
  const role = site.site_name.startsWith('OUTBOUND_') ? 'target' : 'source'
  const parts = site.site_name.split('_')
  let jobName = ''
  let systemName = ''
  let otherSystemName = ''

  if (role === 'source') {
    jobName = parts[0] || ''
  }

  if (['EDI', 'FUSION', 'INFA'].includes(parts[1])) {
    systemName = parts[1]
  } else {
    systemName = 'other'
    otherSystemName = parts[1] || ''
  }

  return {
    jobName,
    systemName,
    otherSystemName,
    role,
  }
}
