import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from '@nike/epic-react-ui'

/**
 * Given a list of objects representing resubmit attempts, generate a summary
 * of passed and failed resubmits. Each transfer ID may have multiple attempts
 * since there are multiple Axway backends.
 *
 * Return {
 *   <transferid>: {Bool<passeed>, node<message>}
 * }
 *
 * @param {*} resubmitResults
 * @param {*} searchResults
 */
const formatResubmitResults = (resubmitResults, searchResults) => {
  const results = {}

  // Combine all results with matching transferid into a single status:
  // success if any succeeded, or failed if all failed.
  resubmitResults.forEach((result) => {
    const id = result.transfer_id
    if (!results[id] || !results[id].passed) {
      results[id] = {
        passed: result.code === 200,
      }
      const key = `result-${id}`
      // The Axway API does not provide file names as part of the submit response, so
      // correlate against our initial search results by matching transfer ID.
      const { filename } = searchResults.find((item) => item.transferid === id)

      results[id].message =
        result.code === 200 ? (
          <div key={key}>
            <FontAwesomeIcon icon='check' className='epic-color-success' /> {filename}
          </div>
        ) : (
          <div key={key}>
            <Tooltip message={`Processed by ${result.processed_by}`} key={key} size='large'>
              <FontAwesomeIcon icon='exclamation-triangle' className='epic-color-error' />{' '}
              {filename}
            </Tooltip>
          </div>
        )
    }
  })

  return results
}

const Warn = ({ children }) => (
  <div>
    <FontAwesomeIcon icon='exclamation-triangle' className='epic-color-warn' /> {children}
  </div>
)

const showResubmitResultsDialog = ({
  dispatch,
  resubmitResults,
  searchResults,
  numSkippedEmptyFiles,
  numSkippedExcessiveFiles,
  maxFilesToResubmit,
}) => {
  const results = formatResubmitResults(resubmitResults, searchResults)
  const lines = []
  console.log('---> results', results)
  const countPassed = Object.values(results).filter(({ passed }) => passed).length
  console.log('---> countPassed', countPassed)
  const messages = Object.values(results).map((r) => r.message)

  if (countPassed !== messages.length) {
    lines.push(
      <Warn key='w1'>
        Some resubmit requests did not succeed. Try again or contact an MFT admin.
      </Warn>
    )
  }
  if (numSkippedEmptyFiles) {
    lines.push(<Warn key='w2'>{numSkippedEmptyFiles} empty files were skipped.</Warn>)
  }
  if (numSkippedExcessiveFiles) {
    lines.push(<Warn key='w3'>Only the first {maxFilesToResubmit} files were resubmitted.</Warn>)
  }
  let summary
  if (countPassed === messages.length) {
    summary = countPassed
      ? 'All files were successfully resubmitted.'
      : 'No files were successfully resubmitted.'
  } else {
    summary = `${countPassed} of ${messages.length} files were successfully resubmitted.`
  }
  lines.push(summary)
  lines.push(<hr key='blah' />)

  dispatch({
    type: 'openDialog',
    title: 'Resubmit Files',
    content: lines.concat(messages),
    redoSearchOnClose: true, // reset
  })
}

export default showResubmitResultsDialog
