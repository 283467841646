import { addBanner } from '__state/slices/banners'
import { setAuthToken } from '__util/http.js'
import client from './client.js'

export function configureAuthEvents(store) {
  const { dispatch } = store
  client.on('login:success', ({ accessToken }) => setAuthToken(accessToken.accessToken))
  client.on('renew:access:success', (accessToken) => setAuthToken(accessToken))
  client.on('login:error', (message) => {
    console.log(`keys`, Object.keys(message))
    if (typeof message === 'object') {
      message = message.errorSummary || message.name
    }
    message && dispatch(addBanner({ message, level: 'error' }))
  })
  client.on('renew:error', (message) => message && dispatch(addBanner({ message, level: 'error' })))
}
