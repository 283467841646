import React, { useState } from 'react'
import { Button, Table, NextGen, IconButton } from '@nike/epic-react-ui'
import { ExtraTableControls } from '__components'
import DetailsContext from './DetailsContext.js'
import { capitalize } from '../../util/strings.js'

const columns = [
  {
    accessor: 'nikeID',
    Header: 'ID',
  },
  {
    accessor: 'formattedRole',
    Header: 'Role',
  },
  {
    accessor: 'email',
    Header: 'Email',
  },
]

const formatMemberData = (data) => {
  return data.map((member) => ({
    ...member,
    nikeID: member.username.replace('_nike', ''),
    formattedRole: capitalize(member.role),
  }))
}

export default function Members() {
  const details = React.useContext(DetailsContext)
  const members = details.members || []
  const [showModal, setShowModal] = useState(false)
  const setModalVisibility = (toggleValue) => () => setShowModal(toggleValue)

  return (
    <div className='team-members-wrapper'>
      <IconButton type='Club' onClick={setModalVisibility(true)} inverse>
        Team Members
      </IconButton>
      <NextGen.Modal show={showModal} onClose={setModalVisibility(false)} className='member-modal'>
        <ExtraTableControls>
          <Button className='member-edit' disabled small>
            Edit
          </Button>
        </ExtraTableControls>
        <Table
          className='members-table'
          columns={columns}
          data={formatMemberData(members)}
          withSearchBar
          searchBarPlaceholder='Search members...'
        />
      </NextGen.Modal>
    </div>
  )
}
