export const getPropsIfDefinedInState = (propNames, sourceObj) => {
  const props = {}
  const sourceKeys = Object.keys(sourceObj)
  propNames.forEach((name) => {
    if (sourceKeys.includes(name)) {
      props[name] = sourceObj[name]
    }
  })
  return props
}
