import AS2Intro from './AS2Intro.js'
import { default as ackType } from './AckType.js'
import { default as authType } from './AuthType.js'
import { default as cerberus_sdb_path } from './CerberusPath.js'
import { default as cipher_suites } from './CipherSuites.js'
import { default as content_type } from './AS2ContentType.js'
import { default as delete_after_download } from './DeleteAfterDownload.js'
import { default as download_folder } from './DownloadFolder.js'
import { default as download_object_key } from './DownloadObjectKey.js'
import { default as download_pattern } from './DownloadPattern.js'
import { default as download_subfolders } from './DownloadSubfolders.js'
import { default as host } from './Host.js'
import { default as is_active } from './IsActive.js'
import { default as max_concurrent_files } from './MaxConcurrentFiles.js'
import { default as mdn_async_type } from './AckProtocol.js'
import { default as nike_as2_id } from './NikeAS2Id.js'
import { default as nike_as2_cert_name } from './NikeAS2CertName.js'
import { default as nike_email } from './NikeEmail.js'
import { default as partner_as2_id } from './PartnerAS2Id.js'
import { default as partner_as2_url } from './PartnerAS2Url.js'
import { default as partner_as2_cert_name } from './PartnerAS2CertName.js'
import { default as partner_email } from './PartnerEmail.js'
import { default as port } from './Port.js'
import { default as receive_file_as } from './ReceiveFileAs.js'
import { default as role_arn } from './RoleArn.js'
import { default as s3_bucket_access } from './S3BucketAccess.js'
import { default as username } from './Username.js'
import { default as s3_bucket } from './S3Bucket.js'
import { default as s3_region } from './S3Region.js'
import { default as sign_alg } from './AS2SignAlg.js'
import { default as ssh_cert_name } from './SSHCertName.js'
import { default as system_metadata } from './SystemMetadata.js'
import { default as tls_versions } from './TLSVersions.js'
import { default as transfer_mode } from './TransferMode.js'
import { default as upload_permissions } from './UploadPermissions.js'

const siteFields = {
  AS2Intro,
  ackType,
  authType,
  cerberus_sdb_path,
  cipher_suites,
  content_type,
  delete_after_download,
  download_folder,
  download_object_key,
  download_pattern,
  download_subfolders,
  host,
  is_active,
  max_concurrent_files,
  mdn_async_type,
  nike_as2_id,
  nike_as2_cert_name,
  nike_email,
  partner_as2_id,
  partner_as2_url,
  partner_as2_cert_name,
  partner_email,
  port,
  receive_file_as,
  username,
  role_arn,
  s3_bucket_access,
  s3_bucket,
  s3_region,
  sign_alg,
  ssh_cert_name,
  system_metadata,
  tls_versions,
  transfer_mode,
  upload_permissions,
}

export default siteFields
