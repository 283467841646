import React, { forwardRef, useEffect } from 'react'

// Renders 3 possible states
const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <React.Fragment>
      <input type='checkbox' ref={resolvedRef} {...rest} />
    </React.Fragment>
  )
})

export default IndeterminateCheckbox
