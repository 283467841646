import React from 'react'
import { Tooltip, Table, Thead, Th, Tbody, Tr, Td } from '@nike/epic-react-ui'
import { useTable, useRowSelect } from 'react-table'
import IndeterminateCheckbox from './IndeterminateCheckbox'

/**
 * A React Table with row selection. Adds a column with checkboxes with a 'check/uncheck all' heading.
 * State of selected rows is made available to an optional toolbar component.
 *
 * @param {Array} columns: core columns configuration object for react-table
 * @param {Array} data: list of row objects
 * @param {func} isRowPickable: optional function takes `row` and returns [Bool<canPick>, String<whyNot>].
 *   If row cannot be picked, a ban icon is shown with `whyNot` in a tooltip.
 * @param {component|func} toolbar: optional function or component that renders a toolbar
 *   for performing actions on the table. It receives parameter `selectedRows`.
 */
export const PickTable = ({
  columns,
  data,
  isRowPickable = (row) => true,
  toolbar,
  ...tableProps
}) => {
  const rt = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Tooltip message='Select/unselect all rows'>
              <IndeterminateCheckbox id='checkboxAll' {...getToggleAllRowsSelectedProps()} />
            </Tooltip>
          ),
          Cell: ({ row }) => {
            const [isPickable, whyNot] = isRowPickable(row.original)
            return isPickable ? (
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            ) : (
              <Tooltip message={whyNot}>
                <IndeterminateCheckbox disabled style={{ cursor: 'ban' }} />
              </Tooltip>
            )
          },
        },
        ...columns,
      ])
    }
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // Props provided by useRowSelect plugin
    selectedFlatRows,
    // state: { selectedRowIds },
  } = rt

  return (
    <React.Fragment>
      {toolbar && toolbar(selectedFlatRows.map((row) => row.original))}
      <Table {...getTableProps()} className='PickTable'>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.length ? (
            rows.map((row, i) => {
              prepareRow(row)
              return (
                <Tr {...row.getRowProps()} className={row.isSelected ? 'PickTable--picked' : ''}>
                  {row.cells.map((cell) => {
                    return <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                  })}
                </Tr>
              )
            })
          ) : (
            <Tr>
              {/* Span all columns including the selection column */}
              <Td colSpan={columns.length + 1} className='text-center'>
                No matching files found
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </React.Fragment>
  )
}
