import { configureStore } from '@reduxjs/toolkit'
import { configureAuthEvents } from '__src/auth/events.js'
import accounts from './slices/accounts.js'
import banners from './slices/banners.js'

export const getStore = (initialState) => {
  return configureStore({
    reducer: {
      accounts,
      banners,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV === 'development',
    // Allow unit tests to mock redux state here
    preloadedState: initialState || {},
  })
}

const store = getStore()
configureAuthEvents(store)

export default store
