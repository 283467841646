import React from 'react'
import { Container } from '@nike/epic-react-ui'

export const UserDetails = ({ user }) => {
  return (
    <Container>
      <h3>Groups</h3>
      <p>You belong to the following AD groups that determine your MFT Portal permissions.</p>
      <ul>
        {user.groups &&
          user.groups
            .filter((group) => group.startsWith('Application.MFT'))
            .map((group, i) => <li key={`group-${i}`}>{group}</li>)}
      </ul>
    </Container>
  )
}
