import React from 'react'
import { Label, RadioGroup } from '@nike/epic-react-ui'

const options = [
  { value: 'FAIL', label: 'Fail the transfer' },
  { value: 'PROCEED', label: 'Proceed to next step' },
]

export default function ActionOnStepFailure({ error, onChange, value }) {
  return (
    <Label key='AOSF' label='Action On Step Failure' required>
      <RadioGroup hasErrors={error} list={options} onChange={onChange} value={value} />
    </Label>
  )
}
