import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CloseButton = ({ onClick }) => {
  return (
    <span className='CloseButton' onClick={onClick}>
      <FontAwesomeIcon icon='times' />
    </span>
  )
}

export default CloseButton
