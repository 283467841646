import React from 'react'
import { Label, Select } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import { findValue, objToSelectOptions } from '__util/select.js'
import { SiteWizardContext } from '../SiteWizard.js'
import { uploadPermissionsOptions } from '../serialize.js'

export default function UploadPermissions({ name, stepName }) {
  const { registerField, setField, state } = React.useContext(SiteWizardContext)

  React.useEffect(() => {
    registerField(stepName, name, [], '0644')
  }, [registerField, name, stepName])

  return (
    <Field>
      <Label label='Upload Permissions' />
      <HelpText>Numeric file permission code as used in Posix systems.</HelpText>
      <Select
        options={objToSelectOptions(uploadPermissionsOptions)}
        onChange={(value) => setField(name, value)}
        value={findValue(uploadPermissionsOptions, state.fields[name] || '')}
      />
    </Field>
  )
}
