import { createSlice } from '@reduxjs/toolkit'

export const bannerSlice = createSlice({
  name: 'banners',
  initialState: {
    banners: {},
    nextID: 0,
  },
  reducers: {
    // Action should be an object with the following props:
    //   message (string)
    //   level   (string) One of error, success, warn (default 'error')
    //   request_id (string) Optional
    addBanner: (state, action) => {
      const banner = {
        ...action.payload,
        internal_id: state.nextID,
      }
      state.banners[state.nextID] = banner
      state.nextID++
    },
    removeBanner: (state, action) => {
      delete state.banners[action.payload]
    },
    clearBanners: (state) => {
      state.banners = {}
      state.nextID = 0
    },
  },
})

//export action creators
export const { addBanner, removeBanner, clearBanners } = bannerSlice.actions

// export reducer slice
export default bannerSlice.reducer

//export selectors
export const selectBanners = (state) => state.banners.banners
