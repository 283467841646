import React from 'react'

export default function HelpText({ children, error = false, className, ...attrs }) {
  const classes = ['HelpText']
  if (error) {
    classes.push('error')
  }
  return (
    <React.Fragment>
      <div className={`HelpText ${error && 'error'} ${className}`} {...attrs}>
        {children}
      </div>
    </React.Fragment>
  )
}
