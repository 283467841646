import React from 'react'
import { Label, RadioGroup } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import { listToSelectOptions } from '__util/select.js'
import { SiteWizardContext } from '../SiteWizard.js'

const options = ['${stenv.rawsource}', "${env['DXAGENT_HTTP_SUBJECT']}"]
const defaultValue = '${stenv.rawsource}'

export default function ReceiveFileAs({ name, stepName }) {
  const { registerField, setField, state } = React.useContext(SiteWizardContext)

  React.useEffect(() => registerField(stepName, name, [], defaultValue), [
    name,
    registerField,
    stepName,
  ])

  return (
    <Field>
      <Label label='Receive File As'>
        <HelpText>
          If Partner sends FileName on Subject, choose <code>{options[1]}</code>
        </HelpText>
        <RadioGroup
          list={listToSelectOptions(options)}
          onChange={(value) => setField(name, value)}
          value={state.fields[name] || ''}
        />
      </Label>
    </Field>
  )
}
