import React from 'react'
import { SiteWizardContext } from '../SiteWizard.js'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'

export default function NikeAS2Id({ name, stepName }) {
  const { registerField, state } = React.useContext(SiteWizardContext)
  const { env, fields } = state

  React.useEffect(() => registerField(stepName, name, [], `NIKE${env.toUpperCase()}_AS2`), [
    env,
    registerField,
    name,
    stepName,
  ])

  return (
    <Field>
      <Label label='Nike AS2 ID' required>
        <HelpText>
          The environment of this site determines this value. If you need a second Nike AS2 ID
          (rare), please contact <code>LST-MFT@nike.com</code> for support.
        </HelpText>
        <TextInput disabled value={fields[name] || ''} />
      </Label>
    </Field>
  )
}
