import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { HelpText, ValidationError } from '__components'

export default function PushMaxParallelConnections({ error, formValues, onChange, value }) {
  return (
    formValues.initiated === 'push' && (
      <Label key='PMPC' label='Max Parallel Connections' required>
        <HelpText>How many files can be uploaded concurrently? 0 is unlimited.</HelpText>
        <TextInput hasErrors={error} onChange={onChange} value={value} autoComplete='off' />
        <ValidationError message={error} />
      </Label>
    )
  )
}
