import React from 'react'
import { NotAuthorized } from '__components/NotAuthorized'
import HomePage from '__src/home/HomePage'
import FileTrackerSearchPage from '__src/files/FileTrackerSearchPage'
import UserPage from '__src/users/UserPage'
import GitHubAuthorized from '__src/github/GitHubAuthorized'
import AccountPage from '__src/accounts/AccountPage'
import AccountsPage from '__src/accounts/AccountsPage'

const routes = {
  '/': () => <HomePage />,
  '/accounts': () => <AccountsPage />,
  '/accounts/:name': ({ name }) => <AccountPage name={name} />,
  '/accounts/:name/:version/:env': ({ name, version, env }) => (
    <AccountPage version={version} env={env} name={name} />
  ),
  '/user-profile': () => <UserPage />,
  '/files': () => <FileTrackerSearchPage />,
  '/notauthorized': () => <NotAuthorized />,
  '/github-authorized': () => <GitHubAuthorized />,
}

export default routes
