import { useContext, useEffect } from 'react'
import { AlertsContext } from '__components'

const OfflineBanner = () => {
  const alerts = useContext(AlertsContext)
  const offlineMessage = 'Your browser is offline. Functionality will be limited.'
  const setOfflineState = () => {
    if (navigator.onLine) {
      alerts.remove(offlineMessage)
    } else {
      alerts.add({ message: offlineMessage, level: 'error', dismiss: false })
    }
  }

  useEffect(() => {
    // (This code pulled from old Rocket recipe notifications component)
    // There is a problem with registering these events
    // If you try attach them before 'load' fires they just no-op (Edge/Firefox)
    // If 'load' has already fired it will not fire again
    // To ensure they work in all cases you must do both (yay browser inconsistencies!)
    // To ensure you are not double notified 'load' must also clean up the original listeners
    window.addEventListener('online', setOfflineState)
    window.addEventListener('offline', setOfflineState)

    const loadHandler = () => {
      window.removeEventListener('online', setOfflineState)
      window.removeEventListener('offline', setOfflineState)
      window.addEventListener('online', setOfflineState)
      window.addEventListener('offline', setOfflineState)
    }
    window.addEventListener('load', loadHandler)

    return () => {
      window.removeEventListener('load', loadHandler)
      window.removeEventListener('online', setOfflineState)
      window.removeEventListener('offline', setOfflineState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default OfflineBanner
