import React, { useCallback, useContext } from 'react'
import { ViewerContext } from '../users/ViewerContext'
import { SearchContext } from '__components/search'
import { Button, Tooltip } from '@nike/epic-react-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { mayResubmit } from './validation'

export const FileTrackerButtonBar = ({
  trackerWaiting,
  selectedRows, // array of row objects
  onResubmit,
  onViewDetails,
}) => {
  const viewer = useContext(ViewerContext)
  const search = useContext(SearchContext)
  const env = search.filters.env
  const barDisabled = search.isLoading || trackerWaiting
  const userMayResubmit = mayResubmit(viewer, env)
  const isDetailsButtonDisabled = useCallback(
    () => search.items.length === 0 || (selectedRows && selectedRows.length !== 1),
    [search.items.length, selectedRows]
  )
  const viewDetails = useCallback(
    () => onViewDetails(env, selectedRows[0]),
    [onViewDetails, env, selectedRows]
  )
  const resubmit = useCallback(() => onResubmit(env, selectedRows), [onResubmit, env, selectedRows])
  const prevOffset = getPrevPageOffset(search.filters)
  const nextOffset = getNextPageOffset(search.filters, search.items)
  const onPrev = useCallback(() => {
    search.dispatch({ type: 'addFiltersAndSearch', filters: { offset: prevOffset } })
  }, [prevOffset, search])
  const onNext = useCallback(() => {
    search.dispatch({ type: 'addFiltersAndSearch', filters: { offset: nextOffset } })
  }, [nextOffset, search])
  return (
    <div
      style={{
        display: 'flex',
        marginBottom: '4px',
      }}
    >
      <Button
        onClick={viewDetails}
        disabled={barDisabled || isDetailsButtonDisabled()}
        style={{ marginRight: '4px' }}
        small
      >
        {trackerWaiting === 'getDetails' ? (
          'Fetching...'
        ) : (
          <span>
            <FontAwesomeIcon icon='info-circle' /> View Details
          </span>
        )}
      </Button>
      <Tooltip
        message={
          userMayResubmit
            ? 'Transfer selected files again'
            : 'You do not have permission to resubmit files in this environment'
        }
        position='top'
      >
        <Button
          onClick={resubmit}
          disabled={barDisabled || !userMayResubmit || selectedRows.length === 0}
          style={{ marginRight: '4px' }}
          small
        >
          {trackerWaiting === 'resubmit' ? (
            'Resubmitting...'
          ) : (
            <span>
              <FontAwesomeIcon icon='hand-point-right' /> Resubmit Selected
            </span>
          )}
        </Button>
      </Tooltip>
      {/* Pagination */}
      <Button
        onClick={onPrev}
        disabled={barDisabled || prevOffset === -1}
        style={{ marginLeft: 'auto', marginRight: '4px' }}
        small
      >
        {
          <span>
            <FontAwesomeIcon icon='arrow-left' /> Prev
          </span>
        }
      </Button>
      <Button onClick={onNext} disabled={barDisabled || nextOffset === -1} small>
        {
          <span>
            Next <FontAwesomeIcon icon='arrow-right' />
          </span>
        }
      </Button>
    </div>
  )
}

const getPrevPageOffset = (filters) => {
  const offset = filters.offset || 0
  const pageSize = filters.limit
  if (Object.keys(filters).includes('offset')) {
    return offset >= pageSize ? offset - pageSize : -1 // disabled
  }
  return -1
}

const getNextPageOffset = (filters, items) => {
  const offset = filters.offset || 0
  const pageSize = filters.limit
  if (Object.keys(filters).includes('offset')) {
    return items.length === pageSize
      ? // Enable next button when we have a full page; backend Axway API won't tell us in advance if there are further matching records
        offset + pageSize
      : -1 // disabled
  }
  return pageSize
}
