import React from 'react'
import { Label, RadioGroup } from '@nike/epic-react-ui'
import { HelpText } from '__components'

export default function PullTargetFileCollision({ formValues, onChange, value }) {
  return (
    formValues.initiated === 'pull' && (
      <Label key='ATD' label='Target File Collision' required>
        <HelpText>
          If the file already exists in the target folder before being picked by target, what action
          should be taken?
        </HelpText>
        <RadioGroup
          list={[
            { value: 'FAIL', label: 'Fail the transfer' },
            { value: 'OVERWRITE', label: 'Overwrite existing file' },
          ]}
          onChange={onChange}
          value={value}
          autoComplete='off'
        />
      </Label>
    )
  )
}
