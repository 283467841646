import React, { useContext } from 'react'
import { SearchContext } from './Search.js'

export const SearchSummary = ({ filters, items, totalMatches }) => {
  const itemCount = items.length
  const pageSize = filters.limit
  const offset = filters.offset || 0
  const getPrevPageOffset = () => {
    if (Object.keys(filters).includes('offset')) {
      return offset >= pageSize ? offset - pageSize : -1 // disabled
    }
    return -1
  }
  const fullPage = itemCount === pageSize
  const getNextPageOffset = () => {
    if (Object.keys(filters).includes('offset')) {
      // Enable next button when we have a full page; backend Axway API won't tell us in advance if there are further matching records
      return fullPage ? offset + pageSize : -1 // disabled
    }
    return pageSize
  }
  const prevOffset = getPrevPageOffset()
  const nextOffset = getNextPageOffset()
  let msg = ''

  if (itemCount && pageSize) {
    if (filters.limit < totalMatches) {
      // `total` is never returned by the API yet (Axway limitation)
      msg = `Showing ${itemCount} of ${totalMatches} total`
    } else {
      msg = `Showing ${itemCount} results`
      if (nextOffset > -1 && prevOffset > -1) {
        msg += ` (click Prev or Next for more)`
      } else if (nextOffset > -1) {
        msg += ` (click Next for more)`
      } else if (prevOffset > -1) {
        msg += ` (click Prev for more)`
      }
    }
  }

  return <div className='SearchResults-summary'>{msg}</div>
}

export const SearchResults = ({ renderAddItem, renderItem, renderResults }) => {
  const search = useContext(SearchContext)

  return (
    <div className='SearchResults'>
      <SearchSummary filters={search.filters} items={search.items} totalMatches={0} />
      <div className={renderResults ? '' : 'SearchResults-cards'}>
        {renderAddItem && renderAddItem()}
        {renderResults ? renderResults(search.items) : search.items.map((item) => renderItem(item))}
      </div>
    </div>
  )
}
