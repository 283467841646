import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { findFilterValue } from '__util/select'
import { SearchContext } from './Search'
import { Label, Select, TextInput } from '@nike/epic-react-ui'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const getTextChangeHandler = (name, value, timerRef, dispatch) => {
  return (e) => {
    const newFilter = { [name]: e.target.value }
    // This field is controlled by local state, so update it
    // setTextValues({ ...textValues, ...newFilter })
    // Also restart the timer to trigger a new search after 300ms of idle time
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      dispatch({ type: 'addFilters', filters: newFilter })
    }, 300)
  }
}

export const SearchForm = ({ fields }) => {
  const search = useContext(SearchContext)
  const filters = search.filters
  const [textValues, setTextValues] = useState({})
  // timer is saved in a ref so it isn't wiped out at re-render
  const textChangeTimer = useRef()
  const registerChipDeleteHandler = useCallback(() => {
    search.dispatch({
      type: 'registerChipDeleteHandler',
      handler: (name) => {
        const newTextValues = { ...textValues }
        newTextValues[name] = ''
        setTextValues(newTextValues)
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textValues])

  useEffect(() => {
    // One-time registration of the func used by SearchBar to clear locally-controlled text fields when chips are deleted
    registerChipDeleteHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getFormField = (field) => {
    const commonProps = {
      id: `search-${field.name}`,
      label: field.label,
      value: filters[field.name] || '',
      ...field.widgetProps,
    }

    if (field.options) {
      return (
        <Select
          {...commonProps}
          options={field.options}
          value={findFilterValue(field.options, field.name, filters)}
          onChange={(selected) => {
            search.dispatch({ type: 'addFilters', filters: { [field.name]: selected.value } })
          }}
        />
      )
    }

    if (!commonProps.value) {
      delete commonProps.value
    }

    switch (field.type) {
      case 'Date':
        const { label, value, ...rest } = commonProps
        return (
          <Label label={label} required={commonProps.required}>
            <DatePicker
              {...rest}
              customInput={<TextInput />}
              selected={value || new Date()}
              onChange={(date) => {
                search.dispatch({ type: 'addFilters', filters: { [field.name]: date } })
              }}
            />
          </Label>
        )
      default:
    }

    // console.log('rendering TextField', commonProps.value)
    return (
      <TextInput
        {...commonProps}
        autoComplete='off'
        onChange={getTextChangeHandler(
          field.name,
          textValues[field.name],
          textChangeTimer,
          search.dispatch
        )}
        // Special case: text fields use local state so they show new values instantly rather than waiting
        // for the form to be re-rendered after a change to SearchContext (which takes too long right now)
        value={textValues[field.name]}
      />
    )
  }

  const content = (
    <div className='SearchForm'>
      {fields.map((field, index) => (
        <React.Fragment key={`field-${index}`}>{getFormField(field)}</React.Fragment>
      ))}
    </div>
  )
  return content
}
