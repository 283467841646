import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import { SubWizardContext } from '../SubWizard.js'

export default function FolderSuffix() {
  const { state } = React.useContext(SubWizardContext)
  const { fields } = state

  return (
    <Field>
      <Label label='Route Name'>
        <HelpText>This value is derived from your answers above.</HelpText>
        <TextInput disabled value={fields.route_name} />
      </Label>
    </Field>
  )
}
