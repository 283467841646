import React from 'react'
export { default as validators } from './validators.js'

function standardReducer(state, action) {
  switch (action.type) {
    case 'set':
      state.changed[action.name] = true
      state.fields[action.name] = action.value
      state.fields = mutateValues(state)
      state.errors = validateForm(state)
      return { ...state }
    case 'reset':
      return { ...state, changed: {}, errors: {}, fields: { ...state.initialValues } }
    default:
      return { ...state }
  }
}

export function useForm(initialState) {
  const [state, dispatch] = React.useReducer(standardReducer, {
    ...getStandardFormState(initialState),
  })

  const handlers = React.useMemo(
    () => ({
      setField: (name, value) => dispatch({ type: 'set', name, value }),
      resetForm: () => dispatch({ type: 'reset' }),
    }),
    [dispatch]
  )

  return {
    state,
    ...handlers,
  }
}

export function getStandardFormState(initialState) {
  const state = {
    changed: {},
    errors: {},
    fields: {},
    mutators: {},
    validators: {},
    ...initialState,
  }
  state.initialValues = { ...state.fields }
  return state
}

/**
 * Apply mutator functions on a form's values, and return the new values.
 * These mutator functions can be defined per field in state.mutators object.
 *
 * @param {object} state
 */
export function mutateValues(state) {
  const { fields, mutators } = state
  Object.entries(mutators || {}).forEach(([fieldName, mutator]) => {
    const oldValue = fields[fieldName]
    fields[fieldName] = mutator ? mutator(oldValue, state) : oldValue
  })
  return fields
}

/**
 * Validate form state and return an errors object.
 *
 * State should include a `validators` object whose keys are field names,
 * and where each value is an array of validators for that field.
 *
 * @param {object} state
 * @returns {object}
 */
export function validateForm(state) {
  const errors = {}

  Object.entries(state.validators).forEach(([fieldName, validators]) => {
    const results = validateField(fieldName, validators, state)
    const messages = getReasonsWhyInvalid(results)

    if (messages.length) {
      if (Array.isArray(messages[0])) {
        // This field represents a form set, so return the array of form errors returned
        // by validateFormSet validator
        errors[fieldName] = messages[0] || ''
      } else {
        // This field has one or more error messages
        errors[fieldName] = messages
      }
    }
  })

  return errors
}

export function validateField(fieldName, validators, state) {
  const { changed, checkAllFields, fields } = state
  const value = fields[fieldName]
  const isValid = (validator) => validator(value, state, { fieldName })

  if (Array.isArray(value)) {
    // field is a form set, so let formSetValidator validate each form's fields
    return validators.map(isValid)
  } else {
    // field is a normal standalone field
    if (!checkAllFields && !changed[fieldName]) {
      return [] // only validate fields that have been changed (aka dirty)
    }
    return validators.map(isValid)
  }
}

function getReasonsWhyInvalid(results) {
  return results.filter(([isValid, reason]) => !isValid).map(([isValid, reason]) => reason)
}
