import React from 'react'
import { Label, RadioGroup } from '@nike/epic-react-ui'
import { Field } from '__components'
import { SubWizardContext } from '../SubWizard.js'

const NAME = 'post_client_download_action'

export default function DeleteAfterDownload({ stepName }) {
  const { registerField, setField, state } = React.useContext(SubWizardContext)
  const { fields } = state

  React.useEffect(() => registerField(stepName, NAME, []), [registerField, stepName])

  return (
    fields.direction === 'FROM_' && (
      <Field>
        <Label label='Delete After Download?'>
          <RadioGroup
            list={[
              {
                value: 'DELETE',
                label: 'Yes, delete files after they are downloaded by client',
              },
              { value: 'NO_ACTION', label: 'No' }, // property is not added to JSON if this is selected
            ]}
            onChange={(value) => setField(NAME, value)}
            value={fields[NAME]}
          />
        </Label>
      </Field>
    )
  )
}
