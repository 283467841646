import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'

export default function CerberusPathField({ disabled, onChange, value }) {
  return (
    <Field>
      <Label label='Cerberus Path' />
      <HelpText>
        Password will be fetched from this location using <code>endpoint:user_name</code> as the
        key. Learn more about{' '}
        <a href='https://confluence.nike.com/pages/viewpage.action?spaceKey=ERPPMFT&title=Access+Management#AccessManagement-Cerberus'>
          MFT recommended Cerberus setup
        </a>
        .
      </HelpText>
      <TextInput disabled={disabled} onChange={onChange} value={value} />
    </Field>
  )
}
