import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText, ValidationError } from '__components'
import { integer } from '__util/forms/validators.js'
import { SiteWizardContext } from '../SiteWizard.js'

export default function MaxConcurrentFiles({ name, stepName }) {
  const { registerField, setField, state } = React.useContext(SiteWizardContext)
  const { errors, fields } = state

  React.useEffect(
    () => registerField(stepName, name, [integer()], 0),
    [registerField, name, stepName]
  )

  return (
    <Field>
      <Label label='Max Concurrent File Transfers'>
        <HelpText>
          The maximum number of files that MFT can{' '}
          {state.fields.role === 'source' ? 'pull from' : 'push to'} this system concurrently.
          Defaults to 0 (unlimited)
        </HelpText>
        <TextInput onChange={(e) => setField(name, e.target.value)} value={fields[name] || ''} />
        <ValidationError message={errors[name]} />
      </Label>
    </Field>
  )
}
