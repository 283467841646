export const mayResubmit = (user, env) => {
  if (!env) {
    return false
  }
  const mftGroups = user.groups
    .filter((g) => g.startsWith('Application.MFT.'))
    .map((g) => g.toLowerCase())

  switch (env.toLowerCase()) {
    case 'prd' || 'prod':
      return mftGroups.some((g) => g.endsWith('.administrator'))
    case 'qa':
      return mftGroups.some(
        (g) =>
          g.endsWith('.administrator') ||
          g.endsWith('.prod.support') ||
          g.endsWith('.prod.splsupport') ||
          g.endsWith('.qa.manager') ||
          g.endsWith('.qa.support') ||
          g.endsWith('.qa.splsupport')
      )
    case 'dev':
      return mftGroups.some(
        (g) =>
          g.endsWith('.administrator') ||
          g.endsWith('.dev.developer') ||
          g.endsWith('.dev.support') ||
          g.endsWith('.dev.splsupport')
      )
    default:
      return false
  }
}
