import React, { useContext, useState } from 'react'
import { navigate } from 'raviger'
import { api } from '__util'
import { AlertsContext } from '__components'

/**
 * Common context useful for rendering item detail pages and forms.
 *
 * See util/api.js for functions that utilze this context.
 */
export const DetailPageContext = React.createContext({
  // These are here soley for good auto-completion
  item: {},
  setItem: null,
  errors: {},
  setErrors: null,
  isEditing: false,
  setIsEditing: null,
  waiting: '',
  setWaiting: null,
  loadItem: null,
  saveItem: null,
  modifyItem: null,
  deleteItem: null,
})

export const DetailPageState = (props) => {
  const alerts = useContext(AlertsContext)
  const [item, setItem] = useState({ id: 'add' })
  const [errors, setErrors] = useState({})
  const [isEditing, setIsEditing] = useState(false)
  const [waiting, setWaiting] = useState('')

  const loadItem = (collection, id) => {
    setWaiting('load')

    return api
      .get(collection, id)
      .then((item) => {
        setItem(item)
        setErrors({})
        setIsEditing(false)
      })
      .catch((response) => setErrors(response.errors))
      .finally(() => setWaiting(''))
  }

  /**
   * Re-using the list endpoint to get details of one item.
   *
   * @param {string} collection: e.g. 'users'
   * @param {object} query: e.g. {email: 'tom.hamlin@nike.com'}
   */
  const findItem = (collection, query) => {
    setWaiting('load')

    return api
      .list(collection, query)
      .then((items) => {
        if (items.length === 1) {
          setItem(items[0])
          setErrors({})
          setIsEditing(false)
        } else {
          throw new Error('Item not found.')
        }
      })
      .catch((response) => setErrors(response))
      .finally(() => setWaiting(''))
  }

  const saveItem = (collection, data, onSuccess) => {
    setWaiting('save')

    if (data.id === 'add') {
      return api
        .create(collection, data)
        .then(({ item }) => {
          if (onSuccess) {
            onSuccess(item)
          }
          navigate(`/${collection}/${item.id}`)
        })
        .catch((response) => setErrors(response.errors))
        .finally(() => setTimeout(() => setWaiting(''), 300)) // Ensure user sees the 'Saving...' even if call is quick
    } else {
      return api
        .save(collection, data.id, data)
        .then(({ item }) => {
          setItem(item)
          setErrors({})
          setIsEditing(false)
          if (onSuccess) {
            onSuccess(item)
          }
        })
        .catch((response) => setErrors(response.errors))
        .finally(() => setTimeout(() => setWaiting(''), 300)) // Ensure user sees the 'Saving...' even if call is quick
    }
  }

  // Examples:
  //   ('sites', 13, 'approve')
  //   ('sites', 18, 'deny', { reason_denied: 'something' })
  const modifyItem = (collection, id, verb, data) => {
    setWaiting(verb)

    return api
      .modify(collection, id, verb, data)
      .then(({ item }) => {
        setItem(item)
        setErrors({})
      })
      .catch((response) => {
        console.error(response)
        alerts.add({
          message: 'The request did not succeed. Please check the form and try again.',
          level: 'error',
        })
      })
      .finally(() => setTimeout(() => setWaiting(''), 300))
  }
  const deleteItem = (collection, id, successMsg) => {
    return api
      .delete(collection, id)
      .then(({ item }) => {
        setErrors({})
        alerts.add({
          message: successMsg || `Item ${id} has been deleted`,
          level: 'success',
        })
        navigate(`/${collection}`)
      })
      .catch((response) => setErrors(response.errors))
  }

  return (
    <DetailPageContext.Provider
      value={{
        item,
        setItem,
        errors,
        isEditing,
        setIsEditing,
        waiting,
        setWaiting,
        loadItem,
        saveItem,
        modifyItem,
        deleteItem,
        findItem,
      }}
    >
      {props.children}
    </DetailPageContext.Provider>
  )
}
