import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText, ValidationError } from '__components'
import { onlyAlphaNumAndUnderscores, required } from '__util/forms/validators.js'
import { SubWizardContext } from '../SubWizard.js'

const NAME = 'folderSuffix'

export default function FolderSuffix({ stepName }) {
  const { registerField, setField, state } = React.useContext(SubWizardContext)
  const { errors, fields } = state

  React.useEffect(
    () =>
      registerField(stepName, NAME, [
        required((v, state) => state.fields.nikeInitiated === false),
        onlyAlphaNumAndUnderscores(true),
      ]),
    [registerField, stepName]
  )

  return (
    <Field>
      <Label label='Folder Suffix'>
        <HelpText>
          Type the rest of the folder name here. RULES: If only one partner-initiated subscription
          will exist for this direction, use 'NIKE' here.
        </HelpText>
        <TextInput
          onChange={(e) => setField(NAME, e.target.value.toUpperCase())}
          value={fields[NAME]}
        />
        <ValidationError message={errors[NAME]} />
      </Label>
    </Field>
  )
}
