import React from 'react'
import { HelpText } from '__components'

export default function CyberArkHelp({ env = '' }) {
  return (
    <HelpText>
      Ensure that your password is stored in this CyberArk safe:{' '}
      <code>{`app_int_pltfrm_mft_${env}`}</code>
    </HelpText>
  )
}
