import React from 'react'
import { Wizard } from '__components'
import ItemDetails from '../common/ItemDetails.js'
import { subWizardStateToJson } from './serialize.js'
import NameStep from './NameStep'
import RoutingStep from './RoutingStep'
import ConfirmStep from './ConfirmStep'

const determineNikeInitiated = (fields) =>
  !(
    fields.subscription_folder.startsWith('/FROM_') || fields.subscription_folder.startsWith('/TO_')
  )
const determineDirection = (fields) =>
  fields.nikeInitiated ? 'FROM_' : fields.subscription_folder.startsWith('/FROM_') ? 'FROM_' : 'TO_'
const determineFolderSuffix = (fields) =>
  fields.nikeInitiated
    ? ''
    : fields.subscription_folder.slice(1).replace(fields.direction, '') || 'NIKE'
const getInitialSimpleRoutes = (fields, sub) => {
  if (!fields.nikeInitiated) return []

  let routes = []

  const simple_routes = sub.custom_route?.simple_routes || []
  simple_routes.forEach((json) => {
    // json to SubWizard state
    const route = {
      simple_route_name: json.simple_route_name || '',
      business_unit: json.business_unit || '',
      filter_rule: json.filter_rule || '',
      failure_email: json.failure_email || '',
      success_email: json.success_email || '',
    }

    if (json.rename && Object.keys(json.rename)) {
      const { target_filename, filter_filename, action_on_step_failure } = json.rename
      route.rename = true
      route.target_filename = target_filename
      route.filter_filename = filter_filename
      route.action_on_step_failure = action_on_step_failure
    }

    if (json.send_to_partner && Object.keys(json.send_to_partner)) {
      const {
        target_account,
        target_site,
        target_dir,
        failure_retry_count,
        failure_retry_interval,
        max_parallel_connections,
        action_on_step_failure,
      } = json.send_to_partner
      route.initiated = 'push'
      route.push_target_account = target_account
      route.push_target_site = target_site
      route.push_target_dir = target_dir
      route.push_failure_retry_count = failure_retry_count
      route.push_failure_retry_interval = failure_retry_interval
      route.push_max_parallel_connections = max_parallel_connections
      route.action_on_step_failure = action_on_step_failure
    }

    if (json.send_to_account && Object.keys(json.send_to_account)) {
      const { target_account, target_dir, target_file_collision, action_on_step_failure } =
        json.send_to_account
      route.initiated = 'pull'
      route.pull_target_account = target_account
      route.pull_target_dir = target_dir
      route.pull_target_file_collision = target_file_collision
      route.action_on_step_failure = action_on_step_failure
    }

    routes.push(route)
  })

  return routes
}

const fieldsThatTriggerFolderChange = [
  'nikeInitiated',
  'direction',
  'folderSuffix',
  'source_site_name',
]
const generateFolder = (fields, existingRouteNames) => {
  const suffix = fields.folderSuffix || ''
  let folder = '/'
  if (fields.nikeInitiated) {
    folder += fields.source_site_name + suffix
  } else {
    folder += fields.direction + suffix
  }
  return folder
}

const SubPreview = (wizardState) => <ItemDetails fields={subWizardStateToJson(wizardState)} />

export default function SubWizard({ commitChanges, debug = false, env, sub, envConfig }) {
  const sites = envConfig.sites || []
  const subs = envConfig.subscriptions || []
  const existingRouteNames = subs.map((s) => s.route_name)
  const existingSiteNames = sites.map((site) => site.site_name)
  const isNew = Object.keys(sub).length === 0
  const initialFields = {
    subscription_folder: sub.subscription_folder || '',
    source_site_name: sub.source_site_name || '',
    condition_type: sub.condition_type || 'MATCH_FIRST',
    post_client_download_action: sub.post_client_download_action || 'DELETE',
    // Special case: for simplicity, this array is not in the `custom_route` object as in the JSON
    simple_routes: [],
  }

  initialFields.nikeInitiated = determineNikeInitiated(initialFields)
  initialFields.direction = determineDirection(initialFields)
  initialFields.folderSuffix = determineFolderSuffix(initialFields)
  initialFields.route_name = sub.route_name || initialFields.subscription_folder.slice(1)
  initialFields.simple_routes = getInitialSimpleRoutes(initialFields, sub)

  const onWizardChange = React.useCallback(
    (fields, action, changed) => {
      // Regenerate sub name if needed, and save in wizard state
      if (action.type === 'setField') {
        if (fieldsThatTriggerFolderChange.includes(action.name)) {
          fields.subscription_folder = generateFolder(fields, existingRouteNames)

          // Update route name if user has not modified it and it is not blank
          if (!changed.route_name || !fields.route_name) {
            fields.route_name = fields.subscription_folder.slice(1)
          }
        }

        if (action.name === 'nikeInitiated' && fields.nikeInitiated === false) {
          fields.simple_routes = []
        }
      }
      return fields
    },
    [existingRouteNames]
  )

  const onWizardSave = React.useCallback(
    (fields) => {
      const path = `subscriptions/${fields.route_name}.json`
      const subJson = subWizardStateToJson(fields)

      commitChanges(path, subJson)
    },
    [commitChanges]
  )

  return (
    <Wizard
      debug={debug}
      initialState={{
        fields: initialFields,
        isNew,
        env,
        existingSiteNames,
        serializer: SubPreview,
      }}
      onChange={onWizardChange}
      onSave={onWizardSave}
      Context={SubWizardContext}
    >
      <NameStep name='Name' />
      <RoutingStep name='Routing' />
      <ConfirmStep name='Confirm' />
    </Wizard>
  )
}

export const SubWizardContext = React.createContext({})
