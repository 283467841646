import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import DetailsContext from './DetailsContext.js'

export default function OverviewDetails({ EditControls }) {
  const details = React.useContext(DetailsContext)
  const { envConfig } = details
  const settings = envConfig?.account || {}

  return (
    <>
      <EditControls />
      <Field>
        <Label label='Internal or External?'>
          <HelpText>Is this account internal to Nike or an external partner?</HelpText>
          <TextInput
            disabled
            value={settings.transfer_type === 'I' ? 'Nike Internal' : 'External Partner'}
          />
        </Label>
      </Field>
      <Field>
        <Label label='Contact email'>
          <HelpText>
            Partner or account owner email address. A distribution list or group address is
            preferred. Only one email address supported.
          </HelpText>
          <TextInput disabled value={settings.email_contact || ''} />
        </Label>
      </Field>
      <Field>
        <Label label='Contact phone'>
          <HelpText>Optional partner or account owner contact phone number.</HelpText>
          <TextInput disabled value={settings.contact_phone || ''} />
        </Label>
      </Field>
      <Field>
        <Label label='Archive all files initiated or routed by sites in this account?'>
          <TextInput
            disabled
            value={
              settings.file_archiving_policy === 'enabled'
                ? 'Yes, archive all files (default)'
                : 'No, do not archive files'
            }
          />
        </Label>
      </Field>
    </>
  )
}
