import React from 'react'
import { Label, RadioGroup } from '@nike/epic-react-ui'
import { HelpText } from '__components'

const options = [
  { value: 'FAIL', label: 'Fail the transfer' },
  { value: 'PROCEED', label: 'Proceed to next step' },
]

export default function RenameActionOnStepFailure({ error, formValues, onChange, value }) {
  const target = formValues.initiated === 'pull' ? 'account' : 'target'
  return (
    formValues.rename === true && (
      <Label key='RAOSF' label='Action On Rename Failure' required>
        <HelpText>Would you like to fail the transfer to this {target} if renaming fails?</HelpText>
        <RadioGroup hasErrors={error} list={options} onChange={onChange} value={value} />
      </Label>
    )
  )
}
