import React from 'react'
import { Button } from '@nike/epic-react-ui'
import { ButtonBar } from '__components'
import { cannotEditDeployed } from '__accounts/VersionSwitcher'

export default function useEditState(canEdit) {
  const [isEditing, setIsEditing] = React.useState(false)

  const handlers = React.useMemo(
    () => ({
      setEditing: () => setIsEditing(true),
      cancelEditing: () => setIsEditing(false),
    }),
    []
  )

  const EditControls = React.useCallback(
    ({ onSave }) => {
      const buttons = isEditing ? (
        <>
          <Button onClick={handlers.cancelEditing} small>
            Cancel
          </Button>
          <Button onClick={onSave} small>
            Save
          </Button>
        </>
      ) : (
        <Button
          onClick={canEdit ? handlers.setEditing : null}
          small
          disabled={!canEdit}
          disabledTip={{ message: cannotEditDeployed }}
        >
          Edit
        </Button>
      )

      return <ButtonBar topRight>{buttons}</ButtonBar>
    },
    [canEdit, handlers.cancelEditing, handlers.setEditing, isEditing]
  )

  return {
    isEditing,
    EditControls, //: ({ onSave }) => <EditControls onSave={onSave} />,
    ...handlers,
  }
}
