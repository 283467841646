import { useRoutes } from 'raviger'
import { LoginRequired, useAuth, useAuthStore, selectUsername } from '@nike/aegis-auth-react'
import client from '__src/auth/client.js'
import '@nike/epic-react-ui/dist/styles/main.css'
import '../stylus/app.styl'

import { Button, Spinner } from '@nike/epic-react-ui'
import { Alerts, NotFound, OfflineBanner } from '__components'
import { ViewerState } from '../users/ViewerContext'
import { DetailPageState } from './DetailPageState'
import config from '__config'
import routes from '__src/routes.js'
import IntegratedPlatform from './IntegratedWrapper'
import Navigation from './Navigation'
import { GitHubState } from '../github/GitHubAuth'
import InterceptGitHubAuthCode from '../github/InterceptGitHubAuthCode.js'
import { NarkProvider } from '__util/nark'

const basePath = config.basePath

/**
 * This component must run *before* okta-auth-js is invoked in AppWithOktaAuth,
 * because the `code` param returned by GitHub needs to be removed first. Otherwise,
 * okta-auth-js will start a new login flow (and fail because its token store is
 * cleared due to the github auth redirect).
 */
function App() {
  return <InterceptGitHubAuthCode fallback={() => <AppWithOktaAuth />} />
}

function AppWithOktaAuth() {
  const { loginComplete } = useAuth(client)
  const email = useAuthStore(selectUsername)
  const route = useRoutes(routes, { basePath }) || <NotFound />

  if (!loginComplete) return <Spinner large className='centered' />

  return (
    <ViewerState email={email}>
      <Alerts>
        <DetailPageState>
          <OfflineBanner />
          <IntegratedPlatform>
            <Navigation />
            <div className='app-container' style={{ position: 'relative' }}>
              <LoginRequired client={client} Button={Button}>
                <NarkProvider>
                  <GitHubState>{route}</GitHubState>
                </NarkProvider>
              </LoginRequired>
            </div>
          </IntegratedPlatform>
        </DetailPageState>
      </Alerts>
    </ViewerState>
  )
}

export { App }
export default App
