import React from 'react'

/**
 * This component is a no-op placeholder to receive the /github-authorized
 * route. Before it's even rendered, InterceptGitHubAuthCode will have
 * parsed github's auth code and stored it in sessionStorage, and then
 * redirected the user back to the page they were viewing before the
 * GitHub auth redirect.
 */
export default function GitHubAuthorized(props) {
  // Return JSX to avoid dumb console warning about unused React variable
  return <div />
}
