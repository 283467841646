import React from 'react'

import { Container } from '@nike/epic-react-ui'

export default function NotFound() {
  return (
    <Container>
      <h1>
        <span role='img' aria-label='grimmace emoji'>
          🙁
        </span>{' '}
        The resource you requested was not found
      </h1>
    </Container>
  )
}
