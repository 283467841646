import React from 'react'
import { Button } from '@nike/epic-react-ui'
import DetailsContext from '../DetailsContext.js'
import ItemDetails from './ItemDetails.js'

export default function DetailsLink({ title, getFields }) {
  const { modalDispatch } = React.useContext(DetailsContext)

  return (
    <span
      className='clickable'
      onClick={() =>
        modalDispatch({
          type: 'open',
          title: title || '',
          content: <ItemDetails fields={getFields()} />,
          actions: <Button onClick={() => modalDispatch({ type: 'close' })}>Close</Button>,
        })
      }
    >
      {title}
    </span>
  )
}
