import React from 'react'
import { Modal } from '@nike/epic-react-ui'
import { ButtonBar } from '__components'

export default function DetailsModal({ show, title, content, actions, onClose, size = 'md' }) {
  return (
    <Modal modalSize={size} show={show} onClose={onClose}>
      <Modal.Title>{title}</Modal.Title>
      <Modal.Content>{content}</Modal.Content>
      <ButtonBar centered>{actions}</ButtonBar>
    </Modal>
  )
}
