import http from '__util/http'
import config from '__config'

export const listAccounts = async (gitHubToken) => {
  const url = `${config.apiUrl}/accounts`
  const accounts = await http.get(url, undefined, { 'x-github-token': gitHubToken })
  // API should filter out repositories that are not actual accounts (e.g. TEMPLATE_REPOSITORY).
  // End users will only see accounts they have permission for.
  return accounts
}

export const getAccount = async (gitHubToken, name, branch = 'main') => {
  const account = await http.get(
    `${config.apiUrl}/accounts/${name}`,
    { branch },
    { 'x-github-token': gitHubToken }
  )
  return account
}

export const createAccount = async (gitHubToken, name) => {
  const results = await http.post(
    `${config.apiUrl}/accounts`,
    { name: name },
    { 'x-github-token': gitHubToken }
  )
  return results
}

export const createVersion = async (gitHubToken, accountName, versionName) => {
  const response = await http.post(
    `${config.apiUrl}/accounts/${accountName}/branches`,
    {
      branchName: versionName,
    },
    { 'x-github-token': gitHubToken }
  )

  console.log('createVersion response', response)
  return response
}

export const createCommit = async (
  gitHubToken,
  accountName,
  versionName,
  env,
  filePath,
  fileContent,
  message
) => {
  const response = await http.post(
    `${config.apiUrl}/accounts/${accountName}/commit`,
    {
      targetBranch: versionName,
      env,
      filePath,
      fileContent,
      message,
    },
    { 'x-github-token': gitHubToken }
  )

  return response
}
