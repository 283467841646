import React from 'react'
import { Table } from '@nike/epic-react-ui'
import { ExtraTableControls } from '__components'

const columns = [
  {
    accessor: 'name',
    Header: 'Name',
  },
  {
    accessor: 'value',
    Header: 'Value',
  },
]

export default function CustomVars({ data, EditControls }) {
  return (
    <>
      <ExtraTableControls>
        <EditControls />
      </ExtraTableControls>
      <Table
        columns={columns}
        data={data}
        withSearchBar
        searchBarPlaceholder='Search variables...'
      />
    </>
  )
}
