import { format } from 'date-fns'

export const formats = {
  // RFC2822 string - e.g. 'Thu, 4 Oct 2019 10:05:59 -0700'
  // See https://date-fns.org/v2.4.1/docs/format
  rfc2822: 'EEE, d MMM yyyy HH:mm:ss XX',
  rfc2822Date: 'EEE, d MMM yyyy',
  rfc2822NoZone: 'EEE, d MMM yyyy HH:mm:ss',
}

/**
 * Return a string in RFC2822 format
 * @param {Date object} date or null
 * @param {string} time value like '13:05:59' or null
 */
export const combineDateAndTime = (dateObj, timeStr) => {
  if (!dateObj || !timeStr) {
    return undefined
  }
  timeStr = timeStr ? timeStr : '00:00:00'
  const [hours, minutes, seconds] = timeStr.split(':')
  dateObj.setHours(hours)
  dateObj.setMinutes(minutes)
  dateObj.setSeconds(seconds)
  const dateTimeStr = format(dateObj, formats.rfc2822)
  return dateTimeStr
}

const mod = {
  format,
  formats,
  combineDateAndTime,
}

export default mod
