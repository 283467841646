import React from 'react'
import { SiteWizardContext } from '../SiteWizard.js'
import { Label, RadioGroup } from '@nike/epic-react-ui'
import { Field } from '__components'

export default function IsActive({ name, stepName }) {
  const { registerField, setField, state } = React.useContext(SiteWizardContext)
  const { fields } = state

  React.useEffect(() => registerField(stepName, name, [], false), [registerField, name, stepName])

  return (
    <Field>
      <Label label='Connection Mode'>
        <RadioGroup
          value={fields[name] === true || fields[name] === 'true'}
          onChange={(value) => setField(name, value)}
          list={[
            { value: true, label: 'Active' },
            { value: false, label: 'Passive' },
          ]}
        />
      </Label>
    </Field>
  )
}
