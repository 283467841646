import React from 'react'
import { Button, Label } from '@nike/epic-react-ui'

export default function EnvSwitcher({ env, setEnv, enabled = true }) {
  return (
    <div style={{ height: '50px' }}>
      <Label label='Environment'>
        <Button onClick={() => setEnv('dev')} inverse={env !== 'dev'} disabled={!enabled} small>
          DEV
        </Button>{' '}
        <Button onClick={() => setEnv('qa')} inverse={env !== 'qa'} disabled={!enabled} small>
          QA
        </Button>{' '}
        <Button onClick={() => setEnv('prd')} inverse={env !== 'prd'} disabled={!enabled} small>
          PRD
        </Button>
      </Label>
    </div>
  )
}
