import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'

export default function UserNameField({ value }) {
  return (
    <Field>
      <Label label='SFTP/SCP user name' />
      <HelpText>
        This will be used by you to log in to the MFT system for file upload/download. Value is
        automatically determined.
      </HelpText>
      <TextInput disabled placeholder='mftuser_' value={value} />
    </Field>
  )
}
