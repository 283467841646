import React from 'react'
import { Label, RadioGroup, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText, ValidationError } from '__components'
import DetailsContext from './DetailsContext.js'
import { useForm, validators } from '__util/forms/index.js'

export default function OverviewForm({ EditControls, settings }) {
  const details = React.useContext(DetailsContext)
  const { commitChanges } = details
  const { state, setField } = useForm({
    fields: { ...settings },
    validators: {
      email_contact: [validators.email(), validators.required()],
    },
  })
  const { errors, fields } = state

  const onSave = React.useCallback(() => {
    commitChanges('account.json', fields)
  }, [commitChanges, fields])

  return (
    <>
      <EditControls onSave={onSave} />
      <Field>
        <Label label='Account name'>
          <TextInput disabled value={fields.account_name} />
        </Label>
      </Field>
      <Field>
        <Label label='Internal or External?'>
          <HelpText>Is this account internal to Nike or an external partner?</HelpText>
          <TextInput
            disabled
            value={settings.transfer_type === 'I' ? 'Nike Internal' : 'External Partner'}
          />
        </Label>
      </Field>
      <Field>
        <Label label='Contact email' required>
          <HelpText>
            Partner or account owner email address. A distribution list or group address is
            preferred. Only one email address supported.
          </HelpText>
          <TextInput
            onChange={({ target }) => setField('email_contact', target.value)}
            placeholder='some-list@example.com'
            value={fields.email_contact || ''}
          />
          <ValidationError message={errors.email_contact} />
        </Label>
      </Field>
      <Field>
        <Label label='Contact phone'>
          <HelpText>Optional partner or account owner contact phone number.</HelpText>
          <TextInput
            onChange={({ target }) => setField('contact_phone', target.value)}
            placeholder='(800) 555-1212'
            value={fields.contact_phone || ''}
          />
        </Label>
      </Field>
      <Field>
        <Label label='Archive all files initiated or routed by sites in this account?'>
          <HelpText>
            If archiving is enabled, it is possible to resubmit failed transfers. Archived files
            remain on MFT systems for 7 days. One reason to disable archiving would be to protect
            highly sensitive information.
          </HelpText>
          <RadioGroup
            list={[
              { value: 'enabled', label: 'Yes, archive all files (default)' },
              { value: 'disabled', label: 'No, do not archive files' },
            ]}
            onChange={(value) => setField('file_archiving_policy', value)}
            value={fields.file_archiving_policy}
          />
        </Label>
      </Field>
    </>
  )
}
