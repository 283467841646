import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Container, NextGen, Section, Spinner } from '@nike/epic-react-ui'
import { GitHubContext } from '__src/github/GitHubAuth.js'
import { PageTitle } from '__components'
import AccountsTable from './AccountsTable.js'
import { createAccount, listAccounts } from './api.js'
import AccountsSearchForm from './AccountsSearchForm.js'
import AccountCreateForm from './AccountCreateForm.js'
import AccountCreateResults from './AccountCreateResults.js'
import {
  accountsFetchDone,
  accountsFetchStart,
  accountsFilter,
  accountsCreateStart,
  accountsCreateDone,
  selectAllAccounts,
  selectFilteredAccounts,
  selectAccountsLoading,
  selectCreating,
  selectCreateResults,
} from '__state/slices/accounts.js'

export default function AccountsPage(props) {
  const github = React.useContext(GitHubContext)
  const token = github.getToken()
  const renewTokenIfExpired = github.renewTokenIfExpired

  const [showCreateForm, setShowCreateForm] = React.useState(false)
  const dispatch = useDispatch()
  const loading = useSelector(selectAccountsLoading)
  const allAccounts = useSelector(selectAllAccounts)
  const filteredAccounts = useSelector(selectFilteredAccounts)
  const isCreating = useSelector(selectCreating)
  const createResults = useSelector(selectCreateResults)

  React.useEffect(() => {
    const getAllAccounts = () => {
      dispatch(accountsFetchStart())
      listAccounts(token)
        .then((response) => dispatch(accountsFetchDone({ accounts: response })))
        .catch((error) => renewTokenIfExpired(error))
    }

    if (!allAccounts.length) {
      getAllAccounts()
    }
  }, [allAccounts.length, dispatch, renewTokenIfExpired, token])

  React.useEffect(() => {
    if (!showCreateForm && Object.keys(createResults).length) {
      // Clear the results for next time the dialog is opened
      dispatch(accountsCreateDone({}))
    }
  }, [createResults, dispatch, showCreateForm])

  React.useEffect(() => {
    // Clear any existing filter when first showing this page
    if (filteredAccounts.length !== allAccounts.length) {
      dispatch(accountsFilter({ name: '' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFilter = React.useCallback((name) => dispatch(accountsFilter({ name })), [dispatch])

  const callCreateAccount = React.useCallback(
    (name) => {
      dispatch(accountsCreateStart())
      createAccount(token, name)
        .then((response) => {
          dispatch(accountsCreateDone({ response }))
        })
        .catch((error) => {
          console.error('createAccount failed:', error)
          const apiMessage = error.data?.message || error
          const message = apiMessage.includes('already exists')
            ? `A repository named ${name} already exists.`
            : apiMessage
          const response = { steps: { 'Create Repo': { error: message } } }
          dispatch(accountsCreateDone({ response }))
        })
    },
    [dispatch, token]
  )

  return (
    <Container>
      <PageTitle title='List of Accounts' />
      <Section style={{ marginTop: '1em' }}>
        {loading ? (
          <Spinner large className='centered' />
        ) : (
          <>
            <div className='AccountsControls'>
              <AccountsSearchForm onFilter={onFilter} />
              <Button onClick={() => setShowCreateForm(true)} small>
                Create Account
              </Button>
            </div>
            <AccountsTable data={filteredAccounts} />
          </>
        )}
      </Section>
      <NextGen.Modal show={showCreateForm} onClose={() => setShowCreateForm(false)} modalSize='md'>
        {Object.keys(createResults).length ? (
          <AccountCreateResults results={createResults} />
        ) : isCreating ? (
          <Spinner />
        ) : (
          <AccountCreateForm
            existingAccountNames={allAccounts.map((a) => a.name)}
            isVisible={showCreateForm}
            onClose={() => setShowCreateForm(false)}
            onSubmit={callCreateAccount}
          />
        )}
      </NextGen.Modal>
    </Container>
  )
}
