import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { HelpText, ValidationError } from '__components'

export default function PushFailureRetryInterval({ error, formValues, onChange, value }) {
  return (
    formValues.initiated === 'push' && (
      <Label key='PushTargetDir' label='Failure Retry Interval' required>
        <HelpText>How many seconds should this transfer wait before retrying if it fails?</HelpText>
        <TextInput hasErrors={error} onChange={onChange} value={value} autoComplete='off' />
        <ValidationError message={error} />
      </Label>
    )
  )
}
