import React from 'react'
import { Section } from '@nike/epic-react-ui'

export default function Step(props) {
  // react-step-wizard automatically passes some props like `currentStep`, etc.
  const { component, name, ...stepWizardProps } = props
  return (
    <div className='WizardStep'>
      <Section title={name}>{component({ name, ...stepWizardProps })}</Section>
    </div>
  )
}
