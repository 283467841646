import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { CopyableText, Field, HelpText } from '__components'
import { required } from '__util/forms/validators.js'
import { SiteWizardContext } from '../SiteWizard.js'

export default function CerberusPath({ name, stepName }) {
  const { registerField, setField, state } = React.useContext(SiteWizardContext)
  const value = state.fields[name]

  React.useEffect(
    () =>
      registerField(stepName, name, [required((v, state) => state.fields.authType === 'cerberus')]),
    [registerField, name, stepName]
  )

  return (
    state.fields.authType === 'cerberus' && (
      <Field>
        <Label label='Cerberus SDB Path' required>
          <TextInput onChange={(e) => setField(name, e.target.value)} value={value || ''} />
          <HelpText>
            <p>
              Please provide the SDB Path for your Ceberus vault. An in-depth walk through can be
              found here: &nbsp;
              <a href='https://confluence.nike.com/display/ERPPMFT/Access+Management#AccessManagement-Cerberus'>
                MFT recommended Cerberus setup
              </a>
              .
            </p>
            <p>
              You must add the following IAM role so MFT can access your Cerberus Path:
              <code>
                <CopyableText text='arn:aws:iam::284722348816:role/mft-prod-role-lambda' />
              </code>
            </p>
          </HelpText>
        </Label>
      </Field>
    )
  )
}
