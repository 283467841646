import React from 'react'
import { Label, Select } from '@nike/epic-react-ui'
import { findValue, listToSelectOptions } from '__util/select.js'

const units = [
  'COLEHAAN',
  'CONVERSE',
  'CDEA',
  'DATA_ANALYTICS',
  'GEO_APLA_BR',
  'GEO_APLA_JAPAN',
  'GEO_APLA_PACIFIC',
  'GEO_APLA_SEA',
  'GEO_APLA_SOCO',
  'GEO_EMEA',
  'GREATERCHINA',
  'GTM',
  'GPPS',
  'SERVICENOW',
  'WDC',
  'LicenseProduct_NTS',
  'NA',
  'NA_CANADA',
  'NA_CARRIER_POD',
  'NA_IHM',
  'NA_SCIPR',
  'NDE_PI',
  'NDE',
  'NDE_DTC',
  'NDE_GSS',
  'NDE_LOSSPREVENTION',
  'NDE_NIKEID',
  'NDE_USCS',
  'PES',
  'PES_DPILS',
  'PME',
  'SABRIX',
  'SCEF_FI',
  'SCEF_FI_TREASURY',
  'SCEF_GFLS',
  'SCEF_GFLS_GTC',
  'SCEF_HR',
  'SCEF_HR_GPAY',
  'SCEF_OM',
  'SCEF_PLANNING',
  'SCEF_RETAIL',
  'SCEF_VCS',
  'SCEF_WMS_CANADA',
  'SCEF_WMS_MEMPHIS',
  'TIS',
  'EP_MFT',
  'NIKE_EDI',
  'CONVERSE_EDI',
  'CDB',
]

export default function BusinessUnit({ error, onChange, value }) {
  const options = listToSelectOptions(units)
  return (
    <Label key='BUS' label='Business Unit' required>
      <Select
        hasErrors={error}
        options={options}
        onChange={onChange}
        value={findValue(options, value)}
      />
    </Label>
  )
}
