import React, { useRef, useState } from 'react'
// import { Tooltip } from '__components'
import { Tooltip } from '@nike/epic-react-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const CopyableText = ({ text, truncateAt, noTooltip = false }) => {
  const displayText =
    truncateAt && text.length > truncateAt ? text.slice(0, truncateAt) + '...' : text
  const [copied, setCopied] = useState(false)
  const tooltipContent = noTooltip || !copied ? 'Copy to clipboard' : 'Copied'
  const timer = useRef()

  return (
    <span className={copied ? `CopyableText CopyableText--copied` : 'CopyableText'}>
      {displayText}
      <Tooltip message={tooltipContent} position='right'>
        <FontAwesomeIcon
          icon={copied ? 'check' : 'clipboard'}
          onClick={() => {
            navigator.clipboard.writeText(text)
            setCopied(true)
            timer.current = setTimeout(() => {
              setCopied(false)
            }, 1500)
          }}
          style={{
            backgroundColor: 'transparent',
          }}
        />
      </Tooltip>
    </span>
  )
}
