import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { HelpText, ValidationError } from '__components'

export default function SimpleRouteName({ error, onChange, value, ...props }) {
  return (
    <Label key='simple_route_name' label='Simple Route Name' required>
      <HelpText>
        Name normal routes with target name; dummy route <code>DUMMY</code>, and zeroByte route name{' '}
        <code>ZEROBYTE</code>. Example: <code>TO_EDI</code>
      </HelpText>
      <TextInput hasErrors={error} onChange={onChange} value={value} autoComplete='off' />
      <ValidationError message={error} />
    </Label>
  )
}
