import { capitalizeWords } from '__util/strings'
import { getPropsIfDefinedInState } from '../common/serializers.js'
import { commonCertNameOptions } from './fields/SSHCertName.js'

export const transferTypeOptions = {
  I: 'Internal to Nike',
  E: 'External to Nike',
}

export const nikeInitiatedOptions = [
  {
    value: true,
    label: 'Yes, a Nike system will initiate the transfer (Autosys/Airflow)',
  },
  { value: false, label: 'No, partner will log in to push or pull files' },
]

export const roleOptions = {
  source: 'Source - MFT system will pull files from this site',
  target: 'Target - MFT system will push files to this site',
}

export const systemNameOptions = {
  EDI: 'EDI',
  Fusion: 'Fusion',
  Infa: 'Informatica/Infa',
  other: 'Other',
}

export const transferModeOptions = {
  auto: 'Auto',
  binary: 'Binary',
  ascii: 'ASCII',
}

export const deleteAfterDownloadOptions = [
  { value: 'Yes', label: 'Yes - delete the file after download is completed' },
  { value: 'No', label: 'No - keep the file on the file system' },
]

export const s3BucketAccessOptions = [
  { value: 'bucket', label: 'Allow MFT access directly to S3 Bucket' },
  { value: 'role', label: 'Grant MFT access to a role with access to S3 Bucket' },
]

export const downloadSubfoldersOptions = [
  { value: 'true', label: 'Yes - download subfolders' },
  { value: 'false', label: 'No - do not download subfolders' },
]

export const uploadPermissionsOptions = {
  '0644': '0644 - User read & write, group read, other read',
}

export const protocolToWord = (p = '') => {
  p = p.toUpperCase()
  return p === 'SSH' ? 'SFTP' : p === 'AZURE-BLOB' ? 'Azure Blob' : p
}

export const siteWizardStateToSiteJson = (state) => {
  const { fields } = state
  const siteJson = {
    site_name: fields.site_name,
    transfer_type: fields.transfer_type,
    protocol: fields.protocol,
    contact: fields.contact,
    [fields.protocol]: getProtocolProps(fields),
  }
  return siteJson
}

export const siteWizardStateToCertsJson = (state, certs) => {
  if (state.use_password === true) {
    return
  }

  const name = state.ssh_cert_name

  if (Object.keys(commonCertNameOptions).includes(name)) {
    console.log(`No changes to certs.json: ${name} is common to all accounts.`)
    return
  }

  const certNames = certs.filter((c) => c.cert_usage !== 'login').map((c) => c.cert_name)
  if (certNames.includes(name)) {
    console.log(`No changes to certs.json: ${name} is already listed.`)
    return
  }

  const newCert = {
    cert_name: name,
    cert_usage: 'private',
    cert_passphrase: 'nikeservers', // yep, this is really a thing
  }

  certs.push(newCert)
  return certs
}

const getSharedProps = (state, props) => {
  if (state.max_concurrent_files && state.max_concurrent_files !== '0') {
    props.max_concurrent_files = state.max_concurrent_files
  }

  if (state.use_password === false) {
    props.ssh_cert_name = state.ssh_cert_name || ''
  }

  if (state.authType === 'cerberus') {
    props.cerberus_sdb_path = state.cerberus_sdb_path
  }

  return props
}

const getProtocolProps = (state) => {
  let props = {}
  switch (state.protocol) {
    case 'ssh':
      // Required properties
      props = {
        host: state.host,
        port: state.port,
        username: state.username,
        use_password: state.use_password,
      }

      props = Object.assign(
        props,
        getPropsIfDefinedInState(
          [
            'download_folder',
            'download_pattern',
            'delete_after_download',
            'upload_permission',
            // Not editable yet, but include these
            'allowed_macs',
            'cipher_suites',
            'key_exch_alg',
            'public_key_alg',
          ],
          state
        )
      )

      if (state.transfer_mode !== 'auto') {
        props.transfer_mode = state.transfer_mode
      }

      return getSharedProps(state, props)

    case 'ftps':
      props = {
        host: state.host,
        port: state.port,
        username: state.username,
        use_password: state.use_password,
        is_active: state.is_active,
      }

      props = Object.assign(
        props,
        getPropsIfDefinedInState(
          [
            'download_folder',
            'download_pattern',
            'delete_after_download',
            // Not editable yet, but include these
            'allowed_macs',
            'cipher_suites',
            'key_exch_alg',
            'public_key_alg',
          ],
          state
        )
      )

      return getSharedProps(state, props)

    case 'smb':
      props = {
        host: state.host,
        port: state.port,
        username: state.username,
      }

      props = Object.assign(
        props,
        getPropsIfDefinedInState(
          [
            'download_folder',
            'download_pattern',
            'delete_after_download',
            // Not editable yet, but include these
            'allowed_macs',
            'cipher_suites',
            'key_exch_alg',
            'public_key_alg',
          ],
          state
        )
      )

      return getSharedProps(state, props)

    case 'as2':
      props = {
        nike_as2_id: state.nike_as2_id,
        nike_as2_cert_name: state.nike_as2_cert_name,
        nike_email: state.nike_email,
        partner_as2_id: state.partner_as2_id,
        partner_as2_url: state.partner_as2_url,
        partner_email: state.partner_email,
        tls_versions: state.tls_versions,
      }

      props = Object.assign(
        props,
        getPropsIfDefinedInState(
          ['cipher_suites', 'content_type', 'mdn_async_type', 'receive_file_as', 'sign_alg'],
          state
        )
      )

      if (state.partnerAS2CertSource === 'upload') {
        props.partner_as2_cert_name = state.partnerAS2CertUpload
      } else {
        props.partner_as2_cert_name = state.partnerAS2CertSelection
      }

      return getSharedProps(state, props)

    case 's3':
      props = {}

      props = Object.assign(
        props,
        getPropsIfDefinedInState(
          [
            'download_folder',
            'download_pattern',
            'delete_after_download',
            's3_bucket',
            's3_region',
            'download_object_key',
            'system_metadata',
            'download_subfolders',
            'user_metadata',
          ],
          state
        )
      )

      if (state.s3_bucket_access === 'role') {
        props.role_arn = state.role_arn
      }

      return getSharedProps(state, props)

    case 'azure-blob':
    default:
      return props
  }
}

// Convert prop names like 'ssh_cert_name' to friendlier 'SSH Cert Name'
const normalizeSiteFieldName = (name) => {
  switch (name) {
    case 'cerberus_sdb_path':
      return 'Cerberus SDB Path'
    default:
      name = name.replaceAll('_', ' ')
      name = capitalizeWords(name)
      return name
  }
}

const normalizeSiteField = (name, value) => {
  let newName = normalizeSiteFieldName(name)
  let newValue = value

  switch (name) {
    case 'use_password':
      newValue = value === true ? 'Yes' : 'No'
      break
    default:
  }

  return [newName, newValue]
}

export const deserializeSite = (site = {}) => {
  const fields = {
    Name: site.site_name,
    // This is a synthetic field, not actually in the JSON
    Role: roleOptions[site.site_name.startsWith('OUTBOUND_') ? 'target' : 'source'] || 'Unknown',
    Type: transferTypeOptions[site.transfer_type] || 'Unknown',
    Contact: site.contact,
    Protocol: protocolToWord(site.protocol),
  }
  const protocolFields = site[site.protocol] || {}
  const ignoredFields = []

  Object.entries(protocolFields).forEach(([name, value]) => {
    if (ignoredFields.includes(name)) return

    const [newName, newValue] = normalizeSiteField(name, value)
    fields[newName] = newValue
  })

  return fields
}
