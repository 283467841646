import React from 'react'
import { ViewerContext } from './ViewerContext'
import { PageTitle } from '__components'
import { UserDetails } from './UserDetails'
import { UserHero } from './UserHero'
import { Spinner } from '@nike/epic-react-ui'
import ResetGitHubAuth from './ResetGitHubAuth'

export default function UserPage(props) {
  const viewer = React.useContext(ViewerContext)

  if (!viewer.email) {
    return <Spinner large className='centered' />
  }

  return (
    <>
      <PageTitle title={viewer.email} />
      <UserHero email={viewer.email} />
      <UserDetails user={viewer} />
      <ResetGitHubAuth user={viewer} />
    </>
  )
}
