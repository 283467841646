import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const StatusDisplay = ({ status }) => {
  const done = { color: 'epic-color-success', icon: 'check' }
  const fail = { color: 'epic-color-error', icon: 'exclamation-circle' }
  const running = { color: 'epic-color-default', icon: 'hourglass-half' }
  const statusDefs = {
    Failed: { ...fail },
    'Failed Subtransmission': { ...fail },
    'Failed Transfer Resubmit': { ...fail },
    Aborted: { ...fail },
    Processed: { ...done },
    'In Progress': { ...running },
    'Pending receipt': { ...running },
    Paused: { ...running },
  }
  const def = statusDefs[status] || { color: '', icon: 'question' }

  return (
    <span className={def.color}>
      <FontAwesomeIcon icon={def.icon} />
      &nbsp;{status}
    </span>
  )
}
