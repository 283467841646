import React from 'react'
import { SiteWizardContext } from '../SiteWizard.js'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'

export default function TLSVersions({ name, stepName }) {
  const { registerField, state } = React.useContext(SiteWizardContext)

  React.useEffect(() => registerField(stepName, name, [], 'TLSv1.2'), [
    registerField,
    name,
    stepName,
  ])

  return (
    <Field>
      <Label label='TLS Versions' required>
        <HelpText>Only one version is supported at this time.</HelpText>
        <TextInput disabled value={state.fields[name] || ''} />
      </Label>
    </Field>
  )
}
