import React from 'react'
import { Label, RadioGroup } from '@nike/epic-react-ui'

const options = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes, rename file' },
]

export default function Rename({ onChange, value }) {
  return (
    <Label
      key='Rename'
      label='Does the file need to be renamed before transfer to target?'
      required
    >
      <RadioGroup list={options} onChange={onChange} value={value === true} />
    </Label>
  )
}
