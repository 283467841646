import React from 'react'
import { SiteWizardContext } from '../SiteWizard.js'
import { Label, TextInput } from '@nike/epic-react-ui'
import { required } from '__util/forms/validators.js'
import { Field, HelpText, ValidationError } from '__components'

export default function DownloadPattern({ name, stepName }) {
  const { registerField, setField, state } = React.useContext(SiteWizardContext)
  const { errors, fields } = state

  React.useEffect(() => {
    registerField(stepName, name, [required((v, state) => state.fields.role === 'source')])
  }, [registerField, name, stepName])

  return (
    fields.role === 'source' && (
      <Field>
        <Label label='Download Pattern' required>
          <HelpText>
            <p>
              Regular Expression of files to download. This can be a file name or a Glob Expression
              of the file names. This field supports wildcard(*). If you need to download multiple
              file patterns, each has to be a separate transfer site.
            </p>
            <p>
              To match files ending in .xml or .txt: <code>.*\.(xml|txt)</code>
            </p>
            <div>
              To match multiple file patterns, separate them with a pipe (|):{' '}
              <code>USBCFF_Estimates_.*|USBCFF_Actuals_.*</code>
            </div>
          </HelpText>
          <TextInput
            hasErrors={errors[name]}
            onChange={(e) => setField(name, e.target.value)}
            value={fields[name] || ''}
          />
          <ValidationError message={errors[name]} />
        </Label>
      </Field>
    )
  )
}
