import React from 'react'
import { Label, RadioGroup } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import { SiteWizardContext } from '../SiteWizard.js'
import CyberArkHelp from '../../common/CyberArkHelp.js'

export const allAuthTypeOptions = [
  { label: 'Password - stored in CyberArk', value: 'cyberark' },
  { label: 'Password - stored in Cerberus', value: 'cerberus' },
  { label: 'Certificate', value: 'cert' },
]

export default function SiteAuthType({ name, stepName }) {
  const { registerField, setFields, state } = React.useContext(SiteWizardContext)
  const value = state.fields[name]

  const authTypeOptions = React.useMemo(
    () => (state.fields.protocol === 'smb' ? allAuthTypeOptions.slice(0, 2) : allAuthTypeOptions),
    [state.fields.protocol]
  )

  React.useEffect(() => registerField(stepName, name, []), [name, registerField, stepName])

  return (
    <Field>
      <Label label='Authentication' />
      <HelpText>How will you authenticate this connection?</HelpText>
      <RadioGroup
        list={authTypeOptions}
        onChange={(value) => setFields({ [name]: value, use_password: value !== 'cert' })}
        value={value || 'cyberark'}
      />
      {value === 'cyberark' && <CyberArkHelp env={state.env} />}
    </Field>
  )
}
