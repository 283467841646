import React from 'react'
import { Button, Tooltip } from '@nike/epic-react-ui'

// Some of these props are automatically passed in from react-step-wizard
export default function StepButtons(props) {
  const nextLabel = props.nextButtonLabel || 'Next'
  return (
    <div
      style={{
        display: 'flex',
        margin: '1em auto',
        width: '300px',
        justifyContent: 'space-between',
      }}
    >
      {props.currentStep === 1 ? null : (
        <Button onClick={() => props.previousStep()} inverse small>
          Back
        </Button>
      )}
      {props.nextButtonDisabledMessage ? (
        <Tooltip message={props.nextButtonDisabledMessage} position='bottom'>
          <Button disabled small>
            {nextLabel}
          </Button>
        </Tooltip>
      ) : props.currentStep === props.totalSteps ? null : (
        <Button onClick={() => props.nextStep()} small>
          {nextLabel}
        </Button>
      )}
    </div>
  )
}
