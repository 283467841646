import React from 'react'
import { Label, RadioGroup } from '@nike/epic-react-ui'

export default function Initiated({ onChange, value }) {
  return (
    <Label key='IB' label='How will this file be delivered to the target?' required>
      <RadioGroup
        list={[
          { value: 'push', label: 'MFT initiated push to target' },
          { value: 'pull', label: 'Target initiated pull from MFT' },
        ]}
        onChange={onChange}
        value={value || 'push'}
      />
    </Label>
  )
}
