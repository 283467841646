import React from 'react'
import { Button, Label, Modal, Select, TextInput } from '@nike/epic-react-ui'
import { HelpText } from '__components'

export const cannotEditDeployed =
  'You cannot edit DEPLOYED. Switch to a different version or create a new one to make changes.'

const VersionModal = ({ onCreate, show, setShow }) => {
  const [name, setName] = React.useState('')
  // Ignore all non-alphanumeric characters or underscores
  const normalizeName = (n) => n.toUpperCase().replace(/[\W]/g, '')

  return (
    <Modal closeButton={false} modalSize='md' show={show}>
      <Modal.Title>Create a new version to work on</Modal.Title>
      <Modal.Content>
        <HelpText>Use only letters, numbers, and underscores.</HelpText>
        <TextInput value={name} onChange={(e) => setName(normalizeName(e.target.value))} />
      </Modal.Content>
      <Modal.Actions>
        <Button inverse onClick={() => setShow(false)}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            onCreate(name)
            setShow(false)
          }}
        >
          Create
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default function VersionSwitcher({ onCreate, setVersion, version, versions }) {
  const [modalVisible, setModalVisible] = React.useState(false)

  const options = versions
    .sort((objA, objB) => {
      // Ignore case
      const a = objA.name.toLowerCase()
      const b = objB.name.toLowerCase()
      // Put "Deployed" first
      if (a === 'main') {
        return -1
      }
      if (b === 'main') {
        return 1
      }
      // Sort all others alphabetically
      return a < b
    })
    .map((v) => ({
      value: v.name,
      label: v.name === 'main' ? 'DEPLOYED' : v.name,
    }))
  const selectedOption = options.find((option) => option.value === version)
  return (
    <div className='flex-container' style={{ width: '50%', height: '50px' }}>
      <Label label='Version'>
        <Select
          onChange={(v) => {
            setVersion(v.value)
          }}
          options={options}
          value={selectedOption}
        />
      </Label>
      <div style={{ marginLeft: '0.5em', verticalAlign: 'bottom' }}>
        <Button onClick={() => setModalVisible(true)} small>
          +
        </Button>
      </div>
      <VersionModal setShow={setModalVisible} onCreate={onCreate} show={modalVisible} />
    </div>
  )
}
