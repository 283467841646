import { IconButton, Tooltip } from '@nike/epic-react-ui'
import { cannotEditDeployed } from '__accounts/VersionSwitcher'

export default function EditLink({ canEdit, item, openWizard }) {
  return (
    <span className={'show-tooltip'}>
      {canEdit ? (
        <IconButton bare onClick={() => openWizard(item)} type='edit' />
      ) : (
        <Tooltip message={cannotEditDeployed}>
          <IconButton bare disabled type='edit' />
        </Tooltip>
      )}
    </span>
  )
}
