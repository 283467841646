import React from 'react'
import { SiteWizardContext } from '../SiteWizard.js'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText, ValidationError } from '__components'
import { required } from '__util/forms/validators.js'

export default function PartnerAS2Id({ name, stepName }) {
  const { registerField, setField, state } = React.useContext(SiteWizardContext)
  const { errors, fields } = state

  React.useEffect(
    () => registerField(stepName, name, [required()]),
    [registerField, name, stepName]
  )

  return (
    <Field>
      <Label label='Partner AS2 ID' required>
        <HelpText>
          What is the AS2 ID (or AS2 Name) provided by this partner for this transfer? If you do not
          know this please contact the trading partner.
        </HelpText>
        <TextInput
          hasErrors={errors[name]}
          onChange={(e) => setField(name, e.target.value)}
          value={fields[name] || ''}
        />
        <ValidationError message={errors[name]} />
      </Label>
    </Field>
  )
}
