import React from 'react'
import DetailsContext from './DetailsContext.js'
import CustomVarsDetails from './CustomVarsDetails.js'
import CustomVarsForm from './CustomVarsForm.js'

export default function AccountDetailsCustomVars({ isEditing, EditControls }) {
  const { commitChanges, envConfig } = React.useContext(DetailsContext)

  const account = envConfig?.account || {}
  const vars = account.user_variables || []
  const data = vars.map((v) => ({
    name: Object.keys(v)[0],
    value: Object.values(v)[0],
  }))

  return isEditing ? (
    <CustomVarsForm
      data={data}
      onSave={(variables) => {
        const newAccount = {
          ...account,
          user_variables: variables,
        }
        console.log('commit account.json:', newAccount)
        commitChanges('account.json', newAccount)
      }}
      EditControls={EditControls}
    />
  ) : (
    <CustomVarsDetails data={data} EditControls={EditControls} />
  )
}
