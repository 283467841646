import React from 'react'

const DefList = ({
  rightAligned,
  items,
  customKey = '',
  termStyle = {},
  defStyle = {},
  ...attributes
}) => {
  let className = 'DefList'
  if (rightAligned) {
    className += ' DefList-right-aligned'
  }

  if (!items) {
    return <dl />
  }

  const definitions = Object.entries(items).map(([name, value], index) => {
    // The '\u00A0' character is equiv to &nbsp; and is rendered to
    // prevent the definitions from shifting up and getting misaligned
    // when value is empty.
    return (
      <React.Fragment key={`dl-${index}`}>
        <dt style={termStyle}>{name}</dt>
        <dd style={defStyle}>{value || '\u00A0'}</dd>
      </React.Fragment>
    )
  })

  return (
    <dl className={className} {...attributes}>
      {definitions}
    </dl>
  )
}

export default DefList
