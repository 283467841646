/**
 * These validator-generator functions take an options object and return
 * a validator function using those options.
 *
 * A validator function takes a value and returns an array [isValid, reason].
 * Validators may receive a second param, the current form/wizard state. This is
 * used when one field's validator depends on other fields.
 */

const FOLDER_PATH = 'Must be a valid folder path starting with a slash (/).'
const INTEGER_MIN = (min) => `Integer must be at least ${min}.`
const INTEGER_MAX = (max) => `Integer must be no more than ${max}.`
const NOT_EMAIL = 'Must be a valid email address.'
const NOT_EMAILS =
  'Must be a valid email address or list of addresses separated by commas or semicolons.'
const NOT_STRING = 'Must be a string.'
const NOT_NUMBER_OR_STRING = 'Must be a number or string data type.'
const NOT_INTEGER_VALUE = 'Must be an integer value.'
const ONE_OF = (options) => {
  if (Array.isArray(options)) {
    options = options.join(', ')
  }
  return `Must be one of: ${options}`
}
const SUNSET_ERROR = 'FTPS sites cannot use certain hosts and usernames'

// Export mostly for unit tests
export const messages = {
  FOLDER_PATH,
  INTEGER_MIN,
  INTEGER_MAX,
  NOT_EMAIL,
  NOT_EMAILS,
  NOT_INTEGER_VALUE,
  NOT_NUMBER_OR_STRING,
  NOT_STRING,
  ONE_OF,
  SUNSET_ERROR,
}

export const VALID = [true, '']
export const valid = () => VALID
export const invalid = (message) => [false, message]

export const isRequired = (value) => {
  if (!value) {
    return invalid('Required.')
  }
  return VALID
}

// Enforces required fields if this validator is set on a field. If the optional func param is passed,
// it will be called to validate the field based on its value and wizard state (e.g. other fields).
export const required = (userFunc) => {
  let func = isRequired
  if (userFunc) {
    func = (value, state, extras) => {
      return !value && userFunc(value, state, extras) ? invalid('Required.') : VALID
    }
  }

  // Decorate this function so isFieldRequired can determine required fields
  func.__name = 'required'
  return func
}

// Is valid if value is a single email address
export const email = () => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return (value) => {
    switch (true) {
      case value === '':
        return VALID
      case typeof value !== 'string':
        return invalid(NOT_STRING)
      case re.test(String(value).toLowerCase()):
        return VALID
      default:
        return invalid(NOT_EMAIL)
    }
  }
}

// Take string with comma or semicolon-separated list of emails and
// return an array of those emails.
export function emailCSVToArray(addressesString) {
  return addressesString
    .split(',')
    .map((p) => p.split(';'))
    .reduce((acc, cur) => acc.concat(cur, []))
    .map((a) => a.trim())
    .filter((a) => a) // Remove empty strings from array
}

// Is valid if string is a list of valid email addresses separated by commas or semicolons
export const listOfEmails = () => {
  const isValidEmail = email()
  const allValid = (value) => {
    const addresses = emailCSVToArray(value)
    return addresses.every((addr) => isValidEmail(addr)[0])
  }

  return (value) => {
    switch (true) {
      case value === '':
        return VALID
      case typeof value !== 'string':
        return invalid(NOT_STRING)
      case !allValid(value):
        return invalid(NOT_EMAILS)
      default:
        return VALID
    }
  }
}

// Is valid if value /appears/ to be a phone number (at least 10 digits).
// Hyphens and other punctuation is ignored.
export const phone = () => {
  return (value) => {
    if (value) {
      const justNumbers = value.replace(/[^0-9]/g, '')
      if (justNumbers.length < 10) {
        return invalid(
          'Must be a valid US or international phone number having at least 10 digit. For example "+1 (503) 555-1212" or simply "5035551212".'
        )
      }
    }

    return VALID
  }
}

// Is valid if string conforms to specified rules
export const string = (options) => {
  const { min, max, regex, regexExplanation } = options || {}

  return (value) => {
    switch (true) {
      case value === '':
        return VALID
      case typeof value !== 'string':
        return invalid(NOT_STRING)
      case min && value.length < min:
        return invalid(`Must be at least ${min} characters long.`)
      case max && value.length > max:
        return invalid(`Must be no longer than ${max} characters.`)
      case regex && !regex.test(value):
        return invalid(regexExplanation || `Must match this regular expression: ${regex}`)
      default:
        return VALID
    }
  }
}

const alphanumericAndUnderscores = /^[a-zA-Z0-9_]+$/
export const onlyAlphaNumAndUnderscores = () =>
  string({
    regex: alphanumericAndUnderscores,
    regexExplanation: 'Only alphanumeric characters and underscores.',
  })

export const folderPath = () =>
  string({
    regex: /^\/.+$/,
    regexExplanation: FOLDER_PATH,
  })

export const integer = (options) => {
  const { min, max, allowedValues } = options || {}

  const onlyDigits = /^[0-9]+$/
  return (value) => {
    switch (true) {
      case value === '':
        return VALID
      case !['number', 'string'].includes(typeof value):
        return invalid(NOT_NUMBER_OR_STRING)
      case !onlyDigits.test(value):
        return invalid(NOT_INTEGER_VALUE)
      default:
        const num = parseInt(value)

        switch (true) {
          case min && min > num:
            return invalid(INTEGER_MIN(min))
          case max && max < num:
            return invalid(INTEGER_MAX(max))
          case allowedValues && !allowedValues.includes(num):
            return invalid(ONE_OF(allowedValues))
          default:
            return VALID
        }
    }
  }
}

// Is valid if value is listed in the options array
export const options = (options) => {
  return (value) => {
    return options.includes(value) ? VALID : invalid(ONE_OF(options))
  }
}

/**
 * Validate a field that is an array of objects produced by FormSet.
 * `fields` is an object of {fieldName: [validators], ...} for each field.
 *
 * Response is an array: [
 *  isFormsetValid: bool
 *   errors: array of objects { fieldName: 'Message. Message2.', ... }
 * ]
 */
export const formSetValidator = (fieldValidators) => {
  return (formSet, state, { fieldName: formSetFieldName }) => {
    const changed = state.changed[formSetFieldName] || []
    let isFormSetValid = true,
      errors = []

    formSet.forEach((form, formIndex) => {
      errors[formIndex] = {}
      const formChanged = changed[formIndex] || {}

      Object.entries(fieldValidators).forEach(([fieldName, validators]) => {
        if (!state.checkAllFields && !formChanged[fieldName]) {
          return // Do not validate fields that have not been touched by user yet
        }
        const results = validators.map((isValid) => isValid(form[fieldName], state, { formIndex }))
        const messages = results
          .filter(([isValid, reason]) => !isValid)
          .map(([isValid, reason]) => reason)
        if (messages.length) {
          isFormSetValid = false
          errors[formIndex][fieldName] = messages.join(' ')
        }
      })
    })

    return [isFormSetValid, errors]
  }
}

export const smbProtocolOnlyForInternal = () => {
  return (value, state) => {
    // This validator is called by both the transfer_type field and the protocol field,
    // so value may be I/E or one of the protocols.
    if (value === 'E' && state.fields.protocol === 'smb') {
      return [false, 'Only sites internal to Nike support the SMB protocol.']
    } else if (value === 'smb' && state.fields.transfer_type === 'E') {
      return [false, 'This site is external to Nike, so SMB is not supported.']
    }
    return VALID
  }
}

export const as2ProtocolOnlyForExternal = () => {
  return (value, state) => {
    // This validator is called by both the transfer_type field and the protocol field,
    // so value may be I/E or one of the protocols.
    if (value === 'I' && state.fields.protocol === 'as2') {
      return [false, 'Only sites external to Nike support the AS2 protocol.']
    } else if (value === 'as2' && state.fields.transfer_type === 'I') {
      return [false, 'This site is internal to Nike, so AS2 is not supported.']
    }
    return VALID
  }
}

// Prevent users from editing or creating FTPS sites with the below values (AD Groups)
export const sunsettedUsernames = ['a.prdmftnetapp', 'a.qamftnetapp', 'a.devmftnetapp']
// if the host name is any of the following:
export const sunsettedHosts = [
  'nke-win-ftp-p01',
  'rtaphurl.ad.nike.com',
  'rtemhurl.ad.nike.com',
  'rtgbhurl.ad.nike.com',
  'rtamhurl.ad.nike.com',
]

export const sunsettingFtpsHostAndUsername = () => {
  return (value, state) => {
    if (
      state.isNew &&
      state.fields.protocol === 'ftps' &&
      sunsettedUsernames.includes(state.fields.username.toLowerCase()) &&
      sunsettedHosts.includes(state.fields.host.toLowerCase())
    )
      return [false, SUNSET_ERROR]
    return VALID
  }
}

const validators = {
  VALID,
  valid,
  invalid,
  messages,
  // validator functions below
  as2ProtocolOnlyForExternal,
  email,
  folderPath,
  formSetValidator,
  integer,
  isRequired,
  listOfEmails,
  onlyAlphaNumAndUnderscores,
  options,
  phone,
  required,
  string,
  smbProtocolOnlyForInternal,
  sunsettingFtpsHostAndUsername,
}

export default validators
