import React, { useContext } from 'react'
import { Link, usePath } from 'raviger'
import { NextGen } from '@nike/epic-react-ui'
import { selectIsLoggedIn, selectUsername, useAuthStore } from '@nike/aegis-auth-react'
import config from '__config'
import { ViewerContext } from '../users/ViewerContext'
import appLogo from './MFT.png'

const Navigation = () => {
  const currentPath = usePath()
  const viewer = useContext(ViewerContext)
  const email = useAuthStore(selectUsername)
  const isLoggedIn = useAuthStore(selectIsLoggedIn)

  if (!isLoggedIn) {
    return null
  }

  const profilePath = '/user-profile'
  const trackFiles = { text: 'Track Files', path: '/files', exact: false }
  let navbarRoutes = []

  if (viewer.isSupportOrHigher()) {
    navbarRoutes = [trackFiles]
  }

  if (viewer.isGitHubUser()) {
    navbarRoutes.push({ text: 'Accounts', path: '/accounts' })
  }

  return (
    <NextGen.NavBar
      avatar={{
        className: 'onboard-4',
        email: email || '',
        isActive: currentPath && email && currentPath === profilePath,
        routes: {
          dropDownLinks: [{ text: 'Profile', path: profilePath }],
        },
      }}
      name={config.appName}
      logo={appLogo}
      routes={navbarRoutes}
      rootPath={config.basePath || '/'}
      RouterLink={Link}
    />
  )
}

export default Navigation
