import React from 'react'
import { Label, RadioGroup } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import { SiteWizardContext } from '../SiteWizard.js'
import { deleteAfterDownloadOptions } from '../serialize.js'

export default function DeleteAfterDownload({ name, stepName }) {
  const { registerField, setField, state } = React.useContext(SiteWizardContext)
  const { fields } = state || { errors: {}, fields: {} }

  React.useEffect(() => registerField(stepName, name, [], 'No'), [name, registerField, stepName])

  return (
    fields.role === 'source' && (
      <Field>
        <Label label='Delete After Download?' required>
          <HelpText>
            Delete the file(s) from the source system after MFT successfully pulls the file.
          </HelpText>
          <RadioGroup
            list={deleteAfterDownloadOptions}
            onChange={(value) => setField(name, value)}
            value={fields[name]}
          />
        </Label>

        {!fields[name] && (
          <HelpText>Be sure to delete these files before the next transfer runs.</HelpText>
        )}
      </Field>
    )
  )
}
