import React from 'react'
import { SubWizardContext } from './SubWizard'
import NikeInitiated from './fields/NikeInitiated.js'
import SourceSite from './fields/SourceSite.js'
import Direction from './fields/Direction.js'
import DeleteAfterDownload from './fields/DeleteAfterDownload.js'
import FolderSuffix from './fields/FolderSuffix.js'
import SubscriptionFolderPreview from './fields/SubscriptionFolderPreview.js'
import RouteName from './fields/RouteName.js'

export default function NameStep(props) {
  const { state, validateStep, StepButtons } = React.useContext(SubWizardContext)
  const { existingSiteNames, fields } = state
  const { isStepValid } = props.isActive ? validateStep(props.name, state) : { isStepValid: true }

  return (
    <>
      <NikeInitiated stepName={props.name} />
      {fields.nikeInitiated ? (
        <SourceSite stepName={props.name} existingSiteNames={existingSiteNames} />
      ) : (
        <>
          <Direction stepName={props.name} />
          <DeleteAfterDownload stepName={props.name} />
        </>
      )}
      <FolderSuffix stepName={props.name} />
      <SubscriptionFolderPreview />

      {fields.nikeInitiated && <RouteName />}

      <StepButtons
        {...props}
        nextButtonDisabledMessage={!isStepValid && 'Please complete this step before moving on'}
      />
    </>
  )
}
