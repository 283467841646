import React from 'react'
import { Label, RadioGroup } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import { SubWizardContext } from '../SubWizard.js'

const NAME = 'nikeInitiated'

export default function Direction({ stepName }) {
  const { registerField, setField, state } = React.useContext(SubWizardContext)
  const { fields, isNew } = state

  React.useEffect(() => registerField(stepName, NAME, []), [registerField, stepName])

  return (
    <Field>
      <Label label='Nike Initiated?'>
        <HelpText>Will Nike be initiating the transfer?</HelpText>
        <RadioGroup
          disabled={!isNew}
          list={[
            {
              value: true,
              label: 'Yes, a Nike system will initiate the transfer (Autosys/Airflow)',
            },
            { value: false, label: 'No, partner will log in to push or pull files' },
          ]}
          onChange={isNew ? (value) => setField(NAME, value) : null}
          value={fields[NAME]}
        />
      </Label>
    </Field>
  )
}
