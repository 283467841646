import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { CopyableText, Field, HelpText } from '__components'
import { SiteWizardContext } from './SiteWizard.js'
import { protocolToWord } from './serialize.js'
import siteFields from './fields'

export const getFieldNamesForProtocol = (protocol) => {
  switch (protocol) {
    case 'ssh':
      return [
        'host',
        'username',
        'port',
        'use_password',
        'authType',
        'cerberus_sdb_path',
        'selectedCertName',
        'ssh_cert_name',
        'download_folder',
        'download_pattern',
        'delete_after_download',
        'max_concurrent_files',
        'transfer_mode',
        'upload_permissions',
      ]
    case 'ftps':
      return [
        'host',
        'username',
        'port',
        'use_password',
        'authType',
        'cerberus_sdb_path',
        'selectedCertName',
        'ssh_cert_name',
        'download_folder',
        'download_pattern',
        'delete_after_download',
        'max_concurrent_files',
        'transfer_mode',
        'is_active',
      ]
    case 'smb':
      return [
        'host',
        'username',
        'authType',
        'cerberus_sdb_path',
        'download_folder',
        'download_pattern',
        'delete_after_download',
        'max_concurrent_files',
      ]
    case 'as2':
      return [
        'AS2Intro',
        'nike_as2_id',
        'nike_as2_cert_name',
        'nike_email',
        'partner_as2_id',
        'partner_as2_url',
        'partner_email',
        'partner_as2_cert_name',
        'sign_alg',
        'content_type',
        'ackType',
        'mdn_async_type',
        'receive_file_as',
        'max_concurrent_files',
        'cipher_suites',
        'tls_versions',
      ]
    case 's3':
      return [
        's3_bucket',
        's3_region',
        'download_object_key',
        'download_pattern',
        'delete_after_download',
        's3_bucket_access',
        'system_metadata',
        'max_concurrent_files',
        'role_arn',
        'download_subfolders',
        'cerberus_sdb_path',
        'transfer_mode',
        'user_metadata',
      ]
    default:
      return []
  }
}

export default function ProtocolStep(props) {
  const stepName = props.name
  const { renameStep, state, StepButtons } = React.useContext(SiteWizardContext)
  const fields = state.fields || {}
  const protocol = fields.protocol
  const protocolWord = protocolToWord(protocol)
  const [protocolFormFields, setProtocolFormFields] = React.useState([])

  React.useEffect(() => {
    // Protocol change means we replace fields in this step with new ones.
    const fieldNames = getFieldNamesForProtocol(protocol)

    setProtocolFormFields(
      fieldNames.map(
        (name) =>
          name in siteFields &&
          React.createElement(siteFields[name], {
            key: `field-${name}`,
            name,
            stepName,
          })
      )
    )

    // Renaming the step here allows the new fields to register themselves with this step again.
    // Without this, only the last field gets registered and other field validators won't work.
    renameStep(2, `${protocolWord} Settings`)
  }, [protocol, protocolWord, renameStep, stepName])

  return (
    <>
      <Field>
        <HelpText>Set up the {protocolWord} configuration.</HelpText>
      </Field>

      {protocolFormFields}

      {protocol === 'ssh' && (
        <>
          <HelpText>
            The following fields are not currently editable, as these are extreme edge cases. Please
            reach out to{' '}
            <code>
              <CopyableText text='LST-MFT@nike.com' />
            </code>{' '}
            for manual support with these advanced fields. Learn more in the{' '}
            <a href='https://confluence.nike.com/display/ERPPMFT/RK0023089'>MFT docs</a>.
          </HelpText>
          <Field>
            <Label label='Allowed MAC Addresses'>
              <TextInput disabled value={fields.allowed_macs} />
            </Label>
          </Field>
          <Field>
            <Label label='Cipher Suites'>
              <TextInput disabled value={fields.cipher_suites} />
            </Label>
          </Field>
          <Field>
            <Label label='Key Exchange Algorithm'>
              <TextInput disabled value={fields.key_exch_alg} />
            </Label>
          </Field>
          <Field>
            <Label label='Public Key Algorithm'>
              <TextInput disabled value={fields.public_key_alg} />
            </Label>
          </Field>
        </>
      )}

      {/* This step allows user to proceed without having to have valid fields */}
      <StepButtons {...props} />
    </>
  )
}
