import React from 'react'
import { Tabs } from '@nike/epic-react-ui'
import { toCamelCaseCapitalized } from '__util/strings.js'
import Overview from './Overview.js'
import CustomVars from './CustomVars.js'
import FileSystem from './FileSystem.js'
import Certs from './Certs.js'
import Members from './Members.js'
import Sites from './Sites.js'
import Subs from './Subs.js'
import { DetailsContextProvider } from './DetailsContext.js'
import useEditState from './EditState.js'

export default function AccountDetails({
  configs,
  members,
  name,
  canEdit,
  onEditChange,
  commitChanges,
  env,
}) {
  // The following are more difficult to fix than what they suggest...
  // Line 25:9:  The 'tabs' object makes the dependencies of useCallback Hook (at line 61) change on every render. To fix this, wrap the initialization of 'tabs' in its own useMemo() Hook  react-hooks/exhaustive-deps
  // Line 25:9:  The 'tabs' object makes the dependencies of useEffect Hook (at line 76) change on every render. To fix this, wrap the initialization of 'tabs' in its own useMemo() Hook    react-hooks/exhaustive-deps
  // TODO: So I'm disabling the lint warning for now...
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tabs = {
    Overview: {
      component: Overview,
      ...useEditState(canEdit),
    },
    'Custom Variables': {
      component: CustomVars,
      ...useEditState(canEdit),
    },
    'Client Initiated': {
      component: FileSystem,
      ...useEditState(canEdit),
    },
    Certs: {
      component: Certs,
      ...useEditState(canEdit),
    },
    Sites: {
      component: Sites,
      ...useEditState(canEdit),
    },
    Transfers: {
      component: Subs,
      ...useEditState(canEdit),
    },
  }

  const onTabChange = React.useCallback(
    (toTab, fromTab) => {
      if (Object.values(tabs)[fromTab]?.isEditing) {
        // Prevent tab clicks when current tab in edit mode
        return false
      } else {
        window.location.hash = toCamelCaseCapitalized(Object.keys(tabs)[toTab])
      }
    },
    [tabs]
  )

  // Change tab labels other than the current one to look disabled when current one is in edit mode
  const tabLabels = Object.keys(tabs).map((label) => {
    const isUnsaved = Object.entries(tabs)
      .filter(([tabLabel, tab]) => tabLabel !== label)
      .some(([tabLabel, tab]) => tab.isEditing)
    return isUnsaved ? <span style={{ color: '#ccc', cursor: 'not-allowed' }}>{label}</span> : label
  })

  React.useEffect(() => {
    // Disable EnvSwitcher whenever a tab is in edit mode
    const isEditing = Object.values(tabs).filter((tab) => tab.isEditing).length
    onEditChange(isEditing)
  }, [onEditChange, tabs])

  return (
    <DetailsContextProvider
      name={name}
      canEdit={canEdit}
      commitChanges={commitChanges}
      configs={configs}
      members={members}
      env={env}
    >
      <div className='account-details-wrapper'>
        <Members />
        <Tabs onSelect={onTabChange} tabs={tabLabels} className='Tabs-vertical'>
          <div className='Tabs-panels'>
            {Object.entries(tabs).map(([tabLabel, tab], i) => {
              const { component, ...editState } = tab
              return (
                <Tabs.TabPanel key={`tab-${i}`}>
                  {React.createElement(component, editState)}
                </Tabs.TabPanel>
              )
            })}
          </div>
        </Tabs>
      </div>
    </DetailsContextProvider>
  )
}
