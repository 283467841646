import React from 'react'
import { Label, Select, TextInput } from '@nike/epic-react-ui'
import { findValue, listToSelectOptions } from '__util/select.js'
import { Field, HelpText, ValidationError } from '__components'
import { onlyAlphaNumAndUnderscores, required } from '__util/forms/validators.js'
import { SiteWizardContext } from '../SiteWizard.js'

export const commonCertNameOptions = [
  { value: 'INFA_SSH_KEY', label: 'Informatica - INFA_SSH_KEY' },
  { value: 'Fusion_SSH_KEY', label: 'Fusion - Fusion_SSH_KEY' },
  { value: 'EDI_SSH_KEY', label: 'Nike EDI - EDI_SSH_KEY' },
  {
    value: 'CONVERSE_EDI_SSH_KEY',
    label: 'Converse EDI - CONVERSE_EDI_SSH_KEY',
  },
]

export default function SSHCertName({ name, stepName }) {
  const {
    existingCertNames = [],
    registerField,
    setField,
    setFields,
    state,
  } = React.useContext(SiteWizardContext)
  const { errors, fields } = state

  const certNameOptions = React.useMemo(
    () =>
      listToSelectOptions(existingCertNames)
        .concat(commonCertNameOptions)
        .concat([{ value: 'other', label: 'Other' }]),
    [existingCertNames]
  )

  React.useEffect(() => {
    registerField(stepName, name, [
      required((v, state) => state.fields.use_password === false),
      onlyAlphaNumAndUnderscores(),
    ])
    registerField(stepName, 'selectedCertName', [], 'other')
  }, [registerField, name, stepName])

  return (
    (fields.use_password === false || fields.use_password === 'false') && (
      <Field>
        <Label label='SSH Cert Name' required>
          <HelpText>Either choose an existing cert name or provide a new one.</HelpText>
          <Select
            onChange={(selected) => {
              const certName = selected.value === 'other' ? '' : selected.value
              setFields({ selectedCertName: selected.value, ssh_cert_name: certName })
            }}
            options={certNameOptions}
            value={findValue(certNameOptions, fields.selectedCertName) || 'other'}
          />

          <TextInput
            disabled={fields.selectedCertName !== 'other'}
            hasErrors={errors[name]}
            onChange={(e) => setField(name, e.target.value)}
            value={fields[name] || ''}
          />
          <ValidationError message={errors[name]} />
        </Label>
      </Field>
    )
  )
}
