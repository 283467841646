import React from 'react'
import DetailsContext from './DetailsContext.js'
import OverviewDetails from './OverviewDetails.js'
import OverviewForm from './OverviewForm.js'

export default function Overview({ isEditing, EditControls }) {
  const { envConfig } = React.useContext(DetailsContext)
  const settings = envConfig?.account || {}

  return isEditing ? (
    <OverviewForm settings={settings} EditControls={EditControls} />
  ) : (
    <OverviewDetails settings={settings} EditControls={EditControls} />
  )
}
