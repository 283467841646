import React from 'react'
import PropTypes from 'prop-types'
import StepWizard from 'react-step-wizard'
import StepButtons from './StepButtons'
import Step from './Step'
import Menu from './Menu'
import { isFieldRequired, validateSteps, validateStep } from './validate'
import { Section } from '@nike/epic-react-ui'
import { PrettyJSON } from '__components'

/**
 * Return a new Wizard `changed` state representing a form set field.
 * @param {object} state: wizard state before the change
 * @param {object} action:
 *    {
 *      name, // name of the field in state that represents the formset
 *      value, // new form set array of objects
 *      extras: {
 *        whatChanged // one of 'addForm', 'removeForm', 'changeField'
 *        changedFormIndex // required for 'removeForm' and 'changeField'
 *        changedFieldName // required for 'changeField'
 *      }
 *    }
 *
 * @returns new changed array representing any fields in the form set that are marked
 *    as changed (aka "dirty"), e.g.:
 *
 *    [
 *      { name: true }, // form index 0
 *      { age: true },  // form index 1
 *      {} // This third form has no dirty fields yet
 *    ]
 */
const getFormsetChanged = (state, action) => {
  const formsetChanged = [...(state.changed[action.name] || [])]

  // action should include an `extras` prop with information required to
  // know the actual field that was changed, or if a form was added or removed
  const { whatChanged, changedFormIndex, changedFieldName } = action.extras || {}

  switch (whatChanged) {
    case undefined:
      // New form set: initialize the changed array to match
      return new Array(action.value.length)
    case 'addForm':
      formsetChanged.push({})
      break
    case 'removeForm':
      const before = [...formsetChanged].splice(0, changedFormIndex)
      const after = [...formsetChanged].splice(changedFormIndex + 1)
      return before.concat(after)
    case 'changeField':
      const formChanged = formsetChanged[changedFormIndex] || {}
      formChanged[changedFieldName] = true
      formsetChanged[changedFormIndex] = formChanged
      break
    default:
      throw Error(`setField action for a FormSet was not called with a reason for the change`)
  }

  return formsetChanged
}

const wizardReducer = (state, action) => {
  let step, steps, newSteps

  switch (action.type) {
    case 'registerField': // { stepName, fieldName, validators, defaultValue }
      // Add a field and optional validators for it to a step
      newSteps = [...state.steps]
      step = newSteps.find((step) => step.name === action.stepName)
      if (step) {
        step.fields[action.fieldName] = action.validators.length ? action.validators : []
        newSteps[action.stepName] = step
      }

      // Now set this field's default if needed, or an emtpy string to avoid
      // uncontrolled component warnings.
      if (!state.fields[action.fieldName]) {
        state.fields[action.fieldName] =
          action.defaultValue === undefined ? '' : action.defaultValue
      }

      return { ...state, steps: newSteps }
    case 'registerStep': // { stepName, fields: {}}
      newSteps = [...state.steps]
      step = newSteps.find((step) => step.name === action.stepName)
      if (step) {
        step.fields = { ...action.fields }
        step.status = ''
      }
      state.steps = newSteps
      return { ...state }
    case 'renameStep': // { index, newName }
      state.steps[action.index].name = action.newName
      // Also clear exisitng field validators; fields `useEffect` hooks should fire on step name change
      // to re-register themselves.
      state.steps[action.index].fields = {}
      return { ...state }
    case 'addStep': // { name, component }
      const newStep = { name: action.name, component: action.component, fields: {} }
      steps = [...state.steps, newStep]
      return { ...state, steps }
    case 'removeStep': // { name }
      steps = [...state.steps.filter((s) => s.name !== action.name)]
      return { ...state, steps }
    case 'setField': // { name, value }
      if (Array.isArray(action.value)) {
        // This field is an array of objects, i.e. a FormSet
        state.changed[action.name] = getFormsetChanged(state, action)
      } else {
        // This is a normal unique field
        state.changed[action.name] = true
      }
      state.fields[action.name] = action.value
      // This onChange function is passed into the wizard from above to allow
      // for additional processing, e.g. recalculate a field value based on others.
      state.fields = state.onChange(state.fields, action, state.changed)

      state = validateSteps(state)
      if (state.onValidate) {
        state.errors = state.onValidate(state.errors, state.fields)
      }
      return { ...state }
    case 'setFields': // { fields: {} }
      Object.entries(action.fields).forEach(([name, value]) => {
        state.changed[name] = true
        state.fields[name] = value
      })
      // This onChange function is passed into the wizard from above to allow
      // for additional processing, e.g. recalculate a field value based on others.
      state.fields = state.onChange(state.fields, action)
      return { ...state }
    case 'validateWizard':
      // validateWizard causes validation to check all fields, changed and unchanged,
      // and to show results for each step in the wizard Menu.
      state.showValidationStatusInMenu = true
      state.checkAllFields = true
      state = validateSteps(state)
      return { ...state }
    default:
      return state
  }
}

const noOpHandler = (fields) => fields

/**
 * Generic multi-step wizard. Provides styling for steps that are complete, have validation
 * errors, or are disabled based on the state of the form.
 */
export default function Wizard({
  children = [],
  debug = false,
  initialState,
  isHashEnabled = false,
  onChange,
  onSave,
  onValidate,
  Context,
  Header,
}) {
  const { fields, ...extraState } = initialState

  let steps = []
  if (children.length) {
    // Steps is an array of objects. Each object should have `name` and `component` prop.
    steps = children.map((child) => ({
      name: child.props.name,
      component: child.type,
      fields: {},
    }))
  }

  const [state, dispatch] = React.useReducer(wizardReducer, {
    // object of field name keys and boolean values indicating if changed by use
    changed: {},
    // object of field name to error message
    errors: {},
    // fields: object of form field name to current value
    fields: { ...initialState.fields },
    // Function is called whenever a `setField` action is dispatched.
    onChange: onChange || noOpHandler,
    onSave,
    onValidate,
    showValidationStatusInMenu: false,
    steps,
    ...extraState,
  })

  const setField = React.useCallback(
    (name, value, extras) => dispatch({ type: 'setField', name, value, extras }),
    [dispatch]
  )
  const setFields = React.useCallback(
    (fields) => dispatch({ type: 'setFields', fields }),
    [dispatch]
  )
  const validateWizard = React.useCallback(() => dispatch({ type: 'validateWizard' }), [dispatch])
  const registerField = React.useCallback(
    (stepName, fieldName, validators, defaultValue) =>
      dispatch({ type: 'registerField', stepName, fieldName, validators, defaultValue }),
    [dispatch]
  )
  const registerStep = React.useCallback(
    (stepName, fields) => dispatch({ type: 'registerStep', stepName, fields }),
    [dispatch]
  )
  const renameStep = React.useCallback(
    (index, newName) => dispatch({ type: 'renameStep', index, newName }),
    [dispatch]
  )

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        isFieldRequired: (name) => isFieldRequired(name, state),
        setField,
        setFields,
        registerField,
        registerStep,
        renameStep,
        validateStep,
        validateWizard,
        StepButtons,
        onSave,
      }}
    >
      {Header && React.createElement(Header, { wizardState: state })}
      <StepWizard
        className='Wizard'
        // Faster and less movement than defaults
        transitions={{
          enterRight: 'speedy fadeIn30Right',
          enterLeft: 'speedy fadeIn30Left',
          exitRight: 'speedy fadeOut30Right',
          exitLeft: 'speedy fadeOut30Left',
        }}
        isHashEnabled={isHashEnabled}
        nav={<Menu state={state} />}
      >
        {state.steps.map(({ component, name }, index) => {
          const key = `${name.replace(/ /g, '-')}`
          return <Step component={component} name={name} key={key} hashKey={key} />
        })}
      </StepWizard>
      {debug && (
        <Section>
          Wizard state:
          <PrettyJSON>{state}</PrettyJSON>
        </Section>
      )}
    </Context.Provider>
  )
}

Wizard.propTypes = {
  initialState: PropTypes.object.isRequired,
  // Render wizard state below the wizard content
  debug: PropTypes.bool,
  // react-step-wizard can put hashes into window.location if desired
  isHashEnabled: PropTypes.bool,
  onChange: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  // A component created via React.createContext() that is used by step components to access wizard state.
  // Letting the caller set this up enables us to have multiple wizards in the same app.
  Context: PropTypes.object.isRequired,
}
