import React from 'react'
import DetailsModal from './DetailsModal.js'

const detailsModalReducer = (state, action) => {
  switch (action.type) {
    case 'open':
      const { title, content, actions } = action
      return { show: true, title, content, actions }
    case 'close':
      return { show: false, title: '', content: '', actions: '' }
    default:
      return state
  }
}

export const DetailsContextProvider = ({
  configs,
  members,
  name,
  canEdit,
  children = [],
  commitChanges,
  env,
}) => {
  const envConfig = configs[env] || {}
  const [modal, modalDispatch] = React.useReducer(detailsModalReducer, {
    show: false,
    title: '',
    content: '',
    actions: '',
  })

  return (
    <DetailsContext.Provider
      value={{
        members,
        name,
        canEdit,
        commitChanges,
        env,
        envConfig,
        modalDispatch,
      }}
    >
      {children}
      <DetailsModal
        show={modal.show}
        title={modal.title}
        content={modal.content}
        actions={modal.actions}
        onClose={() => modalDispatch({ type: 'close' })}
      />
    </DetailsContext.Provider>
  )
}

const DetailsContext = React.createContext({})
export default DetailsContext
