import React from 'react'
import { Button, Checkbox, Label, RadioGroup, TextInput } from '@nike/epic-react-ui'
import { ButtonBar, Field, HelpText, ValidationError } from '__components'
import { useForm, validators } from '__util/forms/index.js'
import { objToSelectOptions } from '__util/select.js'

const { valid, invalid, required, onlyAlphaNumAndUnderscores } = validators

const accountType = {
  nike: 'Nike internal',
  converse: 'Converse internal',
  external: 'External partner',
}

export default function AccountCreateForm({ existingAccountNames, isVisible, onClose, onSubmit }) {
  const { state, setField, resetForm } = useForm({
    fields: {
      existingAccountNames,
      confirmed: false,
      name: 'NIKE_',
      type: 'nike',
    },
    mutators: {
      name: normalizeName(),
    },
    validators: {
      name: [
        required(),
        onlyAlphaNumAndUnderscores(),
        startsWithCorrectPrefix(),
        doesNotMatchExistingAccountNames,
      ],
    },
  })
  const { fields, errors } = state

  React.useEffect(() => {
    if (!isVisible) {
      resetForm()
    }
  }, [isVisible, resetForm])

  React.useEffect(() => {
    if (existingAccountNames.length) {
      setField('existingAccountNames', existingAccountNames)
    }
  }, [existingAccountNames, setField])

  return (
    <div>
      <h2>Create Account</h2>
      <Field>
        <Label label='Please Confirm' required>
          <HelpText>
            Each account created here has a fiscal impact. Please search the accounts list first to
            verify that an account for the business group or partner does not already exist.
            Remember to look for alternate names and abbreviations as well.
          </HelpText>
          <Checkbox
            label='I could not find an account for this team or partner.'
            onValueChange={(v) => setField('confirmed', v)}
            checked={fields.confirmed}
          />
        </Label>
      </Field>
      {fields.confirmed && (
        <>
          <Field>
            <Label label='Account Type?'>
              <RadioGroup
                list={objToSelectOptions(accountType)}
                onChange={(value) => setField('type', value)}
                value={fields.type}
              />
            </Label>
          </Field>
          <Field>
            <Label label='Name' required>
              <HelpText>
                Type one or more words separated by underscore.{' '}
                {fields.type === 'nike'
                  ? 'Name begins with NIKE_.'
                  : fields.type === 'converse'
                  ? 'Name begins with CONVERSE_.'
                  : 'May not begin with NIKE_ or CONVERSE_.'}
              </HelpText>
              <TextInput
                hasErrors={errors.name}
                onChange={(e) => setField('name', e.target.value)}
                value={fields.name || ''}
              />
              <ValidationError message={errors.name} />
            </Label>
          </Field>
          <ButtonBar centered>
            <Button onClick={onClose} inverse small>
              Cancel
            </Button>
            <Button
              disabled={Object.keys(errors).length}
              disabledTip={{ message: 'Please fix the above errors first.' }}
              onClick={() => onSubmit(fields.name)}
              small
            >
              Create Account
            </Button>
          </ButtonBar>
        </>
      )}
    </div>
  )
}

function startsWithCorrectPrefix() {
  return (value, state) => {
    if (!value) {
      return valid()
    }

    switch (state.fields.type) {
      case 'nike':
        return value.startsWith('NIKE_') ? valid() : invalid('Must start with NIKE_.')
      case 'converse':
        return value.startsWith('CONVERSE_') ? valid() : invalid('Must start with CONVERSE_.')
      default:
        return valid()
    }
  }
}

const doesNotMatchExistingAccountNames = (value, state) => {
  if (!value) {
    return valid()
  }

  if (state.fields.existingAccountNames.includes(value)) {
    return invalid('There is an account with this name already.')
  } else {
    return valid()
  }
}

function normalizeName() {
  return (oldValue, state) => {
    let value = oldValue.toUpperCase()

    switch (state.fields.type) {
      case 'nike':
        value = value.replace(/^CONVERSE_/, 'NIKE_') // User toggled `type`
        value = value === 'NIKE' ? 'NIKE_' : value // User deletes last char of prefix
        value = value.startsWith('NIKE_') ? value : `NIKE_${value}` // Prepend prefix
        break
      case 'converse':
        value = value.replace(/^NIKE_/, 'CONVERSE_') // User toggled `type`
        value = value === 'CONVERSE' ? 'CONVERSE_' : value // User deletes last char of prefix
        value = value.startsWith('CONVERSE_') ? value : `CONVERSE_${value}` // Prepend prefix
        break
      default:
        value = value.replace(/^NIKE_/, '')
        value = value.replace(/^CONVERSE_/, '')
        break
    }
    return value
  }
}
