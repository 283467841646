import 'whatwg-fetch'
import qs from 'qs'

import { JSONReplacer } from '__components/PrettyJSON'

// Global variable to hold user's okta token. More secure here than local/session storage.
let authToken

export function setAuthToken(token) {
  authToken = token
}

function getAuthHeader() {
  if (authToken) {
    return { Authorization: `Bearer ${authToken}` }
  }
  return {}
}

export const processError = async (r, contentType) => {
  let data
  if (contentType === 'application/json') {
    data = await r.json()
  } else {
    data = await r.text()
  }
  const message = `Request failed with status code ${r.status}`
  const error = new Error(data.message || message)
  // The old axios behavior was to return a response
  // response.message was the parsed message for JSON
  // but running toString produced the "message" above
  error.toString = () => message
  error.data = data
  error.status = r.status
  console.error(message, error)
  throw error
}

export const processResponse = async (r) => {
  let contentType = r.headers.get('content-type')
  if (!r.ok) {
    return processError(r, contentType)
  }
  if (contentType === 'application/json') {
    return await r.json()
  } else if (contentType === 'application/octet-stream') {
    return await r.blob()
  }
  return await r.text()
}

const mod = {
  post(url, payload = {}, headers = {}) {
    return fetch(url, {
      // Stringify objects but not FormData instances
      body: payload.entries ? payload : JSON.stringify(payload, JSONReplacer, 2),
      headers: {
        'Content-Type': payload.entries ? 'multipart/form-data' : 'application/json',
        ...getAuthHeader(),
        ...headers,
      },
      method: 'POST',
      mode: 'cors',
    }).then(processResponse)
  },
  get(url, queryData, headers = {}) {
    if (queryData) {
      const queryString = qs.stringify(queryData)
      url = url + '?' + queryString
    }

    return fetch(url, {
      headers: { ...getAuthHeader(), ...headers },
      mode: 'cors',
    }).then(processResponse)
  },
  delete(url, headers = {}) {
    return fetch(url, {
      headers: { ...getAuthHeader(), ...headers },
      method: 'DELETE',
      mode: 'cors',
    }).then(processResponse)
  },
  put(url, payload = {}, headers = {}) {
    const contentType = payload.entries ? 'multipart/form-data' : 'application/json'
    return fetch(url, {
      // Stringify objects but not FormData instances
      body: payload.entries ? payload : JSON.stringify(payload, JSONReplacer, 2),
      headers: { 'Content-Type': contentType, ...getAuthHeader(), ...headers },
      method: 'PUT',
      mode: 'cors',
    }).then(processResponse)
  },
}

export default mod
