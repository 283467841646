import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'

export default function EndpointField({ value }) {
  return (
    <Field>
      <Label label='Endpoint' />
      <HelpText>This is the Nike endpoint for partner to connect to via SFTP.</HelpText>
      <TextInput disabled value={value} />
    </Field>
  )
}
