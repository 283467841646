import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText, ValidationError } from '__components'
import { required, string, sunsettingFtpsHostAndUsername } from '__util/forms/validators.js'
import { SiteWizardContext } from '../SiteWizard.js'
import { protocolToWord } from '../serialize.js'

const validHostname =
  /^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-_]{0,61}[a-zA-Z0-9])(\.([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-_]{0,61}[a-zA-Z0-9]))*$/
const validIP =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/
const hostnameRegex = new RegExp(validHostname.source + '|' + validIP.source)
const hostnameError = 'Must be a valid DNS name or a valid IPv4 address.'

export default function Host({ name, stepName }) {
  const { registerField, setField, state } = React.useContext(SiteWizardContext)
  const errors = state.errors || {}
  const fields = state.fields || {}

  React.useEffect(
    () =>
      registerField(stepName, name, [
        required(),
        string({ regex: hostnameRegex, regexExplanation: hostnameError }),
        sunsettingFtpsHostAndUsername(),
      ]),
    [registerField, name, stepName]
  )

  return (
    <Field>
      <Label label='Host' required>
        <HelpText>{protocolToWord(fields.protocol)} host name - IP or DNS</HelpText>
        <TextInput
          hasErrors={errors[name]}
          onChange={(e) => setField(name, e.target.value)}
          value={fields[name] || ''}
        />
        <ValidationError message={errors[name]} />
      </Label>
    </Field>
  )
}
