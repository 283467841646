import React from 'react'
import config from '__config'
import { GitHubContext } from '../github/GitHubAuth'
import { Button, Container } from '@nike/epic-react-ui'

export default function ResetGitHubAuth({ user }) {
  const github = React.useContext(GitHubContext)
  if (!user.isGitHubUser()) {
    return null
  }

  const accessPage = `https://github.com/settings/connections/applications/${config.gitHubAuth.client_id}`
  return (
    <Container>
      <h3>GitHub (Advanced)</h3>
      <p>
        This application requires permission to use your GitHub identity. Authorization is started
        automatically the first time you visit this app; you may reset it manually if you experience
        problems.
      </p>
      <Button onClick={() => github.authFlowStart()} small>
        Reset GitHub authorization
      </Button>

      <p>
        For your information, here is the GitHub page showing what access you have given to the MFT
        UI:
        <a href={accessPage}>{accessPage}</a>
      </p>
    </Container>
  )
}
