import React from 'react'
import { api } from '__util'
import { PageTitle } from '__components'
import { Search } from '__components/search'
import { FileTrackerSearchForm } from './FileTrackerSearchForm'
import { FileTrackerSearchResults } from './FileTrackerSearchResults'

const trackerFilters = [
  'env',
  'account',
  'status',
  'protocol',
  'filename',
  'accountOrLogin',
  'endTimeBefore', // A string in RFC 2822 format
  'timeWindowSeconds',
  'direction',
  'resubmitted',
  'limit',
]

const FileTrackerSearchPage = (props) => {
  return (
    <React.Fragment>
      <PageTitle title='Track Files' />
      <p style={{ marginLeft: '2em' }} className='epic-color-grey-5'>
        Track individual file transfers and resubmit as per the business need.
      </p>
      <Search
        // This list reflects the API filters defined at mft-portal-api
        allowedFilters={trackerFilters}
        baseUrl={api.url('files')}
        initialFilters={{
          env: 'qa',
          resubmitted: 'false',
          limit: 5,
          offset: 0,
          endTimeBefore: new Date(),
          timeWindowSeconds: 3600,
        }}
        // This API returns {results: [...]}
        resultPropName='results'
      >
        <FileTrackerSearchForm />
        <FileTrackerSearchResults />
      </Search>
    </React.Fragment>
  )
}

export default FileTrackerSearchPage
