import React from 'react'
import PropTypes from 'prop-types'
import { Label, RadioGroup, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText, ValidationError } from '__components'
import {
  as2ProtocolOnlyForExternal,
  listOfEmails,
  options,
  required,
  smbProtocolOnlyForInternal,
} from '__util/forms/validators.js'
import { objToSelectOptions } from '__util/select.js'
import { SiteWizardContext } from './SiteWizard.js'
import { transferTypeOptions } from './serialize.js'

export default function BasicsStep(props) {
  const { registerStep, setField, state, validateStep, StepButtons } =
    React.useContext(SiteWizardContext)
  const { errors, fields } = state
  const { isStepValid } = props.isActive ? validateStep(props.name, state) : { isStepValid: true }

  React.useEffect(() => {
    registerStep(props.name, {
      transfer_type: [
        options(['I', 'E']),
        as2ProtocolOnlyForExternal(),
        smbProtocolOnlyForInternal(),
      ],
      contact: [required(), listOfEmails()],
    })
  }, [props.name, registerStep])

  return (
    <>
      <Field>
        <Label label='Internal or External?'>
          <HelpText>Does this site reside inside Nike or at the external partner?</HelpText>
          <RadioGroup
            list={objToSelectOptions(transferTypeOptions)}
            onChange={(value) => setField('transfer_type', value)}
            value={fields.transfer_type}
          />
          <ValidationError message={errors.transfer_type} />
        </Label>
      </Field>
      <Field>
        <Label label='Site Owner' required>
          <HelpText>Email address of the person or group that owns this site.</HelpText>
          <TextInput
            hasErrors={errors.contact}
            onChange={(e) => setField('contact', e.target.value)}
            placeholder='some-list@example.com'
            value={fields.contact}
          />
          <ValidationError message={errors.contact} />
        </Label>
      </Field>

      <StepButtons
        {...props}
        nextButtonDisabledMessage={!isStepValid && 'Please complete this step before moving on'}
      />
    </>
  )
}

BasicsStep.propTypes = {
  // Label shown to user, also used to register fields and perform validation
  name: PropTypes.string.isRequired,
  // This step component is passed the following props from react-step-wizard.
  // See https://github.com/jcmcneal/react-step-wizard for docs.
  isActive: PropTypes.bool,
  currentStep: PropTypes.number,
  firstStep: PropTypes.number,
  lastStep: PropTypes.number,
  nextStep: PropTypes.func,
  previousStep: PropTypes.func,
  goToStep: PropTypes.func, // takes integer step number
}
