import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, HelpText } from '__components'
import { SubWizardContext } from '../SubWizard.js'

export default function SubscriptionFolderPreview() {
  const { state } = React.useContext(SubWizardContext)

  return (
    <Field>
      <Label label='Subscription Folder'>
        <HelpText>
          This value is derived from your answers above. It is a path on the MFT system that is
          relative to the login user's home directory.
        </HelpText>
        <TextInput disabled value={state.fields.subscription_folder} />
      </Label>
    </Field>
  )
}
