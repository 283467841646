import React from 'react'
import { SiteWizardContext } from '../SiteWizard.js'
import { Label, Select } from '@nike/epic-react-ui'
import { Field } from '__components'
import { findValue, listToSelectOptions } from '__util/select.js'
import siteFields from './index.js'

export default function NikeEmail({ name, stepName }) {
  const { registerField, state } = React.useContext(SiteWizardContext)
  const { envConfig, fields } = state
  const email = React.useMemo(() => envConfig?.account?.email_contact || '', [envConfig])
  const options = listToSelectOptions([email]) // For now, only one email exists on the account

  React.useEffect(() => registerField(stepName, name, [], email), [
    email,
    name,
    registerField,
    stepName,
  ])

  return (
    <Field>
      <Label label='Nike Email' required>
        <Select
          onChange={(value) => siteFields(name, value)}
          options={options}
          value={findValue(options, fields[name])}
        />
      </Label>
    </Field>
  )
}
