import React from 'react'
import { Label, TextInput } from '@nike/epic-react-ui'
import { Field, FilePicker, HelpText } from '__components'

export default function CertField({ env, accountName, onChange, disabled, value }) {
  return (
    <Field>
      <Label label='Public Certificate' />
      <HelpText>Please select your public SSH key file. Only RSA format is supported.</HelpText>
      {disabled ? (
        <>
          <TextInput disabled value={value} />
        </>
      ) : (
        <>
          <HelpText style={{ border: '1 px orange', backgroundColor: '#bdb' }}>
            Upload the SSH public key. You will also need to upload it to the S3 bucket specified
            <code>{`mft-onboarding-prod/${accountName}/${env}/public/`}</code>
          </HelpText>
          <FilePicker value={value} onSelect={(file) => onChange(file.name)} />
        </>
      )}
    </Field>
  )
}
